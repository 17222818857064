import { Component, Input } from '@angular/core';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ApplicationStorageService } from 'src/app/application/services/application-storage.service';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-application-pay-deposit',
  templateUrl: './application-pay-deposit.component.html',
  styleUrls: ['./application-pay-deposit.component.scss']
})
export class ApplicationPayDepositComponent {

  @Input() application;
  @Input() token;
  cashHand = faHandHoldingUsd;

  applicationDataLoading:boolean;

  constructor(
    private _bond_application_storage: ApplicationStorageService,
    private _toast_service: ToastService,
  ) { }


   ngOnInit(): void {}

  payDeposit($event): void {
      $event.preventDefault()
      this.applicationDataLoading = true;
      this._bond_application_storage.payDeposit$(this.token,this.application.unit_id,{}).subscribe(data => {
        this._toast_service.show(data["message"], { classname: 'bg-success text-light' });
         window.location.href = data["data"]["submission_url"];
      }, (res) => {
        this.applicationDataLoading = false;    
        this._toast_service.show(res.error.message, { classname: 'bg-danger text-light' });
      });
  }

}
