
<div class="container">
    <h4 class="pt-5 pb-3">
      Profile Settings
    </h4>
    <hr>
    <p class="pt-3 pb-3">
       Please ensure all your personal information below is accurate and up to date.
    </p>
    <div class="card craft-card bg-light">
      <div class="card-body"> 
        <form [appLoader]="formLoading" [formGroup]="profileDetails">
            <div class="row">
                <div class="col-md">
                   <!-- start of  First Name field -->
                   <div class="form-group craft-form-group required">
                      <label for="first_name-field">First Name</label>
                      <input formControlName="first_name"  type="input" class="form-control" [ngClass]="{'is-invalid':profileDetails.get('first_name').invalid && (profileDetails.get('first_name').dirty || profileDetails.get('first_name').touched) }" id="first_name-field">
                      <app-invalid-feedback  class="invalid-feedback" name="First Name" [control]="profileDetails.get('first_name')"></app-invalid-feedback>
                   </div>
                   <!-- end of First Name Field -->
                </div>
                <div class="col-md">
                      <!-- start of  Last Name field -->
                      <div class="form-group craft-form-group required">
                         <label for="last_name-field">Last Name</label>
                         <input formControlName="last_name"  type="input" class="form-control" [ngClass]="{'is-invalid':profileDetails.get('last_name').invalid && (profileDetails.get('last_name').dirty || profileDetails.get('last_name').touched) }" id="last_name-field">
                         <app-invalid-feedback  class="invalid-feedback" name="Last Name" [control]="profileDetails.get('last_name')"></app-invalid-feedback>
                      </div>
                      <!-- end of Last Name Field -->
                </div>
             </div>

             <div class="row">
                <div class="col-md">
                    <!-- start of  E mail field -->
                    <div class="form-group craft-form-group required">
                       <label for="email-field">E mail</label>
                       <input formControlName="email"  type="input" class="form-control" [ngClass]="{'is-invalid':profileDetails.get('email').invalid && (profileDetails.get('email').dirty || profileDetails.get('email').touched) }" id="email-field">
                       <app-invalid-feedback  class="invalid-feedback" name="E mail" [control]="profileDetails.get('email')"></app-invalid-feedback>
                    </div>
                    <!-- end of E mail Field -->
                </div>
             </div>

             <div class="row">
                <div class="col-md">
                    <!-- start of  ID Number field -->
                    <div class="form-group craft-form-group required">
                       <label for="id_number-field">ID Number</label>
                       <input formControlName="id_number"  type="input" class="form-control" [ngClass]="{'is-invalid':profileDetails.get('id_number').invalid && (profileDetails.get('id_number').dirty || profileDetails.get('id_number').touched) }" id="id_number-field">
                       <app-invalid-feedback  class="invalid-feedback" name="ID Number" [control]="profileDetails.get('id_number')"></app-invalid-feedback>
                    </div>
                    <!-- end of ID Number Field -->
                </div>
             </div>

            <fieldset formGroupName="address">
               <div class="row">
                  <div class="col-md">
                      <!-- start of  Address field -->
                      <div class="form-group craft-form-group required">
                         <label for="id_number-field">Address Line 1</label>
                         <input formControlName="address_line_1"  type="input" class="form-control" [ngClass]="{'is-invalid':profileDetails.get('address.address_line_1').invalid && (profileDetails.get('address.address_line_1').dirty || profileDetails.get('address.address_line_1').touched) }" id="id_number-field">
                         <app-invalid-feedback  class="invalid-feedback" name="Address Line 1" [control]="profileDetails.get('address.address_line_1')"></app-invalid-feedback>
                      </div>
                      <!-- end of Address Field -->
                  </div>
               </div>

               <div class="row">
                  <div class="col-md">
                      <!-- start of  Address field -->
                      <div class="form-group craft-form-group required">
                         <label for="id_number-field">Address Line 2</label>
                         <input formControlName="address_line_2"  type="input" class="form-control" [ngClass]="{'is-invalid':profileDetails.get('address.address_line_2').invalid && (profileDetails.get('address.address_line_2').dirty || profileDetails.get('address.address_line_2').touched) }" id="id_number-field">
                         <app-invalid-feedback  class="invalid-feedback" name="Address Line 2" [control]="profileDetails.get('address.address_line_2')"></app-invalid-feedback>
                      </div>
                      <!-- end of Address Field -->
                  </div>
               </div>

               <div class="row">
                  <div class="col-md">
                      <!-- start of  City field -->
                      <div class="form-group craft-form-group required">
                         <label for="cityfield">Town/City</label>
                         <input formControlName="city"  type="input" class="form-control" [ngClass]="{'is-invalid':profileDetails.get('address.city').invalid && (profileDetails.get('address.city').dirty || profileDetails.get('address.city').touched) }" id="cityfield">
                         <app-invalid-feedback  class="invalid-feedback" name="City" [control]="profileDetails.get('address.city')"></app-invalid-feedback>
                      </div>
                      <!-- end of City Field -->
                  </div>
               </div>

               <div class="row">
                  <div class="col-md">
                      <!-- start of  City field -->
                      <div class="form-group craft-form-group required">
                         <label for="cityfield">Postal Code</label>
                         <input formControlName="postal_code"  type="input" class="form-control" [ngClass]="{'is-invalid':profileDetails.get('address.postal_code').invalid && (profileDetails.get('address.postal_code').dirty || profileDetails.get('address.postal_code').touched) }" id="postal_codefield">
                         <app-invalid-feedback  class="invalid-feedback" name="postal_code" [control]="profileDetails.get('address.postal_code')"></app-invalid-feedback>
                      </div>
                      <!-- end of City Field -->
                  </div>
               </div>

               <div class="row">
                  <div class="col-md">
                      <!-- start of  City field -->
                      <div class="form-group craft-form-group required">
                         <label for="cityfield">Province</label>

                         <select formControlName="province_id"  type="input" class="form-control" [ngClass]="{'is-invalid':profileDetails.get('address.province_id').invalid && (profileDetails.get('address.province_id').dirty || profileDetails.get('address.province_id').touched) }" id="province_idfield">
                              <option class="d-none" [ngValue]="''">
                                 Select preferred communication channel
                              </option>
                              <option *ngFor="let option of provinces"  [ngValue]="option.id">
                                 {{option.name }}
                              </option>
                           </select>
                         <app-invalid-feedback  class="invalid-feedback" name="province_id" [control]="profileDetails.get('address.province_id')"></app-invalid-feedback>
                      </div>
                      <!-- end of City Field -->
                  </div>
               </div>

            </fieldset>

             <div class="row">
                <div class="col-md">
                    <!-- start of  Work Number field -->
                    <div class="form-group craft-form-group required">
                       <label for="work_number-field">Work Number</label>
                       <input formControlName="work_number"  type="input" class="form-control" [ngClass]="{'is-invalid':profileDetails.get('work_number').invalid && (profileDetails.get('work_number').dirty || profileDetails.get('work_number').touched) }" id="work_number-field">
                       <app-invalid-feedback  class="invalid-feedback" name="Work Number" [control]="profileDetails.get('work_number')"></app-invalid-feedback>
                    </div>
                    <!-- end of Work Number Field -->
                </div>
             </div>

             <div class="row">
                <div class="col-md">
                    <!-- start of  Mobile Number field -->
                    <div class="form-group craft-form-group required">
                       <label for="mobile_number-field">Mobile Number</label>
                       <input formControlName="mobile_number"  type="input" class="form-control" [ngClass]="{'is-invalid':profileDetails.get('mobile_number').invalid && (profileDetails.get('mobile_number').dirty || profileDetails.get('mobile_number').touched) }" id="mobile_number-field">
                       <app-invalid-feedback  class="invalid-feedback" name="Mobile Number" [control]="profileDetails.get('mobile_number')"></app-invalid-feedback>
                    </div>
                    <!-- end of Mobile Number Field -->
                </div>
             </div>

            <div class="row">
               <div class="col-lg-3 col-sm-12">
                  <button [appLoader]="submitLoading" type="submit" class="btn btn-primary" (click)="onSubmit()">Update Profile</button>
               </div>
            </div>
        </form>
    </div>
    </div>
  </div>
  