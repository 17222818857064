import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, iif, of } from 'rxjs';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserService } from '../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginDetails: FormGroup;

  savePrompt: boolean;
  formLoading: boolean;
  errors: Array<string>;
  formSubscriptions: Subscription = new Subscription()
  impersonate: boolean = false;

  @Input() token: string;
  @Input() applicationFormdata: any;
  @Output() dataLoaded: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('content') modalContent: any;


  constructor(
    private _utility_service: UtilityService,
    private fb: FormBuilder,
    private router: Router,
    private _toast_service: ToastService,
    private _user_service: UserService,
    private route: ActivatedRoute
  ) { }
  ngOnDestroy(): void {
    this.formSubscriptions.unsubscribe()
  }

  ngOnInit(): void {
    this.formLoading = false;
    this.impersonate = this.route.snapshot.params.id !== undefined && this.route.snapshot.params.id !== null;
    this.initForm();
  }

  initForm(): void {
    this.loginDetails = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      rememberMe: ['']
    });
  }

  onLogin(): void {
    this._utility_service.markFormGroupTouched(this.loginDetails);
    if (!this.loginDetails.invalid) {
      this.formLoading = true;
      this.clearErrors();

      const source$ = iif(
        () => this.impersonate
        , this._user_service.impersonate$({ ...this.loginDetails.value, user_id: parseInt(this.route.snapshot.params.id) }) /* POST: email, password, rememberMe, user_id*/
        , this._user_service.login$(this.loginDetails.value)
      );

      this.formSubscriptions.add(
        source$.subscribe(data => {
          this.formLoading = false;
          let intedendedUrl = this._user_service.getIntendedUrl()

          if (intedendedUrl) {
            this._user_service.setIntendedUrl(null);
            this.router.navigateByUrl(this._utility_service.getFullUrlFromSnapshot(intedendedUrl));
          } else {
            this.router.navigate(['/']);
          }

        }, (res) => {
          this.formLoading = false;
          if(res.error)
          {
            
            if(res.error.errors)
            {
              this.setErrors(res.error.errors);
            }
            this._toast_service.show(res.error.message, { classname: 'bg-danger text-light' });
          }
        })

      );
    } else {
      this._toast_service.show("Please ensure that your input is valid", { classname: 'bg-danger text-light' });
    }
  }


  setErrors(errors) {
    let errorFields = Object.keys(errors);
    errorFields.forEach(field => {
      this.errors = this.errors.concat(errors[field]);
    });
  }

  clearErrors() {
    this.errors = [];
  }

}
