<app-bond-application-header *ngIf="application" 
[development]="application.development">
</app-bond-application-header>

<div class="container" >
    <h4 class="pt-5 pb-3">
        {{ application ? application.label : "Loading Application"  }}
    </h4>
    <hr>
    <p class="pt-3 pb-3">
        Welcome to your dashboard. View, explore and update your Craft Client profile from here.
    </p>

    <ul ngbNav [(activeId)]="active_fragment" #nav="ngbNav" class="nav-tabs nav-fill">
        <li [ngbNavItem]="links.unit_details.fragment">
            <a ngbNavLink routerLink="." [fragment]="links.unit_details.fragment" class="p-3">
                {{links.unit_details.title}}
            </a>
            <ng-template ngbNavContent>
                <app-unit-details [application]="application"></app-unit-details>
            </ng-template>
        </li>
        <li [ngbNavItem]="links.bond_application.fragment" *ngIf="application && application?.status.application_type == 'bond'">
            <a ngbNavLink routerLink="." [fragment]="links.bond_application.fragment" class="p-3">
                {{links.bond_application.title}}
            </a>
            <ng-template ngbNavContent >

              

                <app-application-history [application]="application" *ngIf="!application.development.is_manual && !application.is_manual"></app-application-history>

                <app-manual-form [application]="application" [token]="token" *ngIf="application.development.is_manual || application.is_manual" (status)="updateApplicationStatus($event)"></app-manual-form>

            </ng-template>
        </li>
        <li [ngbNavItem]="links.cash_application.fragment" *ngIf="application && application?.status.application_type == 'cash'">
            <a ngbNavLink routerLink="." [fragment]="links.cash_application.fragment" class="p-3">
                {{links.cash_application.title}}
            </a>
            <ng-template ngbNavContent >
               
                <app-cash-form [application]="application" [token]="token" (status)="updateApplicationStatus($event)"></app-cash-form>
            </ng-template>
        </li>
        <li [ngbNavItem]="links.deposit.fragment">
            <a ngbNavLink routerLink="." [fragment]="links.deposit.fragment" class="p-3">
                {{links.deposit.title}}
            </a>
            <ng-template ngbNavContent>
                <app-application-pay-deposit [application]="application" [token]="token"></app-application-pay-deposit>
            </ng-template>
        </li>
        <!--
        <li [ngbNavItem]="links.finishes.fragment" *ngIf="application?.status.code == 'sbmtd' || (application?.status.can_select_additions && application?.development.has_extras)">
            <a ngbNavLink routerLink="." [fragment]="links.finishes.fragment" class="p-3">
                {{links.finishes.title}}
            </a>
            <ng-template ngbNavContent>
                <app-finish-selection  [application]="application" [token]="token"></app-finish-selection>
            </ng-template>
        </li>
        <li [ngbNavItem]="links.extras.fragment" *ngIf="application?.status.code == 'sbmtd' || (application?.status.can_select_additions && application?.development.has_extras)">
            <a ngbNavLink routerLink="." [fragment]="links.extras.fragment"  class="p-3">
                {{links.extras.title}}
            </a>
            <ng-template ngbNavContent>
                <app-addition-selection  [application]="application" [token]="token"></app-addition-selection>
            </ng-template>
        </li>
        -->
        <li [ngbNavItem]="links.documents.fragment">
            <a ngbNavLink routerLink="." [fragment]="links.documents.fragment" class="p-3">
                {{links.documents.title}}
            </a>
            <ng-template ngbNavContent>
                <app-application-documents [application]="application"></app-application-documents>
            </ng-template>
        </li>
    </ul>

    <div [ngbNavOutlet]="nav"></div>

    <div *ngIf="!application" class="card">
        <div class="card-body">
            <div [class]="{'h-150px':applicationLoading}" [appLoader]="applicationLoading"></div>
        </div>
    </div>

</div>
