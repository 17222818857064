export class Formtypes {

    exclusion_list: any =
    {
        "Private Individual":{},
        "Joint Account":{},
        "Company":{},
        "Close Corporation":{},
        "Trust":{},
        "Manual": {}
    };
    
    constructor() {
        this.addExcludedSectionAList();
    }

    private addExcludedSectionAList()
    {
        let excludedlist = ["Private Individual","Joint Account"];
        let  sectionA = {
            "juristic_application": [
                "business_name",
                "registration_number",
                "trading",
                "foundation_date",
                "country_of_incorporation",
                "business_industry",
                "gross_monthly_turnover",
                "registered_business_address",
                "legal_form",
            ]
        };

        excludedlist.forEach(element => {
            Object.assign(this.exclusion_list[element],sectionA);
        });
    }
    
}