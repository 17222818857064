import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-monthly-expenses',
  templateUrl: './monthly-expenses.component.html',
  styleUrls: ['./monthly-expenses.component.scss']
})
export class MonthlyExpensesComponent implements OnInit {

  monthly_expenses:FormGroup;
  @Input() bondApplication: FormGroup;
@Input() index: number;
  requiredMessage = "Value must be 0 or higher";
  constructor(private fb: FormBuilder,
    private utilityService: UtilityService 
    //  private _validation_service: ValidationService
  ) { 
  }

  ngOnInit(): void {
    this. AddToForm();
  }

  AddToForm(): void{
    this.monthly_expenses = this.fb.group({
        groceries: ['0',[Validators.required]],
        rates_taxes_electricity: ['0',[Validators.required]],
        domestic_garden_service:['0',[Validators.required]],
        telephone_mobile_phone:['0',[Validators.required]],
        fixed_investments:['0',[Validators.required]],
        insurance_policies:['0',[Validators.required]],
        educational_fees:['0',[Validators.required]],
        memberships_subscriptions:['0',[Validators.required]],
        transport_petrol_cost:['0',[Validators.required]],
        entertainment:['0',[Validators.required]],
        security:['0',[Validators.required]],
        other:['0',[Validators.required]],
        other_specification:['']
    });
    this.bondApplication.addControl('monthly_expenses',this.monthly_expenses);
  }

  get monthly_expenses_total()
  {
    return this.utilityService.valuesSum(this.monthly_expenses.value,[
      "groceries",
      "rates_taxes_electricity",
      "domestic_garden_service",
      "telephone_mobile_phone",
      "fixed_investments",
      "insurance_policies",
      "educational_fees",
      "memberships_subscriptions",
      "transport_petrol_cost",
      "entertainment",
      "security",
      "other",
    ]);
  }


}
