import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';
import options from 'src/app/data/options.json'

@Component({
  selector: 'app-juristic-applications',
  templateUrl: './juristic-applications.component.html',
  styleUrls: ['./juristic-applications.component.scss']
})
export class JuristicApplicationsComponent implements OnInit {
  subscriptions: Array<Subscription> = []
  juristic_application: FormGroup;
  message:String;
  options = options;
  @Input() bondApplication: FormGroup;
  @Input() index: number;
  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.message = null;
    this.AddToForm();
  }

  AddToForm(): void {
    this.juristic_application = this.fb.group({
      business_name: ['', [Validators.required]],
      registration_number: ['',[Validators.required]],
      trading: ['', [Validators.required]],
      foundation_date: ['',[Validators.required]],
      country_of_incorporation: ['',[Validators.required]],
      business_industry: ['', [Validators.required]],
      gross_monthly_turnover: ['', [Validators.required]],
      registered_business_address: ['', [Validators.required]],
      legal_form: ['', [Validators.required]],
    });
    this.bondApplication.addControl('juristic_application', this.juristic_application);
    this.subscriptions.push(this.juristic_application.valueChanges.subscribe(f=>{
        if(Object.keys(f).length == 0)
        {
          this.message = "Please note, this section is only for company, trust and close corporation  applications.";
        }else
        {
          this.message = null;

        }
    }));
}

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    })
  }

}
