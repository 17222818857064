import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-invalid-feedback',
  templateUrl: './invalid-feedback.component.html',
  styleUrls: ['./invalid-feedback.component.scss']
})
export class InvalidFeedbackComponent implements OnInit ,OnChanges{


  @Input() control: FormControl;
  @Input() name: string;
  @Input() messages?: any;
  displayMessages:any = {};
  defaultMessages: {};
  constructor() {      
  }

  ngOnInit(): void {
  }
  
 
  ngOnChanges(changes: SimpleChanges):void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case "messages":
              if(typeof changes.messages.currentValue != "undefined")
              {
                this.setMessages();
              }
            break;
        } 
      }
    }
  }

  setMessages()
  {
    this.defaultMessages = {
      serverError: this.control?.errors?.serverError,
      minlength: `${this.name} must be atleast ${this.control?.errors?.minlength?.requiredLength} selected.`,
      maxlength: `${this.name} must be at most ${this.control?.errors?.maxlength?.requiredLength} selected.`,
      email: `${this.name} must be a valid email address.`,
      equalTo: `${this.name} does not match.`,
      required: `${this.name} can not be left empty.`
    };
    this.displayMessages = Object.assign(this.defaultMessages,this.messages)
  }

  get displayMessage():string
  {
    if(this.getFirstError())
    {
      this.setMessages();
      let errorType = this.getFirstError();
      if (this.displayMessages.hasOwnProperty(errorType))
      {
        return this.displayMessages[errorType];
      }else
      {
        return `${this.name} is invalid`;
      }
    }
  }

  getFirstError()
  {
    let keys =  typeof this.control != "undefined" && this.control.errors !== null ? Object.keys(this.control.errors) : [];
    if(keys.length > 0)
    {
      return keys[0];
    }
    return null
  }


}
