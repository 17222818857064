<div *ngIf="bondApplicationValid; else invalidBondApplication">
  <div class="card" *ngIf="loading">
    <div class="card-body">
        <div [class]="{'h-150px':loading}" [appLoader]="loading">

        </div>
    </div>
  </div>
  <ng-container *ngIf="!loading && additions.length > 0">
  <div class="additions-list py-3">
    <ngb-accordion #a="ngbAccordion">
        <ngb-panel id="panel-{{category.id}}" *ngFor="let category of additions">
            <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between">
               
                <button ngbPanelToggle class="btn btn-link accordion-btn container-fluid">
                    <h5 class="m-0 float-left">{{category.name}}</h5>
                    <fa-icon class="float-right" [icon]="opened?iconDown:iconRight"></fa-icon>
                  </button>
            </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div class="addition row py-3 d-none d-md-flex" >
                    <div class="col-12 col-md-6">ITEM</div>
                    <div class="col-12 col-md-3">PRICE</div>
                    <div class="col-12 col-md-2">PICTURE</div>
                    <div class="col-12 col-md-1"></div>
                 </div>
                <div class="addition variation row py-3" *ngFor="let addition of category.additions">
                   <div class="col-12 col-md-6 vertical-container">
                       <p class="vertical-text">{{addition.name}}</p>
                   </div>
                   <div class="col-12 col-md-3 vertical-container" >
                       <p>{{addition.purchasable_price | currency:'R' }}</p>
                   </div>
                   <ng-container *ngIf="!addition.has_variations">
                      <div class="col-12 col-md-2 vertical-container horizontal addition-image" [ngStyle]="{'background-image': 'url(' + addition['featured_image'] + ')'}">
                          
                      </div>
                   </ng-container>
                   <ng-container *ngIf="addition.has_variations">
                      <div class="col-12 col-md-3">
                          
                      </div>
                   </ng-container>
                   <div class="col-12 variations-col" *ngIf="addition.has_variations">
                      <div class="row variation no-gutters py-3 px-3" *ngFor="let variation of addition.variations">
                          <div class="col-12 col-md-6 vertical-container">
                             <p>
                              - {{variation.name}}
                             </p>
                          </div>
                          <div class="col-12 col-md-3 vertical-container">
                              <p>
                                  {{variation.purchasable_price}}
                              </p>
                          </div>
                          <div class="col-12 col-md-2 vertical-container horizontal addition-image" [ngStyle]="{'background-image': 'url(' + variation['featured_image'] + ')'}">
                              
                          </div>
                      </div>
                   </div>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
  </div>
</ng-container>
<ng-container *ngIf="!loading && additions.length == 0">
  <div class="row">
    <div class="col-12 py-5"><div>Please contact Craft Homes on <a href="tel:0860027238">086 002 7238</a> for special requests with regards to extras.</div></div>
  </div>
</ng-container>
</div>
<ng-template #invalidBondApplication>
  <div class="alert alert-info mt-2">
      Please ensure you have selected your unit type from the drop down field under section A
  </div>
</ng-template>



  