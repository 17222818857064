<div class="card craft-card bg-light" *ngIf="application">
    <div class="card-body">
        <table class="table" [appLoader]="dataLoading">
            <thead>
                <tr>
                    <th>
                        Name
                    </th>
                    <th>
                        Download
                    </th>
                </tr>
            </thead>
            <tr *ngFor="let document of documents">
                <td>
                    {{ document.name }}

                </td>
                <td>
                    <a class="btn btn-primary" [href]="document.url">
                        Download
                    </a>
                </td>
            </tr>
            <tr *ngIf="!dataLoading && documents.length == 0">
                <td colspan="2">
                    No Documents Uploaded
                </td>
            </tr>
        </table>
    </div>
</div>
