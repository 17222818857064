import { Component, OnInit } from '@angular/core';
import { ReportingService } from 'src/app/shared/services/reporting.service'

@Component({
  selector: 'app-visitor-counter',
  templateUrl: './visitor-counter.component.html',
  styleUrls: ['./visitor-counter.component.scss']
})
export class VisitorCounterComponent implements OnInit {

  count:number = 1;

  constructor(private _report_service: ReportingService) {}

  //subscribe to websocket
  ngOnInit(): void {

    this._report_service.visitors$.subscribe( res => {
        this.count = res;
    });

    this._report_service.join().subscribe( event => {})

  }

}
