import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AppRoutingModule } from '../app-routing.module';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ProfileComponent } from './components/profile/profile.component';
import { PasswordSetupComponent } from './components/password-setup/password-setup.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent, ChangePasswordComponent, ProfileComponent, PasswordSetupComponent],
  imports: [
    AppRoutingModule,
    CommonModule,
    SharedModule,
    FormsModule, 
    ReactiveFormsModule,
    FontAwesomeModule
  ],
  exports:[
    LoginComponent
  ]
})
export class UserModule { }
