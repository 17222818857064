import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DependecyFieldsService {

  constructor() { }

  setConditionalFields(formGroup, dependecyFields) {
    let subscriptions = [];
    dependecyFields.forEach(element => {
      subscriptions.push(
        formGroup.get(element['field']).valueChanges.subscribe((val) => {
          if (element['values'].includes(val)) {
            this.addFields(formGroup, element['depedent_fields']);
          } else {
            this.removeFields(formGroup, element['depedent_fields']);
          }
        })
      )
    });
    return subscriptions;
  }

  addFields(formGroup, fields) {
    fields.forEach(field => {
      if (!formGroup.get(field.name)) {
        formGroup.addControl(field.name, field.control);
      }
    });
  }

  removeFields(formGroup, fields) {
    fields.forEach(field => {
      if (formGroup.get(field.name)) {
        formGroup.removeControl(field.name);
      }
    });
  }
}
