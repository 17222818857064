
<div class="container">
    <div class="card craft-card bg-light mt-5" [appLoader]="applicationDataLoading">
        <div class="card-body" *ngIf="application">
            <h3 class="text-center mb-4">Congratulations</h3>
            <p class="text-center mb-4">
                You have successfully reserved your unit. To confirm your reservation please select one of the two available options.
                <br>
                One of the two options must be completed within 48 hours of reserving the unit. Failing to do so could result in your unit becoming available again.
            </p>
            <div class="row">
                <div class="col-lg-5 col-12" href="#">
                    <a class="card h-100"  (click)="payDeposit($event)">
                        <div class="card-body payment-card">
                            <div class="row">
                                <fa-icon [icon]="cashHand" class="fa-3x fa-icon d-block m-auto"></fa-icon>
                            </div>
                            <p class="text-center">
                                Pay a R5000 refundable reservation deposit <br>
                                This amount will be deducted from the purchase price
                            </p>
                        </div>
                    </a>
                </div>
                <div class="col-lg-2 col-12 align-self-center">
                    <h3 class="text-center">or</h3>
                </div>
                <div class="col-lg-5 col-12">
                    <a class="card h-100"  (click)="bondApplicationSelection($event)">
                        <div class="card-body payment-card">
                            <div class="row">
                                <fa-icon [icon]="contract" class="fa-3x fa-icon d-block m-auto"></fa-icon>
                            </div>
                            <p class="text-center">
                                Submit your bond application first then pay the required R5000 deposit later.<br>
                                Craft Homes will submit your applications to all major banks.
                            </p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>


