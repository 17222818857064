import { Directive, Input, ElementRef, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appDepedencyChild]'
})
export class DepedencyChildDirective {
  
  @Input() appDepedencyChild:any;
  @Input() form:any; 
  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) { 
  }

  ngAfterViewInit(): void {
    
  }

}
