import { Injectable, Inject } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common'; 
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(@Inject(DOCUMENT) private document) {
   }
     
  objectFilter(object,keys)
  {
    return keys.reduce((result, key) => ({ ...result, [key]: object[key] }), {});
  }

  valuesSum(object,keys): number
  {
    let json = this.objectFilter(object,keys);
    let values = Object.values(json);
    return Number(values.reduce((a, b) => Number(a) + Number(b), 0));
  }

  /**
 * Marks all controls in a form group as touched
 * @param formGroup - The form group to touch
 */
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

 /**
 *scroll to input
 */
focusOnError() {
  setTimeout(()=>{
    var elements = document.querySelectorAll('input.ng-invalid,select.ng-invalid');
    if(elements.length > 0)
    {
        let activeElement = elements[0];
        activeElement.scrollIntoView({
          behavior: "smooth"
      });
    };

  },1)
}



getControlName(c: AbstractControl): string | null {
  const formGroup = c.parent.controls;
  return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
}


  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);
  
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
  
    // create a view into the buffer
    var ia = new Uint8Array(ab);
  
    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
  
    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], {type: mimeString});
    return blob;
  
  }
  
  templateReplace(replaceList,text)
  {
    Object.keys(replaceList).forEach((key)=>{
      let field = replaceList[key];
       text = text.replace(`{{${key}}}`,field);
    });
    return text;
  }

  getFullUrlFromSnapshot(url:ActivatedRouteSnapshot):string
  {
    return url.pathFromRoot
    .map(v => v.url.map(segment => segment.toString()).join('/'))
    .join('/');
  }

  arrayFindReplace(array, findValue, replaceValue){
    while(array.indexOf(findValue) !== -1){
        let index = array.indexOf(findValue);
        array[index] = replaceValue;
    }
  }

}
