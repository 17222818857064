import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-salary-deductions',
  templateUrl: './salary-deductions.component.html',
  styleUrls: ['./salary-deductions.component.scss']
})
export class SalaryDeductionsComponent implements OnInit {

  salary_deductions:FormGroup;
  @Input() bondApplication: FormGroup;
  @Input() index: number;
  requiredMessage = "Value must be 0 or higher";
  constructor(private fb: FormBuilder,
    private utilityService: UtilityService 
  ) { 
  }

  ngOnInit(): void {
    this. AddToForm();
  }

  AddToForm(): void{
    this.salary_deductions = this.fb.group({
        income_tax:['0',[Validators.required]],
        unemployment_insurance_fund:['0',[Validators.required]],
        pension:['0',[Validators.required]],
        medical_aid:['0',[Validators.required]],
        other_specify:['0',[Validators.required]],
        other_specification:[''],
    });
    this.bondApplication.addControl('salary_deductions',this.salary_deductions);
  }

  get salary_deductions_total()
  {
    return this.utilityService.valuesSum(this.salary_deductions.value,[
        "income_tax",
        "unemployment_insurance_fund",
        "pension",
        "medical_aid",
        "other_specify",
    ]); 
  }

}
