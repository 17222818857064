<fieldset [formGroup]="property_details">

   <div class="row">
      <div class="col-md">
         <!-- start of  Development Name field -->
         <div class="form-group">
            <label for="development_name-field">Development Name</label>
            <input formControlName="development_name" readonly type="input" class="form-control" [ngClass]="{'is-invalid':property_details.get('development_name').invalid && (property_details.get('development_name').dirty || property_details.get('development_name').touched) }" id="development_name-field">
            <app-invalid-feedback  class="invalid-feedback" name="Development Name" [control]="property_details.get('development_name')"></app-invalid-feedback>
         </div>
         <!-- end of Development Name Field -->
      </div>
      <div class="col-md">
            <!-- start of  Unit Number (ISM) (SIMS) field -->
            <div class="form-group">
               <label for="unit_number-field">Unit Number</label>
               <input formControlName="unit_number" readonly type="input" class="form-control" [ngClass]="{'is-invalid':property_details.get('unit_number').invalid && (property_details.get('unit_number').dirty || property_details.get('unit_number').touched) }" id="unit_number-field">
               <app-invalid-feedback  class="invalid-feedback" name="Unit Number (ISM) (SIMS)" [control]="property_details.get('unit_number')"></app-invalid-feedback>
            </div>
            <!-- end of Unit Number (ISM) (SIMS) Field -->
      </div>
   </div>

   <div class="row">
      <div class="col-md">
         <!-- start of Property Description field -->
         <div class="form-group">
            <label for="property_description-field">Property Description</label>
            <input formControlName="property_description"  readonly type="input" class="form-control" [ngClass]="{'is-invalid':property_details.get('property_description').invalid && (property_details.get('property_description').dirty || property_details.get('property_description').touched) }" id="property_description-field">
            <app-invalid-feedback  class="invalid-feedback" name="Property Description" [control]="property_details.get('property_description')"></app-invalid-feedback>
         </div>
         <!-- end of Property Description Field -->
      </div>
      <div class="col-md">
            <!-- start of Property Type field -->
            <div class="form-group">
               <label for="property_type-field">Property Type</label>
               <input formControlName="property_type" type="input"  readonly class="form-control" [ngClass]="{'is-invalid':property_details.get('property_type').invalid && (property_details.get('property_type').dirty || property_details.get('property_type').touched) }" id="property_type-field">
               <app-invalid-feedback  class="invalid-feedback" name="Property Type" [control]="property_details.get('property_type')"></app-invalid-feedback>
            </div>
            <!-- end of Property Type Field -->
      </div>
   </div>
   <div class="row">
      <div class="col-md">
            <!-- start of Street Name field -->
            <div class="form-group">
               <label for="street_name-field">Street Name</label>
               <input formControlName="street_name" type="input" readonly class="form-control" [ngClass]="{'is-invalid':property_details.get('street_name').invalid && (property_details.get('street_name').dirty || property_details.get('street_name').touched) }" id="street_name-field">
               <app-invalid-feedback  class="invalid-feedback" name="Street Name" [control]="property_details.get('street_name')"></app-invalid-feedback>
            </div>
            <!-- end of Street Name Field -->
      </div>
      <div class="col-md">
            <!-- start of Suburb field -->
            <div class="form-group">
               <label for="suburb-field">Suburb</label>
               <input formControlName="suburb" type="input" readonly class="form-control" [ngClass]="{'is-invalid':property_details.get('suburb').invalid && (property_details.get('suburb').dirty || property_details.get('suburb').touched) }" id="suburb-field">
               <app-invalid-feedback  class="invalid-feedback" name="Suburb" [control]="property_details.get('suburb')"></app-invalid-feedback>
            </div>
            <!-- end of Suburb Field -->
      </div>
   </div>
   <div class="row">
      <div class="col-md">
            <!-- start of  City field -->
            <div class="form-group">
               <label for="city-field">City</label>
               <input formControlName="city" type="input" readonly class="form-control" [ngClass]="{'is-invalid':property_details.get('city').invalid && (property_details.get('city').dirty || property_details.get('city').touched) }" id="city-field">
               <app-invalid-feedback  class="invalid-feedback" name="City" [control]="property_details.get('city')"></app-invalid-feedback>
            </div>
            <!-- end of City Field -->
      </div>
      <div class="col-md">
         <!-- start of  Province field -->
         <div class="form-group">
            <label for="province-field">Province</label>
            <input formControlName="province" type="input" readonly class="form-control" [ngClass]="{'is-invalid':property_details.get('province').invalid && (property_details.get('province').dirty || property_details.get('province').touched) }" id="province-field">
            <app-invalid-feedback  class="invalid-feedback" name="Province" [control]="property_details.get('province')"></app-invalid-feedback>
         </div>
         <!-- end of Province Field -->
      </div>
   </div>
   <div class="row">
      <div class="col-md">
         <!-- start of  Transferring Attorney Name field -->
         <div class="form-group">
            <label for="transferring_attorney_name-field">Transferring Attorney Name</label>
            <input formControlName="transferring_attorney_name" readonly type="input" class="form-control" [ngClass]="{'is-invalid':property_details.get('transferring_attorney_name').invalid && (property_details.get('transferring_attorney_name').dirty || property_details.get('transferring_attorney_name').touched) }" id="transferring_attorney_name-field">
            <app-invalid-feedback  class="invalid-feedback" name="Transferring Attorney Name" [control]="property_details.get('transferring_attorney_name')"></app-invalid-feedback>
         </div>
      </div>
   </div>
</fieldset>