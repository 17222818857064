import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';

import { UnitTypeService } from 'src/app/application/services/unit-type.service';

import { Finish } from 'src/app/models/finish';
import { ToastService } from 'src/app/shared/services/toast.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-finish-selection',
  templateUrl: './finish-selection.component.html',
  styleUrls: ['./finish-selection.component.scss']
})
export class FinishSelectionComponent implements OnInit, OnChanges {

  @Input() application;
  @Input() token;

  loading:boolean;
  selectedFinish:Finish;

  finishes: Array<Finish>;
  finishComparisons: {};

  constructor(private _unit_type_service: UnitTypeService,   private _toast_service: ToastService) { }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['application'].currentValue){
      this.loadAvaliableFinishes();
    }
  }

  loadAvaliableFinishes() {
    this.loading = true;
    this._unit_type_service.getFinishes$(this.application['unit'].unit_type_id).subscribe(response => {
      this.loading = false;
      this.finishes = response['data'].map((x)=> new Finish().fromJson(x));
      this.finishComparisons = this.mapFinishComparisons(this.finishes);
      if(this.application.finish_unit_type_id) {
        for(let x = 0; x < this.finishes.length; x++) {
          if(this.finishes[x]['pivot'].id == this.application.finish_unit_type_id) {
            this.selectedFinish = this.finishes[x];
            break;
          }
        }
      } else {
        this.selectedFinish = this.finishes[0];
      }
    }, (error) => {
      this.loading = false;
      this._toast_service.show(error.error.message, { classname: 'bg-danger text-light' });
    });
  }

  mapFinishComparisons(finishes): {} {
    let comparison = {};

    for(let x = 0; x < finishes.length; x++) {
      for(let y = 0; y < finishes[x]['comparison_images'].length; y++) {
        let media = finishes[x]['comparison_images'][y];

        if(!comparison[media['custom_properties']['label']]) comparison[media['custom_properties']['label']] = {};
        if(!comparison[media['custom_properties']['label']]['images']) comparison[media['custom_properties']['label']]['images'] = [];

        comparison[media['custom_properties']['label']]['images'].push({
          'name': finishes[x].name,
          'url': media.url
        });
      }
    }

    let keys = this.getObjectKeys(comparison);

    for(let k = 0; k < keys.length; k++) {
      if(comparison[keys[k]]['images'][0]) {
        comparison[keys[k]]['active1'] = comparison[keys[k]]['images'][0];
      }
      if(comparison[keys[k]]['images'][1]) {
        comparison[keys[k]]['active2'] = comparison[keys[k]]['images'][1];
      }

    }

    return comparison;
  }

  save() {
    this.loading = true;
    this._unit_type_service.saveFinish$(this.selectedFinish['pivot'].id, this.token).subscribe(response => {
      this.loading = false;
      this._toast_service.show("Your finish selection has been saved", { classname: 'bg-success text-light' });
    }, (error) => {
      this.loading = false;
      this._toast_service.show(error.error.message, { classname: 'bg-danger text-light' });
    });
  }

  setActiveFinish(label, active, image) {
    this.finishComparisons[label][active] = image;
  }

  getObjectKeys(obj) {
    return Object.keys(obj);
  }

  displayOnly(): boolean{
      // "sbmtd" = Submitted
    return this.application.status.code === "sbmtd" ? true : false;
  }

}
