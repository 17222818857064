import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/user/services/user.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/models/user';


@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {
  
  links: Array<string>;
  isMenuCollapsed = true;
  user$: BehaviorSubject<User>;

  constructor(
    private _auth_service: UserService,
    private router: Router
  ) { }
  
  ngOnInit(): void {
    this.user$ = this._auth_service.currentUser$;
  }

  logout($event)
  {
    $event.preventDefault();
    this._auth_service.logout$().subscribe(()=>{
      //redirect a person to home
      this.router.navigate(['login']);

    });
  }

}
