import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import options from 'src/app/data/options.json'
import { ValidationService } from 'src/app/shared/services/validation.service';
import { ProgressReportService } from 'src/app/application/services/progress-report.service';


@Component({
  selector: 'app-document-uploads',
  templateUrl: './document-uploads.component.html',
  styleUrls: ['./document-uploads.component.scss']
})
export class DocumentUploadsComponent implements OnInit ,OnDestroy{
  options = options;
  document_uploads:FormGroup;
  idCopyErrors: any;
  proofOfResidenceErrors: any;
  salarySlipErrors: any;
  bankStatementErrors: any;
  ancContractErrors: any;
  subscriptions : Array<Subscription>;
  fileTypes:string="image/png, image/jpeg, application/pdf, image/bmp";

  @Input() bondApplication: FormGroup;
  @Input() index: number;
    constructor(private fb: FormBuilder,
    private _progressReport: ProgressReportService,
    private _validation_service: ValidationService,
  ) { 
    this.subscriptions = [];
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((s)=>{
      s.unsubscribe();
    })
  }

  ngOnInit(): void {
    this.AddToForm();
    this.subscriptions.push(
      this._validation_service.getSeverValidation$()
      .subscribe(errors => {
        this.setMultiFileSelectErrors(errors);
      }));
    
  }

  setMultiFileSelectErrors(errors)
  {
    this.salarySlipErrors = [];
    this.bankStatementErrors = [];
    this.idCopyErrors = [];
    this.proofOfResidenceErrors = [];
    this.ancContractErrors = [];
    let errorKeys = Object.keys(errors);

    errorKeys.forEach(field => {
      if(field.includes(`applicants.${this.index}.document_uploads.salary_slips`))
      {
        this.salarySlipErrors.push(errors[field]);
      }
      else if(field.includes(`applicants.${this.index}.document_uploads.bank_statements`))
      {
        this.bankStatementErrors.push(errors[field]);
      }
      else if(field.includes(`applicants.${this.index}.document_uploads.id_copy`))
      {
        this.idCopyErrors.push(errors[field]);
      }
      else if(field.includes(`applicants.${this.index}.document_uploads.proof_of_residence`))
      {
        this.proofOfResidenceErrors.push(errors[field]);
      } 
      else if(field.includes(`applicants.${this.index}.document_uploads.anc_contract`))
      {
        this.ancContractErrors.push(errors[field]);
      }
    });
  }


  get registerFormControl() {
    return this.document_uploads.controls;
  }

  AddToForm(): void{
    this.document_uploads = this.fb.group({
      id_copy:new FormControl(null, []),
      proof_of_residence:new FormControl(null, []),
      salary_slips:new FormControl(null, []),
      bank_statements:new FormControl(null, [])
    });
    this.bondApplication.addControl('document_uploads',this.document_uploads);
    this.onChanges();
  }

  getLabel(key)
  {
    if(this.document_uploads.get(key).value && this.document_uploads.get(key).value[0]["name"])
    {
      return  this.document_uploads.get(key).value[0]["name"];
    }
    return "Choose File";
  }

  get idLabel()
  {
    return this.getLabel("id_copy")
  }

  get proofOfResidencelabel()
  {
    return this.getLabel("proof_of_residence")
  }
  
  get salarySlipsLabel()
  {
    return this.getLabel("salary_slips")

  }
  
  get bankStatementslabel()
  {
    return this.getLabel("bank_statements")

  }
  
  get ancContractLabel()
  {
    return this.getLabel("anc_contract")

  }
  

  onChanges()
  {
    this.subscriptions.push(
    this.document_uploads.valueChanges.pipe(
      distinctUntilChanged(),
    ).subscribe((nv)=>{
      let fields = Object.keys(nv);
      let total = fields.length;
      let completedFields = 0;
      fields.forEach((field)=>{
        let fieldValue = this.document_uploads.get(field).value;
        if(fieldValue && (fieldValue instanceof File || (Array.isArray(fieldValue) && fieldValue.length > 0)))
        {
            if(Array.isArray(fieldValue)) {
              let fileCount = 0;
              for(let x = 0; x < fieldValue.length; x++) {
                if(fieldValue[x] instanceof File) {
                  fileCount++;
                }
              }
              if(fileCount == fieldValue.length) {
                completedFields += 1;
              }
            } else {
              completedFields += 1;
            }
        }  
      });
      let percentage = (completedFields/total) * 100;
      this._progressReport.setFileProgress(percentage, this.index);
    }));


    const marital_status_prompters = this.options['marital_status'].filter(option => option.has_anc_contract);
    const marital_status_prompters_values = marital_status_prompters.map(option => option.value);

    this.bondApplication.get('personal_details.marital_status').valueChanges.subscribe(nv=>{
      if(marital_status_prompters_values.includes(nv))
      {
        this.document_uploads.addControl('anc_contract',this.fb.control(null))

      }else
      {
        this.document_uploads.removeControl('anc_contract');
      }
    });
  }
  
}
