<fieldset [formGroup]="prominent_influential_persons">

    <p>
        Prominent Influential Persons (PIP) are individuals entrusted with prominent public functions either domestically or by a foreign country. Examples are heads of state or heads of government, important political party officials, military officials, or senior executives of state-owned corporations. This term also includes immediate family members and close associates.
    </p>
<!-- start of  Are you a public official in a position of authority field -->
<div class="form-group">
    <label for="position_of_authority-field">Are you a public official in a position of authority</label>
    <div class="row">
      <div class="col-md pr-2 pl-2">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="position_of_authority" id="position_of_authority-yes{{index}}" value="1" >
          <label class="form-check-label" for="position_of_authority-yes{{index}}">
            Yes
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="position_of_authority" id="position_of_authority-no{{index}}" value="0">
          <label class="form-check-label" for="position_of_authority-no{{index}}">
            No
          </label>
        </div> 
      </div>
    </div>


    <app-invalid-feedback  [ngClass]="{'d-block':prominent_influential_persons.get('position_of_authority').invalid && (prominent_influential_persons.get('position_of_authority').dirty || prominent_influential_persons.get('position_of_authority').touched) }" class="invalid-feedback" name="This " [control]="prominent_influential_persons.get('position_of_authority')"></app-invalid-feedback>
</div>
<!-- end of  Are you a public official in a position of authority field -->

<!-- start of  Are you related to or associated with a public official in a position of authority  field -->
<div class="form-group">
    <label for="related_to_position_of_authority-field">Are you related to or associated with a public official in a position of authority</label>
    <div class="row">
      <div class="col-md pr-2 pl-2">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="related_to_position_of_authority" id="related_to_position_of_authority-yes{{index}}" value="1" >
          <label class="form-check-label" for="related_to_position_of_authority-yes{{index}}">
            Yes
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="related_to_position_of_authority" id="related_to_position_of_authority-no{{index}}" value="0">
          <label class="form-check-label" for="related_to_position_of_authority-no{{index}}">
            No
          </label>
        </div> 
      </div>
    </div>


    <app-invalid-feedback  [ngClass]="{'d-block':prominent_influential_persons.get('related_to_position_of_authority').invalid && (prominent_influential_persons.get('related_to_position_of_authority').dirty || prominent_influential_persons.get('related_to_position_of_authority').touched) }" class="invalid-feedback" name="This " [control]="prominent_influential_persons.get('related_to_position_of_authority')"></app-invalid-feedback>
  </div>
<!-- end of  Are you related to or associated with a public official in a position of authority  field -->

<div class="row">
    <div class="col-md-6">
            <!-- start of  Please provide full name and surname of relative or associate field -->
            <div class="form-group"  *ngIf="prominent_influential_persons.get('fullname')">
                <label for="title-field">Please provide the full name and surname of relative or associate</label>
                <input formControlName="fullname" type="input" class="form-control" [ngClass]="{'is-invalid':prominent_influential_persons.get('fullname').invalid && (prominent_influential_persons.get('fullname').dirty || prominent_influential_persons.get('fullname').touched) }" id="title-field">
                <app-invalid-feedback  class="invalid-feedback" name="Please provide full name and surname of relative or associate" [control]="prominent_influential_persons.get('fullname')"></app-invalid-feedback>
            </div>

            <!-- end of  Please provide full name and surname of relative or associate field -->
    </div>
    <div class="col-md-6">
            <!-- start of  What is the nature of relationship or association (if applicable)? field -->
            <div class="form-group" *ngIf="prominent_influential_persons.get('nature_of_relation')">
                <label for="title-field">What is the nature of relationship or association (if applicable)?</label>
                <select formControlName="nature_of_relation" type="input" class="form-control" [ngClass]="{'is-invalid':prominent_influential_persons.get('fullname').invalid && (prominent_influential_persons.get('fullname').dirty || prominent_influential_persons.get('fullname').touched) }" id="title-field">
                    <option class="d-none" [ngValue]="''">
                        Select relationship type
                    </option>
                    <option *ngFor="let option of options['nature_of_relationship']"  [ngValue]="option.value">
                        {{option.name }}
                    </option>
                </select>

                <app-invalid-feedback  class="invalid-feedback" name="What is the nature of relationship or association (if applicable)?" [control]="prominent_influential_persons.get('fullname')"></app-invalid-feedback>
            </div>
            <!-- end of  What is the nature of relationship or association (if applicable)? field -->
    </div>
</div>



</fieldset>
    
    
    