import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DependecyFieldsService } from 'src/app/shared/services/dependecy-fields.service';
import options from 'src/app/data/options.json'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-prominent-influential-persons',
  templateUrl: './prominent-influential-persons.component.html',
  styleUrls: ['./prominent-influential-persons.component.scss']
})
export class ProminentInfluentialPersonsComponent implements OnInit,OnDestroy{

  prominent_influential_persons:FormGroup;
  @Input() bondApplication: FormGroup;
  @Input() index: number;
  options = options;
  subscriptions: Array<Subscription> = []
  constructor(private fb: FormBuilder,
     private _dependecy_fields_service: DependecyFieldsService
  ) { 
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>{sub.unsubscribe()})
  }

  ngOnInit(): void {
    this.AddToForm();
  }

  get registerFormControl() {
    return this.prominent_influential_persons.controls;
  }

  AddToForm(): void{
    this.prominent_influential_persons = this.fb.group({
        position_of_authority: ['',[Validators.required]],
        related_to_position_of_authority: ['',[Validators.required]],
    });

    this.bondApplication.addControl('prominent_influential_persons',this.prominent_influential_persons);
    
    let dependecyFields = [
      {
        "field":"related_to_position_of_authority",
        "values":[true,1,"1"],
         "depedent_fields":[
           { 
             "name": "fullname",
             "control":this.fb.control('',[Validators.required])
            },
            { 
              "name": "nature_of_relation",
              "control":this.fb.control('',[Validators.required])
            }
        ]
      }
    ];
    this.subscriptions.push(...
   this._dependecy_fields_service.setConditionalFields(this.prominent_influential_persons,dependecyFields)
    )
  }

}
