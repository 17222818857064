export class PropertyDetails {
    development_name: string;
    unit_number: string;
    property_description: string;
    property_type: string;
    street_name: string;
    suburb: string;
    city: string;
    province: string;
    transferring_attorney_name: string;
    ownership_type: any;
}
