<div class="container">

<div  class="card craft-card bg-light mt-5">
    <div class="card-body">
      <h3 class="text-center mb-4">Setup a New Password</h3>
      <form [formGroup]="passwordSetup">
          <div class="row" [ngClass]="{'mb-2': passwordSetup.get('password')}">
            <div class="col-lg-4 col-md-4 m-auto">
              <!-- start of  Email field -->
                <div class="form-group craft-form-group">
                    <input placeholder="Email" formControlName="email" type="email" class="form-control" [ngClass]="{'is-invalid':passwordSetup.get('email').invalid && (passwordSetup.get('email').dirty || passwordSetup.get('email').touched) }" id="email-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Email" [control]="passwordSetup.get('email')"></app-invalid-feedback>
                </div>
              <!-- end of Email Field -->
            </div>
          </div>

          <div class="col-lg-4 col-md-4 m-auto">
            <!-- start of  token field -->
            <input placeholder=""  *ngIf="passwordSetup.get('token')" formControlName="token" type="hidden" class="form-control">
            <!-- end of token Field -->
          </div>

          <div class="row mb-2">
            <div class="col-lg-4 col-md-4 m-auto">
              <!-- start of  Email field -->
                <div class="form-group craft-form-group" *ngIf="passwordSetup.get('password')">
                  <input placeholder="Password" formControlName="password" type="password" class="form-control" [ngClass]="{'is-invalid':passwordSetup.get('password').invalid && (passwordSetup.get('password').dirty || passwordSetup.get('password').touched) }" id="email-field">
                  <app-invalid-feedback  class="invalid-feedback" name="Password" [control]="passwordSetup.get('password')"></app-invalid-feedback>
                </div>
              <!-- end of Email Field -->
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-lg-4 col-md-4 m-auto">
              <!-- start of  Email field -->
                <div class="form-group craft-form-group" *ngIf="passwordSetup.get('password_confirmation')">
                  <input placeholder="Confirm Password" formControlName="password_confirmation" type="password" class="form-control" [ngClass]="{'is-invalid':passwordSetup.get('password_confirmation').invalid && (passwordSetup.get('password_confirmation').dirty || passwordSetup.get('password_confirmation').touched) }" id="email-field">
                  <app-invalid-feedback  class="invalid-feedback" name="Confirm Password" [control]="passwordSetup.get('password_confirmation')"></app-invalid-feedback>
                </div>
              <!-- end of Email Field -->
            </div>
          </div>

          <div class="row mb-4">
            <button [appLoader]="formLoading" type="submit" class="btn btn-primary d-block m-auto" (click)="onSubmit()">Set Password</button>
          </div>

      </form>
  </div>
  </div>
  </div>