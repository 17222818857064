import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-monthly-gross-income',
  templateUrl: './monthly-gross-income.component.html',
  styleUrls: ['./monthly-gross-income.component.scss']
})
export class MonthlyGrossIncomeComponent implements OnInit {

  monthly_gross_income:FormGroup;
  @Input() bondApplication: FormGroup;
  @Input() index: number;
  requiredMessage = "Value must be 0 or higher";

  constructor(private fb: FormBuilder,
    private utilityService: UtilityService 
    //  private _validation_service: ValidationService
  ) { 
  }

  ngOnInit(): void {
    this. AddToForm();
  }

  AddToForm(): void{
    this.monthly_gross_income = this.fb.group({
        gross_monthly_salary_income:['0',[Validators.required]],
        commission:['0',[Validators.required]],
        overtime:['0',[Validators.required]],
        maintenance:['0',[Validators.required]],
        rental_income:['0',[Validators.required]],
        pension:['0',[Validators.required]],
        other:['0'],
        other_specification:[''],
    });
    this.bondApplication.addControl('monthly_gross_income',this.monthly_gross_income);
  }

  get monthly_gross_income_total()
  {
    return this.utilityService.valuesSum(this.monthly_gross_income.value,[
          "gross_monthly_salary_income",
          "commission",
          "overtime",
          "maintenance",
          "rental_income",
          "pension",
          "other",
    ]);
  }

}
