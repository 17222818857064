<fieldset [formGroup]="loan_details">
  <div class="row">
    <div class="col">
      Please note that the cost of the Extras cannot be included in your loan amount.
    </div>
  </div>
  <div class="row mb-3">
    <div class="col">
      You cannot put in an amount higher than the purchase price.
    </div>
  </div>
  <div class="row">
    <div class="col-md">
          <!-- start of  Purchase Price (ISM) (SIMS) field -->
          <div class="form-group">
            <label for="purchase_price-field">Purchase Price</label>
            <input currencyMask formControlName="purchase_price" type="input" class="form-control" [ngClass]="{'is-invalid':loan_details.get('purchase_price').invalid && (loan_details.get('purchase_price').dirty || loan_details.get('purchase_price').touched) }" readonly id="purchase_price-field">
            <app-invalid-feedback  class="invalid-feedback" name="Purchase Price (ISM) (SIMS)" [control]="loan_details.get('purchase_price')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
        </div>
        <!-- end of Purchase Price (ISM) (SIMS) Field -->
    </div>
    <div class="col-md">
      <!-- start of  Loan Amount field -->
      <div class="form-group">
        <label for="loan_amount-field">Loan Amount</label>
        <div class="input-group">
        <input currencyMask formControlName="loan_amount" (focus)="$event.target.select()" type="input" class="form-control" [ngClass]="{'is-invalid':loan_details.get('loan_amount').invalid && (loan_details.get('loan_amount').dirty || loan_details.get('loan_amount').touched) }" id="loan_amount-field">
        <div class="input-group-append pl-2">
          <button type="button" class="btn btn-primary" (click)="fill()">Full Amount</button>
        </div>
        <app-invalid-feedback  class="invalid-feedback" name="Loan Amount" [control]="loan_details.get('loan_amount')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
      </div>
      </div>
      <!-- end of Loan Amount Field -->
    </div>
  </div>
  <div class="row">
    <div class="col-md">
        <!-- start of Loan Amount field -->
        <div class="form-group">
          <label for="loan_term-field">Loan Term (Years)</label>
          <select formControlName="loan_term"  class="form-control" [ngClass]="{'is-invalid':loan_details.get('loan_term').invalid && (loan_details.get('loan_term').dirty || loan_details.get('loan_term').touched) }" id="loan_term-field">
            <option class="d-none" [ngValue]="''">
                Select Loan Term
            </option>
            <option *ngFor="let option of options['loan_term']"  [ngValue]="option.value">
                {{option.name }}
            </option>
        </select>
          <app-invalid-feedback  class="invalid-feedback" name="Loan Term" [control]="loan_details.get('loan_term')"></app-invalid-feedback>
      </div>
      <!-- end of Loan Amount Field -->
    </div>
    <div class="col-md">
        <!-- start of  Monthly Repayment Day field -->
        <div class="form-group">
          <label for="monthly_repayment_day-field">Monthly Repayment Day</label>
          <select formControlName="monthly_repayment_day" class="form-control" [ngClass]="{'is-invalid':loan_details.get('monthly_repayment_day').invalid && (loan_details.get('monthly_repayment_day').dirty || loan_details.get('monthly_repayment_day').touched) }" id="monthly_repayment_day-field">
            <option *ngFor="let month of monthly" [ngValue]="month">{{month}}</option>
          </select>
          <app-invalid-feedback  class="invalid-feedback" name="Monthly Repayment Day" [control]="loan_details.get('monthly_repayment_day')"></app-invalid-feedback>
        </div>
        <!-- end of Monthly Repayment Day Field -->
    </div>
  </div>
</fieldset>