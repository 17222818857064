<div class="error-page position-relative">
    <div class="container center-error-block">
        <div class="row">
            <div class="col-4">
                <h1 class="text-primary large-text">
                    404
                </h1>
            </div>
            <div class="col-8">
                <div class="row">
                    <div class="col">
                        <img src="assets/image/craft_logo.png"  alt="craft-logo" class="img-fluid">
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <h3>
                            The link is broken or the page has been moved
                        </h3>
                    </div>
                </div>
              
            </div>
        </div>
    </div>   
    
</div>



