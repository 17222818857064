import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class ProgressReportService {
  
  fileProgress: any; // was type issue not sure if it
  constructor() { }

  public progress(form)
  {
    if(form)
    {
      const fields = Object.keys(form.controls);
      if(fields.length == 0)
      {
        return 100;
      }
      const total = fields.length;
      let validFields = 0;
      fields.forEach(key => {
          if(form.get(key).valid)
          {
            validFields+=1;
          }
      });
      return (validFields/total) * 100;
    }{
      return 0;
    }
  }
  
  public setFileProgress(progress, index)
  {
    if(!this.fileProgress) {
      this.fileProgress = [];
    }
    this.fileProgress[index] = progress;
  }

  public getFileProgress()
  {
    return this.fileProgress;
  }

  public clearFileProgress()
  {
    this.fileProgress = null;
  }
}
