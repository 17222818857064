 <fieldset [formGroup]="personal_details">

    <div class="row">
        <div class="col-md-6 override-p-m">
            <div class="row">
                <div class="col-md override-p-m">
                    <!-- start of  Title field -->
                   <div class="form-group">
                       <label for="title-field">Title</label>
                       <input formControlName="title" type="input" class="form-control" [ngClass]="{'is-invalid':personal_details.get('title').invalid && (personal_details.get('title').dirty || personal_details.get('title').touched) }" id="title-field">
                       <app-invalid-feedback  class="invalid-feedback" name="Title" [control]="personal_details.get('title')"></app-invalid-feedback>
                   </div>
                   <!-- end of Title Field -->
               </div>
               <div class="col-md override-p-m">
                <!-- start of  First Name field -->
                <div class="form-group">
                    <label for="first_name-field">First Name</label>
                    <input formControlName="first_name" type="input" class="form-control" [ngClass]="{'is-invalid':personal_details.get('first_name').invalid && (personal_details.get('first_name').dirty || personal_details.get('first_name').touched) }" id="first_name-field">
                    <app-invalid-feedback  class="invalid-feedback" name="First Name" [control]="personal_details.get('first_name')"></app-invalid-feedback>
                </div>
                <!-- end of  First Name field -->
            </div>
            </div>
        </div>
        <div class="col-md-6 override-p-m">
            <div class="row">
                <div class="col-md override-p-m">
                    <!-- start of  Second Name field -->
                    <div class="form-group">
                    <label for="first_name-field">Second Name</label>
                    <input formControlName="second_name" type="input" class="form-control" [ngClass]="{'is-invalid':personal_details.get('second_name').invalid && (personal_details.get('second_name').dirty || personal_details.get('second_name').touched) }" id="second_name-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Second Name" [control]="personal_details.get('second_name')"></app-invalid-feedback>
                </div>
                <!-- end of  Second Name field -->
                </div>
                <div class="col-md override-p-m">
                    <!-- start of  last_name field -->
                    <div class="form-group">
                        <label for="last_name-field">Last Name</label>
                        <input formControlName="last_name" type="input" class="form-control" [ngClass]="{'is-invalid':personal_details.get('last_name').invalid && (personal_details.get('last_name').dirty || personal_details.get('last_name').touched) }" id="last_name-field">
                        <app-invalid-feedback  class="invalid-feedback" name="last_name" [control]="personal_details.get('last_name')"></app-invalid-feedback>
                    </div>
                    <!-- end of  last_name field -->
                </div>
            </div>
        </div>
    
    </div>

    <div class="row">
        <div class="col-md-6 override-p-m">
            <div class="row">
                <div class="col-md override-p-m">
                    <!-- start of  Date of birth (Calendar) field -->
                    <div class="form-group">
                        <label for="title-field">Date of birth</label>
                        <!-- <input formControlName="date_of_birth" type="input" class="form-control" [ngClass]="{'is-invalid':personal_details.get('date_of_birth').invalid && (personal_details.get('date_of_birth').dirty || personal_details.get('date_of_birth').touched) }" id="title-field"> -->
                        <div class="input-group" [ngClass]="{'is-invalid':personal_details.get('date_of_birth').invalid && (personal_details.get('date_of_birth').dirty || personal_details.get('date_of_birth').touched) }">
                        
                            <input class="form-control"  [placement]="'bottom'" placeholder="dd-mm-yy"  [minDate]="{year: 1900, month: 1, day: 1}" formControlName="date_of_birth" ngbDatepicker [placement]="'bottom'"  #d="ngbDatepicker" [ngClass]="{'is-invalid':personal_details.get('date_of_birth').invalid && (personal_details.get('date_of_birth').dirty || personal_details.get('date_of_birth').touched) }">
                            <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar shade" (click)="d.toggle()" type="button">
                                <fa-icon [icon]="faCalendar"></fa-icon>
                            </button>
                            </div>
                        </div>
                        <app-invalid-feedback  class="invalid-feedback" name="Date of birth" [control]="personal_details.get('date_of_birth')"></app-invalid-feedback>
                        
                    </div>
                    <!-- end of  Date of birth (Calendar) field -->
                </div>
                <div class="col-md override-p-m">
                        <!-- start of  Gender field -->
                        <div class="form-group">
                            <label for="title-field">Gender</label>
                            <select formControlName="gender" class="form-control" [ngClass]="{'is-invalid':personal_details.get('gender').invalid && (personal_details.get('gender').dirty || personal_details.get('gender').touched) }" id="title-field">
                                <option class="d-none" [ngValue]="''">
                                    Select Gender
                                </option>
                                <option *ngFor="let option of options['gender']"  [ngValue]="option.value">
                                    {{option.name }}
                                </option>
                            </select>
                            <app-invalid-feedback  class="invalid-feedback" name="Gender" [control]="personal_details.get('gender')"></app-invalid-feedback>
                        </div>
                        <!-- end of  Gender field -->
                </div>
            </div>
        </div>
        <div class="col-md-6 override-p-m">
            <div class="row">
                <div class="col-md override-p-m">
                    <!-- start of  ID Type field -->
                    <div class="form-group">
                        <label for="title-field">ID Type</label>
                        <select formControlName="id_type" type="input" class="form-control" [ngClass]="{'is-invalid':personal_details.get('id_type').invalid && (personal_details.get('id_type').dirty || personal_details.get('id_type').touched) }" id="title-field">
                            <option class="d-none" [ngValue]="''">
                                Select ID Type
                            </option>
                            <option *ngFor="let group of options['id_types']"  [ngValue]="group.value">
                                {{group.name }}
                            </option>
                        </select>
                        
                        <app-invalid-feedback  class="invalid-feedback" name="ID Type" [control]="personal_details.get('id_type')"></app-invalid-feedback>
                    </div>
                    <!-- end of  ID Type field -->
                </div>

                <div class="col-md" *ngIf="personal_details.get('id_number')">
                    <!-- start of  ID Number field -->
                    <div class="form-group">
                        <label for="title-field">ID Number</label>
                        <input mask="000000 0000 000" formControlName="id_number" type="input" class="form-control" [ngClass]="{'is-invalid':personal_details.get('id_number').invalid && (personal_details.get('id_number').dirty || personal_details.get('id_number').touched) }" id="title-field">
                        <app-invalid-feedback  class="invalid-feedback" name="ID Number" [control]="personal_details.get('id_number')"></app-invalid-feedback>
                    </div>
                    <!-- end of  ID Number field -->
                </div>

                <div class="col-md override-p-m" *ngIf="personal_details.get('passport_number')">
                    <!-- start of Passport Number field -->
                    <div class="form-group">
                        <label for="title-field">Passport Number</label>
                        <input formControlName="passport_number" type="input" class="form-control" [ngClass]="{'is-invalid':personal_details.get('passport_number').invalid && (personal_details.get('passport_number').dirty || personal_details.get('passport_number').touched) }" id="title-field">
                        <app-invalid-feedback  class="invalid-feedback" name="Passport Number" [control]="personal_details.get('passport_number')"></app-invalid-feedback>
                    </div>
                    <!-- end of  Passport Number field -->
                </div>
            </div>
        </div>
    </div>




        <div class="row">
            <div class="col-md-6 override-p-m">
                <div class="row">
                    <div class="col-md">
                        <!-- start of  Nationality field -->
                        <div class="form-group">
                            <label for="title-field">Nationality</label>
                            <input formControlName="nationality" type="input" class="form-control" [ngClass]="{'is-invalid':personal_details.get('nationality').invalid && (personal_details.get('nationality').dirty || personal_details.get('nationality').touched) }" id="title-field">
                            <app-invalid-feedback  class="invalid-feedback" name="Nationality" [control]="personal_details.get('nationality')"></app-invalid-feedback>
                        </div>
                        <!-- end of  Nationality field -->
                    </div>
                    <div class="col-md override-p-m">
                        <!-- start of  Ethnic Group field -->
                        <div class="form-group">
                            <label for="title-field">Ethnic Group</label>
                            <select formControlName="ethnic_group"  class="form-control" [ngClass]="{'is-invalid':personal_details.get('ethnic_group').invalid && (personal_details.get('ethnic_group').dirty || personal_details.get('ethnic_group').touched) }" id="title-field">
                                <option class="d-none" [ngValue]="''">
                                    Select Ethnic Group
                                </option>
                                <option *ngFor="let group of options['ethnic_group']"  [ngValue]="group.value">
                                    {{group.name }}
                                </option>
                            </select>
                            <app-invalid-feedback  class="invalid-feedback" name="Ethnic Group" [control]="personal_details.get('ethnic_group')"></app-invalid-feedback>
                        </div>
                        <!-- end of  Ethnic Group field -->
                    </div>
                </div>
            </div>

            <div class="col-md-6 override-p-m">
                <div class="row">
                    <div class="col-md">
                        <!-- start of  Marital Status field -->
                        <div class="form-group">
                            <label for="title-field">Marital Status</label>
                            <select formControlName="marital_status" type="input" class="form-control" [ngClass]="{'is-invalid':personal_details.get('marital_status').invalid && (personal_details.get('marital_status').dirty || personal_details.get('marital_status').touched) }" id="title-field">
                                <option class="d-none" [ngValue]="''">
                                    Select Marital Status
                                </option>
                                <option *ngFor="let option of options['marital_status']"  [ngValue]="option.value">
                                    {{option.name }}
                                </option>
                            </select>
                            <app-invalid-feedback  class="invalid-feedback" name="Marital Status" [control]="personal_details.get('marital_status')"></app-invalid-feedback>
                        </div>
                        <!-- end of  Marital Status field -->
                    </div>
                    <div class="col-md override-p-m">
                            <!-- start of  Number of dependants  field -->
                        <div class="form-group">
                            <label for="title-field">Number of dependants </label>
                            <input formControlName="number_of_dependants" type="number" min="0" class="form-control" [ngClass]="{'is-invalid':personal_details.get('number_of_dependants').invalid && (personal_details.get('number_of_dependants').dirty || personal_details.get('number_of_dependants').touched) }" id="title-field">
                            <app-invalid-feedback  class="invalid-feedback" name="Number of dependants " [control]="personal_details.get('number_of_dependants')"></app-invalid-feedback>
                        </div>
                        <!-- end of  Number of dependants  field -->
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 override-p-m" *ngIf="personal_details.get('other_ethnic_group_specification')">
                <!-- start of  Ethnic Group Specification  field -->
                <div class="form-group">
                    <label for="title-field">Please Specify Ethnic Group</label>
                    <input formControlName="other_ethnic_group_specification" type="input" class="form-control" [ngClass]="{'is-invalid':personal_details.get('other_ethnic_group_specification').invalid && (personal_details.get('other_ethnic_group_specification').dirty || personal_details.get('other_ethnic_group_specification').touched) }" id="title-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Ethnic Group Specification " [control]="personal_details.get('number_of_dependants')"></app-invalid-feedback>
                </div>
                <!-- end of  Ethnic Group Specification  field -->
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 override-p-m">
     
                        <!-- start of  Highest Qualification field -->
                        <div class="form-group">
                            <label for="title-field">Highest Qualification</label>
                            <select formControlName="highest_qualification" type="input" class="form-control" [ngClass]="{'is-invalid':personal_details.get('highest_qualification').invalid && (personal_details.get('highest_qualification').dirty || personal_details.get('highest_qualification').touched) }" id="title-field">
                                <option class="d-none" [ngValue]="''">
                                    Select Highest Qualification
                                </option>
                                <option *ngFor="let option of options['highest_qualification']"  [ngValue]="option.value">
                                    {{option.name }}
                                </option>
                            </select>
                            <app-invalid-feedback  class="invalid-feedback" name="Highest Qualification" [control]="personal_details.get('highest_qualification')"></app-invalid-feedback>
            
                </div>
             
            </div>


            <div class="col-md-6 override-p-m" *ngIf="personal_details.get('other_highest_qualification_specification')">
                <!-- start of  Qualification Specification  field -->
                <div class="form-group">
                    <label for="title-field">Qualification Specification </label>
                    <input formControlName="other_highest_qualification_specification" type="input" class="form-control" [ngClass]="{'is-invalid':personal_details.get('other_highest_qualification_specification').invalid && (personal_details.get('other_highest_qualification_specification').dirty || personal_details.get('other_highest_qualification_specification').touched) }" id="title-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Qualification Specification " [control]="personal_details.get('number_of_dependants')"></app-invalid-feedback>
                </div>
                <!-- end of  Qualification Specification  field -->
            </div>
        </div>
</fieldset>
