import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EchoService } from 'ngx-laravel-echo';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ImpersonationService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  
  constructor(
    private echo: EchoService,
    private _user_service: UserService,
    private http: HttpClient
  ) { }


  public impersonationRequest$(user_id)
	{
    this._user_service.echoLogin();
    return this.echo.join(`impersonation-request.${user_id}`,'presence')
    .listen(`impersonation-request.${user_id}`,".App\\Events\\ImpersinationRequestCreatedEvent")
  }

  public updateRequest$(id,data)
  {
    return this.http.put(`${environment.api.base_url}/impersonation-request/${id}`, data, this.httpOptions);
  }
  
}
