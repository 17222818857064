<fieldset [formGroup]="employment_information">
    
    <div class="row">
        <div class="col-md override-p-m">
                <!-- start of  Current Employment Type field -->
                <div class="form-group">
                    <label for="current_employment_type-field">Current Employment Type</label>
                   
                        <select formControlName="current_employment_type" class="form-control" [ngClass]="{'is-invalid':employment_information.get('current_employment_type').invalid && (employment_information.get('current_employment_type').dirty || employment_information.get('current_employment_type').touched) }" id="current_employment_type-field">
                            <option class="d-none" [ngValue]="''">
                                Select preferred
                            </option>
                            <option *ngFor="let option of options['current_employment_type']"  [ngValue]="option.value">
                                {{option.name }}
                            </option>
                        </select>
                   
                    <app-invalid-feedback  class="invalid-feedback" name="Current Employment Type" [control]="employment_information.get('current_employment_type')"></app-invalid-feedback>
                </div>
                <!-- end of Current Employment Type Field -->
        </div>
        <div class="col-md override-p-m">
            <!-- start of  Occupation Industry field -->
            <div class="form-group" *ngIf="employment_information.get('occupation_industry')">
                <label for="occupation_industry-field">Occupation Industry</label>
                <input formControlName="occupation_industry" type="input" class="form-control" [ngClass]="{'is-invalid':employment_information.get('occupation_industry').invalid && (employment_information.get('occupation_industry').dirty || employment_information.get('occupation_industry').touched) }" id="occupation_industry-field">
                <app-invalid-feedback  class="invalid-feedback" name="Occupation Industry" [control]="employment_information.get('occupation_industry')"></app-invalid-feedback>
            </div>
            <!-- end of Occupation Industry Field -->
        </div>
    </div>
    <div class="row">
        <div class="col-md override-p-m" >
               <!-- start of Other Ocupation type Specification field -->
               <div class="form-group" *ngIf="employment_information.get('other_current_employment_type_specification')">
                <label for="occupation_level-field">Please specify employment type</label>
                <input formControlName="other_current_employment_type_specification" type="input" class="form-control" [ngClass]="{'is-invalid':employment_information.get('other_current_employment_type_specification').invalid && (employment_information.get('other_current_employment_type_specification').dirty || employment_information.get('other_current_employment_type_specification').touched) }" id="other_current_employment_type_specification-field">
                <app-invalid-feedback  class="invalid-feedback" name="Please specify employment type" [control]="employment_information.get('other_current_employment_type_specification')"></app-invalid-feedback>
            </div>
            <!-- end of Other Ocupation type Specification Field -->
        </div>
    </div>

    <div class="row">
        <div class="col-md override-p-m">
            <!-- start of Occupation Level field -->
            <div class="form-group" *ngIf="employment_information.get('occupation_level')">
                <label for="occupation_level-field">Occupation Level</label>
                <input formControlName="occupation_level" type="input" class="form-control" [ngClass]="{'is-invalid':employment_information.get('occupation_level').invalid && (employment_information.get('occupation_level').dirty || employment_information.get('occupation_level').touched) }" id="occupation_level-field">
                <app-invalid-feedback  class="invalid-feedback" name="Occupation Level" [control]="employment_information.get('occupation_level')"></app-invalid-feedback>
            </div>
            <!-- end of Occupation Level Field -->
        </div>
        <div class="col-md override-p-m">
            <!-- start of  Present Employer Name field -->
            <div class="form-group" *ngIf="employment_information.get('present_employer_name')">
                <label for="present_employer_name-field">Present Employer Name</label>
                <input formControlName="present_employer_name" type="input" class="form-control" [ngClass]="{'is-invalid':employment_information.get('present_employer_name').invalid && (employment_information.get('present_employer_name').dirty || employment_information.get('present_employer_name').touched) }" id="present_employer_name-field">
                <app-invalid-feedback  class="invalid-feedback" name="Present Employer Name" [control]="employment_information.get('present_employer_name')"></app-invalid-feedback>
            </div>
            <!-- end of Present Employer Name Field -->
        </div>
    </div>
    <div class="row">
        <div class="col-md override-p-m">
            <!-- start of Employer Telephone number field -->
            <div class="form-group" *ngIf="employment_information.get('employer_telephone_number')">
                <label for="employer_telephone_number-field">Employer Telephone number</label>
                <input formControlName="employer_telephone_number" type="input" class="form-control" [ngClass]="{'is-invalid':employment_information.get('employer_telephone_number').invalid && (employment_information.get('employer_telephone_number').dirty || employment_information.get('employer_telephone_number').touched) }" id="employer_telephone_number-field">
                <app-invalid-feedback  class="invalid-feedback" name="Employer Telephone number" [control]="employment_information.get('employer_telephone_number')"></app-invalid-feedback>
            </div>
            <!-- end of Employer Telephone number Field -->
        </div>
        <div class="col-md override-p-m">
            <!-- start of Time with present employer field -->
            <div class="form-group" *ngIf="employment_information.get('time_with_present_employer')">
                <label for="time_with_present_employer-field">Time with present employer</label>
                <input formControlName="time_with_present_employer" type="input" class="form-control" [ngClass]="{'is-invalid':employment_information.get('time_with_present_employer').invalid && (employment_information.get('time_with_present_employer').dirty || employment_information.get('time_with_present_employer').touched) }" id="time_with_present_employer-field">
                <app-invalid-feedback  class="invalid-feedback" name="Time with present employer" [control]="employment_information.get('time_with_present_employer')"></app-invalid-feedback>
            </div>
            <!-- end of Time with present employer Field -->
        </div>
    </div>
    <div class="row">
        <div class="col-md override-p-m">
            <!-- start of  Source of Income for loan repayment field -->
            <div class="form-group">
                <label for="source_of_income_for_loan_repayment-field">Source of Income for loan repayment</label>
                <select formControlName="source_of_income_for_loan_repayment" class="form-control" [ngClass]="{'is-invalid':employment_information.get('source_of_income_for_loan_repayment').invalid && (employment_information.get('source_of_income_for_loan_repayment').dirty || employment_information.get('source_of_income_for_loan_repayment').touched) }" id="source_of_income_for_loan_repayment-field">
                    <option class="d-none" [ngValue]="''">
                        Select preferred
                    </option>
                    <option *ngFor="let option of options['loan_repayment']"  [ngValue]="option.value">
                        {{option.name }}
                    </option>
                </select>
                
                <app-invalid-feedback  class="invalid-feedback" name="Source of Income for loan repayment" [control]="employment_information.get('source_of_income_for_loan_repayment')"></app-invalid-feedback>
            </div>
            <!-- end of Source of Income for loan repayment Field -->
        </div>
        <div class="col-md override-p-m">
            <!-- start of Tax Number field -->
            <div class="form-group">
                <label for="tax_number-field">Tax Number</label>
                <input formControlName="tax_number" type="input" class="form-control" [ngClass]="{'is-invalid':employment_information.get('tax_number').invalid && (employment_information.get('tax_number').dirty || employment_information.get('tax_number').touched) }" id="tax_number-field">
                <app-invalid-feedback  class="invalid-feedback" name="Tax Number" [control]="employment_information.get('tax_number')"></app-invalid-feedback>
            </div>
            <!-- end of Tax Number Field -->
        </div>
    </div>
    <div class="row">
        <div class="col-6 override-p-m">
            <!-- start of Other source of Income for loan repayment Specification field -->
            <div class="form-group" *ngIf="employment_information.get('other_source_of_income_for_loan_repayment_specification')">
                <label for="other_source_of_income_for_loan_repayment_specification-field">Other source of Income for loan repayment Specification</label>
                <input formControlName="other_source_of_income_for_loan_repayment_specification" type="input" class="form-control" [ngClass]="{'is-invalid':employment_information.get('other_source_of_income_for_loan_repayment_specification').invalid && (employment_information.get('other_source_of_income_for_loan_repayment_specification').dirty || employment_information.get('other_source_of_income_for_loan_repayment_specification').touched) }" id="other_source_of_income_for_loan_repayment_specification-field">
                <app-invalid-feedback  class="invalid-feedback" name="Other source of Income for loan repayment Specification" [control]="employment_information.get('other_source_of_income_for_loan_repayment_specification')"></app-invalid-feedback>
            </div>
            <!-- end of Other source of Income for loan repayment Specification Field -->
        </div>

    </div>
</fieldset>