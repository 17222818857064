
<div class="container">
  <div *ngIf="componentStatus.display_form; else messageBlock " class="card craft-card bg-light mt-5">
    <div class="card-body"> 
      <h3 class="text-center mb-4">Change Password</h3>
      <p class="text-center mb-4">Should you need to change your password please follow the steps below.</p>
      <form [formGroup]="changePassword">

          <div class="row mb-2">
            <div class="col-lg-4 col-md-4 m-auto">
              <!-- start of  Email field -->
            <div class="form-group craft-form-group">
              <input placeholder="Old Password" formControlName="old_password" type="password" class="form-control" [ngClass]="{'is-invalid':changePassword.get('old_password').invalid && (changePassword.get('old_password').dirty || changePassword.get('old_password').touched) }" id="email-field">
              <app-invalid-feedback  class="invalid-feedback" name="Old password" [control]="changePassword.get('old_password')"></app-invalid-feedback>
            </div>
          <!-- end of Email Field -->
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-lg-4 col-md-4 m-auto">
              <!-- start of  Email field -->
            <div class="form-group craft-form-group">
              <input placeholder="New Password" formControlName="password" type="password" class="form-control" [ngClass]="{'is-invalid':changePassword.get('password').invalid && (changePassword.get('password').dirty || changePassword.get('password').touched) }" id="email-field">
              <app-invalid-feedback  class="invalid-feedback" name="Password" [control]="changePassword.get('password')"></app-invalid-feedback>
            </div>
          <!-- end of Email Field -->
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-lg-4 col-md-4 m-auto">
              <!-- start of  Email field -->
            <div class="form-group craft-form-group">
              <input placeholder="Confirm Password" formControlName="password_confirmation" type="password" class="form-control" [ngClass]="{'is-invalid':changePassword.get('password_confirmation').invalid && (changePassword.get('password_confirmation').dirty || changePassword.get('password_confirmation').touched) }" id="email-field">
              <app-invalid-feedback  class="invalid-feedback" name="Confirm password" [control]="changePassword.get('password_confirmation')"></app-invalid-feedback>
            </div>
          <!-- end of Email Field -->
            </div>
          </div>

          <div class="row mb-4">
            <button [appLoader]="formLoading" type="submit" class="btn btn-primary d-block m-auto" (click)="onSubmit()">Update</button>
          </div>

      </form>
  </div>
  </div>
  <ng-template #messageBlock>
    <div class="alert alert-success">
      {{ componentStatus.message }}
    </div>
  </ng-template>
</div>