<div class="card craft-card bg-light" *ngIf="application">
    <div class="card-body">
        <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12">
                <div class="d-flex justify-content-center">
                    <img [src]="application.development.featured_image_url" alt="{{ application.label}}" class="mb-4 img-fluid decorated-img align-self-center">
                </div>                
                <h4>
                    {{ application.label}}<br>
                    Status: {{ application.status.name  }}
                </h4>
                
                <p>{{ application.reserved_unit.description  }}</p>
            </div>
            
        </div>
    </div>
</div>