import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class UnitTypeService {

  httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    })
  };

  constructor(private http: HttpClient) { }

  getFinishes$(unitTypeId)
  {
    return this.http.get(`${environment.api.base_url}/unit-types/${unitTypeId}/finishes`,this.httpOptions)
  }

  saveFinish$(finishUnitTypeId, token) {
    return this.http.patch(`${environment.api.base_url}/applications/${token}/finish-save`, {
      finish_unit_type_id: finishUnitTypeId
    }, this.httpOptions)
  }

  getAdditions$(unitTypeId)
  {
    return this.http.get(`${environment.api.base_url}/unit-types/${unitTypeId}/addition-categories`,this.httpOptions)
  }

  purchaseAdditions$(purchaseArray, purchaseType, unitTypeId , token)
  {
    return this.http.post(`${environment.api.base_url}/applications/${token}/unit-type/${unitTypeId}/purchase`, {
      payment_code: purchaseType,
      purchasable: purchaseArray
    },this.httpOptions)
  }

  getPurchasedAdditions$(token)
  {
    return this.http.get(`${environment.api.base_url}/applications/${token}/purchasable`,this.httpOptions)
  }
}
