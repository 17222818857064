import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-declaration',
  templateUrl: './declaration.component.html',
  styleUrls: ['./declaration.component.scss']
})
export class DeclarationComponent implements OnInit {

  @ViewChild('signaturePad') signaturePad: SignaturePad;
  declaration:FormGroup;
  @Input() bondApplication: FormGroup;
  @Input() index: number;
  
  signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 5,
    'canvasWidth': 500,
    'canvasHeight': 300
  };

  constructor(
    private fb: FormBuilder,
    private _utility_service: UtilityService
  ) { }

  ngOnInit(): void {
    this.AddToForm();
  }

  AddToForm(): void{
    this.declaration = this.fb.group({
      signature: ['',[Validators.required]],
      marketing_products:['',[Validators.required]],
      positive_contact_me:['',[Validators.required]],
      sharing_personal_information:['',[Validators.required]],
      research_purposes:['',[Validators.required]],
      bank_enquire_credit:['',[Validators.required]],
      bank_share_information:['',[Validators.required]],
      bank_fraud_identity:['',[Validators.required]],
      admin_fees:['',Validators.required],
      married_community:['',Validators.required],
      information_reflection:['',Validators.required],
      third_party_providers:['',Validators.required],
      fraud_prevention_services:['',Validators.required],
      payslip_verification:['',Validators.required]

    });
  this.bondApplication.addControl('declaration',this.declaration);
}

  ngAfterViewInit() {
   this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
   this.declaration.get('signature').setValue('');
  }
 
  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
   let signataureValue = this.signaturePad.toDataURL();
   this.declaration.get('signature').setValue(signataureValue);
  }
 
  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
  }

  clearSignature(){
    if(this.signaturePad)
    {
      this.signaturePad.clear();
      this.declaration.get('signature').setValue('');
    }
  }

}
