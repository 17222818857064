import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private is_loading$:Subject<boolean>;
  constructor() {
    this.is_loading$ = new Subject<boolean>();
  }
  
  setStatus(loading:boolean):void
  {
    this.is_loading$.next(loading);
  }
  getStatus():Subject<boolean>
  {
    return this.is_loading$;
  }
}