<div id="sale-agreement-panel" *ngIf="bondApplicationValid; else invalidBondApplication">
    <div class="text-center" [innerHTML]="topSectionHtml">
    </div>
    
    <ol *ngIf="sale_agreement" [formGroup]="sale_agreement">
        <li class="pb-2" *ngFor="let key of termKeys; index as i">
            <div class="form-check">
                <input class="form-check-input" [formControlName]="key" type="checkbox" value="1" [id]="key">
                <label class="form-check-label" [for]="key">
                  {{ transformPlaceHolders(formData.development?.meta_pairs.sale_agreement_terms[i]) }}
                </label>
            </div>
            <app-invalid-feedback [ngClass]="{'d-block':sale_agreement.get(key).invalid && (sale_agreement.get(key).dirty || sale_agreement.get(key).touched) }" class="invalid-feedback" name="The following terms" [messages]="{validTerms:'please accept the terms and conditions by clicking on the tick box',required:requiredMessage}" [control]="sale_agreement.get(key)"></app-invalid-feedback>
        </li>
    </ol>
    
    <div class="row">
        <div class="col" *ngFor="let control of applicants.controls; let i=index">
            
            <div *ngIf="applicants.at(i).get('sale_agreement.purchaser_signature')" >
                <div class="row">
                    <div class="col">
                        <p>
                            {{ transformPlaceHolders(formData.development?.meta_pairs.buyer_agreement) }}
                        </p>
                        <signature-pad #signaturePad  [options]="signaturePadOptions" (onBeginEvent)="drawStart(i)"
                        (onEndEvent)="drawComplete(i)"></signature-pad>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-invalid-feedback [ngClass]="{'d-block':applicants.at(i).get('sale_agreement.purchaser_signature').invalid && (applicants.at(i).get('sale_agreement.purchaser_signature').dirty || applicants.at(i).get('sale_agreement.purchaser_signature').touched) }" class="invalid-feedback" name="The following terms" [messages]="{validTerms:requiredMessage,required:requiredMessage}" [control]="applicants.at(i).get('sale_agreement.purchaser_signature')"></app-invalid-feedback>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        {{ fullName(i) }} 
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button type="button" class="btn btn-primary" (click)="clearSignature(i)">Clear</button>
                    </div>
                </div>
                    
            </div>
        </div>
    </div>
</div>
<ng-template #invalidBondApplication>
    <div class="alert alert-info mt-2">
        Please complete your bond application document before you complete this short sales agreement form.
        The sales agreement will use information from your bond application to populate necessary fields.
    </div>
</ng-template>


