<div class="container" [appLoader]="loading" >
    <ng-container *ngIf="application.status.code != 'awt_cmpl'">
        <div class="card craft-card bg-light mt-5">
            <div class="card-body">
                <div class="row">
                    <fa-icon [icon]="checkCircle" class="fa-5x fa-icon d-block m-auto"></fa-icon>
                </div>
                <h3 class="text-center mb-4">Thank you</h3>
                <h5 class="text-center mb-4">Your application has been submitted ({{application.updated_at | date }})</h5>
                <h5 class="text-center mb-4">We will confirm with you that we have received all the information we require to submit to the bank(s) you have selected for finance, download a copy of your bond application documents below. Use your ID number as the password.</h5>

                <h5 class="text-center mb-4">If you have any queries, please contact <a href="tel:0860027238">086 002 7238</a> or <a href="mailto:info@crafthomes.co.za">info@crafthomes.co.za</a></h5>
                <div class="row mb-4 d-flex justify-content-center" >
                    <a class="btn btn-primary mr-3" [routerLink]="'/'">
                        Return to Dashboard
                    </a>
                    <app-application-download [application]="application" ></app-application-download>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container  *ngIf="application.status.code == 'awt_cmpl' && application.development.bond_application_form_url && application.development.sales_agreement_form_url">
        
        <ng-container *ngIf="!loading">
           <form class="" [formGroup]="manualForm" *ngIf="application.status.code == 'awt_cmpl'" (submit)="submitApplication()">

            <div class="manual-form-header" *ngIf="!loading">
                <div class="manual-form-heading py-4">
                    Instructions
                 </div>

                 <div class="form-group manual-form-underline pb-3">
                    <label class="font-weight-bold" for="unit_unit_type_id-field">Type of unit</label>
                    <p class="select-unit-type bounce" *ngIf="manualForm.get('unit_unit_type_id').invalid"><fa-icon [icon]="faHandPointDown"></fa-icon><b> Please select your Unit here</b> before you proceed with the application</p>
                    <select formControlName="unit_unit_type_id" class="form-control" [ngClass]="{'is-invalid':manualForm.get('unit_unit_type_id').invalid && (manualForm.get('unit_unit_type_id').dirty || manualForm.get('unit_unit_type_id').touched) }" id="unit_unit_type_id-field">
                        <option class="d-none" [ngValue]="''">
                            Select type of unit
                        </option>
                        <option *ngFor="let unitType of unitTypes"  value="{{ unitType.pivot.id }}">
                            {{ unitType.name }} - {{ unitType.pivot.price  | currency:'R'}}
                        </option>
                    </select>
                    
                    <app-invalid-feedback  class="invalid-feedback" name="Application type" [control]="manualForm.get('unit_unit_type_id')"></app-invalid-feedback>
                </div>
                
                <div class="manual-form-subheading my-4" *ngIf="application.status.code == 'awt_cmpl'">
                    Please download the PDF bond application form by clicking on the red download button below. For all joint applications, all parties need to complete their own PDF bond application. Ensure you complete the application in full and that you save them to your PC. You can then upload all the relative documents to the section below which will complete your application.
                </div>
                <div class="bond-application-button mb-3">
                    <a class="btn btn-primary" target="_blank" href="{{application.development.bond_application_form_url}}" download>Download Bond Application</a>
                </div>
                <div class="manual-form-subheading my-4">
                    Please download the PDF short form sale agreement by clicking on the red download button below. For all joint applications, all parties need to complete ONE short form sale agreement by filling in all names, ID numbers, email addresses and all purchasers must sign under the purchaser signature field. Ensure you complete the agreement in full and that you save them to your PC. You can then upload all the relative documents to the section below which will complete your application.
                </div>
                <div class="manual-form-subheading my-4" *ngIf="application.status.code == 'awt_cmpl'">
                    If you need help filling in the sales agreement <a href="#" (click)="open($event, content)">click here</a>
                </div>
                <div class="bond-application-button mb-3">
                    <a class="btn btn-primary" target="_blank" href="{{application.development.sales_agreement_form_url}}" download>Download Sales Agreement</a>
                </div>
                <div class="manual-form-subheading my-4" *ngIf="!application.development.is_manual">
                    Alternatively, if you would like to try out the online process <a href="#" (click)="toggleManualApplication($event)">click here</a>
                </div>
            </div>

            <div class="manual-form-heading py-4">
                Bond Application Files
            </div>

            <div class="form-background">
            <div class="form-group">
                <label class="font-weight-bold">Upload your completed application(s) here</label>
                <ngx-dropzone (change)="onSelect($event, applicationFiles, 'applicationFilesDirty')"  [ngClass]="{'border-danger': !applicationFilesValid && applicationFilesDirty}">
                    <ngx-dropzone-label>Drag and Drop or Click here to upload your Application</ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of applicationFiles" [removable]="true" (removed)="onRemove(f, applicationFiles)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-preview>
                </ngx-dropzone>
                <small *ngIf="!applicationFilesValid && applicationFilesDirty" class="text-danger">You need to upload your application(s) here</small>
            </div>
            <div class="form-group">
                <label class="font-weight-bold">Upload your completed sales agreement(s) here</label>
                <ngx-dropzone (change)="onSelect($event, salesAgreementFiles, 'salesAgreementFilesDirty')"  [ngClass]="{'border-danger': !salesAgreementFilesValid && salesAgreementFilesDirty}">
                    <ngx-dropzone-label>Drag and Drop or Click here to upload your Sales Agreement</ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of salesAgreementFiles" [removable]="true" (removed)="onRemove(f, salesAgreementFiles)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-preview>
                </ngx-dropzone>
                <small *ngIf="!salesAgreementFilesValid && salesAgreementFilesDirty" class="text-danger">You need to upload your sales agreement(s) here</small>
            </div>
            <div class='form-group'>
                <label class="font-weight-bold">Clear Copy of SA ID document / New ID card - back & front</label>
                <ngx-dropzone (change)="onSelect($event, idDocFiles, 'idDocFilesDirty')"  [ngClass]="{'border-danger': !idDocFilesValid && idDocFilesDirty }">
                    <ngx-dropzone-label>Drag and Drop or Click here to upload your Id Documents</ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of idDocFiles" [removable]="true" (removed)="onRemove(f, idDocFiles)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-preview>

                </ngx-dropzone>
                <small *ngIf="!idDocFilesValid && idDocFilesDirty" class="text-danger">A ID document / New ID card is required</small>
               </div>
                <div class="form-group">
                    <label class="font-weight-bold">Proof of Residence / Utility Bill (not older than 3 months)</label>
                    <ngx-dropzone (change)="onSelect($event, proofOfResFiles, 'proofOfResFilesDirty')"  [ngClass]="{'border-danger': !proofOfResFilesValid && proofOfResFilesDirty}">
                        <ngx-dropzone-label>Drag and Drop or Click here to upload your Proof of Residence Documents</ngx-dropzone-label>
                        <ngx-dropzone-preview *ngFor="let f of proofOfResFiles" [removable]="true" (removed)="onRemove(f, proofOfResFiles)">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                        </ngx-dropzone-preview>
                    </ngx-dropzone>
                    <small *ngIf="!proofOfResFilesValid && proofOfResFilesDirty" class="text-danger">Proof of Residence / Utility Bill is required</small>
                </div>
                <div class="form-group">
                    <label class="font-weight-bold">Three Months Salary Slips</label>
                    <ngx-dropzone (change)="onSelect($event, salarySlipFiles, 'salarySlipFilesDirty')"  [ngClass]="{'border-danger': !salarySlipFilesValid && salarySlipFilesDirty}">
                        <ngx-dropzone-label>Drag and Drop or Click here to upload your Salary Slips</ngx-dropzone-label>
                        <ngx-dropzone-preview *ngFor="let f of salarySlipFiles" [removable]="true" (removed)="onRemove(f, salarySlipFiles)">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                        </ngx-dropzone-preview>
                    </ngx-dropzone>
                    <small *ngIf="!salarySlipFilesValid && salarySlipFilesDirty" class="text-danger">Your salary slips are required</small>
                </div>
                <div class="form-group">
                    <label class="font-weight-bold">Three Months Bank Statements</label>
                    <ngx-dropzone (change)="onSelect($event, bankStatementFiles, 'bankStatementFilesDirty')"  [ngClass]="{'border-danger': !bankStatementFilesValid && bankStatementFilesDirty}">
                        <ngx-dropzone-label>Drag and Drop or Click here to upload your Bank Statements</ngx-dropzone-label>
                        <ngx-dropzone-preview *ngFor="let f of bankStatementFiles" [removable]="true" (removed)="onRemove(f, bankStatementFiles)">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                        </ngx-dropzone-preview>
                    </ngx-dropzone>
                    <small *ngIf="!bankStatementFilesValid && bankStatementFilesDirty" class="text-danger">Your bank statements required</small>
                </div>
                
                <div class="form-group clearfix">
                    <input type="submit" class="btn btn-primary float-right" value="Submit">
                </div>
            </div>
           </form>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="!application.development.bond_application_form_url || !application.development.sales_agreement_form_url">
        <div class="card craft-card bg-light mt-5">
            <div class="card-body">
                <h3 class="text-center mb-4">Looks like no application has been assigned to this development yet</h3>
                <h5 class="text-center mb-4">Please contact a craft administrator for further assistance</h5>
                <div class="row mb-4" >
                    <a class="btn btn-primary d-block m-auto" [routerLink]="'/'">
                        Return to Dashboard
                    </a>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Sales Agreement Details</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
       <div class="info-header">
         Please use the following information to complete the Agreement of Sales form:
       </div>
       <div class="manual-form-heading my-4">
           Top Section
       </div>
      <div class="info-point my-3">
        <span class="info-label">(‘’Seller’’) and:</span> {{application.user.full_name}}, {{application.user.id_number}}, {{application.user.email}}
      </div>
      <div class="info-point my-3">
        (For a joint application, you’ll need to add the second persons details here too)
      </div>
      <div class="manual-form-heading my-4">
        Point 1:
      </div>
      <div class="info-point my-3">
        <span class="info-label">Unit Number:</span> {{selectedUnitType ? selectedUnitType.number : 'Select a Unit Type for this field to show'}}
      </div>
      <div class="info-point my-3" *ngIf="selectedUnitType">
        <span class="info-label">Sum Of: </span>{{selectedUnitType.price | currency:'R'}}, {{selectedUnitType.price_words}} Rand  
      </div>
      <div class="info-point my-3" *ngIf="!selectedUnitType">
        <span class="info-label">Sum Of: </span> Select a Unit Type for this field to show 
      </div>
      <div class="manual-form-heading my-4">
        Point 2:
      </div>
      <div class="info-point my-3">
        <span class="info-label">By (date):</span> {{application.complete_by_date}}
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Button click')">Close</button>
    </div>
  </ng-template>