
<div class="container">
  <div class="card craft-card bg-light mt-5">
    <div class="card-body"> 
      <ng-template [ngIf]="impersonate" [ngIfElse]="elseBlock">
        <h3 class="text-center mb-4" >Impersonate</h3>
      </ng-template>
      <ng-template #elseBlock>
        <h3 class="text-center mb-4" >Login</h3>
      </ng-template>
      <form [formGroup]="loginDetails">
        <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 m-auto">
            <!-- start of  Email field -->
            <div class="form-group craft-form-group mb-4">
              <input formControlName="email" placeholder="Email Address" type="email" class="form-control" [ngClass]="{'is-invalid':loginDetails.get('email').invalid && (loginDetails.get('email').dirty || loginDetails.get('email').touched) }" id="email-field">
              <app-invalid-feedback  class="invalid-feedback" name="Email" [control]="loginDetails.get('email')"></app-invalid-feedback>
            </div>
            <!-- end of Email Field -->
          </div>
        </div>

        <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 m-auto">
            <!-- start of  Password field -->
            <div class="form-group craft-form-group">
              <input formControlName="password" placeholder="Password" type="password" class="form-control" [ngClass]="{'is-invalid':loginDetails.get('password').invalid && (loginDetails.get('password').dirty || loginDetails.get('password').touched) }" id="password-field">
              <app-invalid-feedback  class="invalid-feedback" name="Password" [control]="loginDetails.get('password')"></app-invalid-feedback>
            </div>
            <!-- end of password Field -->
          </div>
        </div>

        <ul>
          <li *ngFor="let error of errors" class="text-danger">
            {{error}}
          </li>
        </ul>

        <div class="row">
          
            <ng-template [ngIf]="!impersonate" [ngIfElse]="button">
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 m-auto">
              <div class="form-check mb-4">
                <input class="form-check-input"  id="rememberMe" type="checkbox" formControlName="rememberMe" [ngClass]="{'is-invalid':loginDetails.get('rememberMe').invalid && (loginDetails.get('rememberMe').dirty || loginDetails.get('rememberMe').touched) }" />
                <label class="form-check-label" for="rememberMe">
                  Remember me
                </label>
              </div>
              <button class="btn btn-primary mr-3 mb-4" [appLoader]="formLoading" type="submit" (click)="onLogin()">Login</button>
              <a [routerLink]="'/forgot-password'">
                <button class="btn btn-secondary mb-4">Forgot Your password?</button>
              </a>
            </div>
            </ng-template>     
            <ng-template #button>
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 m-auto text-center">
              <button class="btn btn-primary" [appLoader]="formLoading" type="submit" (click)="onLogin()">Login</button>
            </div>
            </ng-template>       
          </div>
        

      </form>
  </div>
  </div>
</div>
