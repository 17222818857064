import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements as defineImgComponentSlider } from 'img-comparison-slider/loader';

import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

if (environment.production) {
  Sentry.init({
    dsn: "https://eaa709a3bcdd4596aee885a8856a071e@sentry.assemble.co.za/23",
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [/*"localhost", "https://yourserver.io/api"*/],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 0.3,
  });
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
defineImgComponentSlider(window);