import { Component, OnInit, Input, SimpleChanges, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, FormArray, Form } from '@angular/forms';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { validateTerms } from 'src/app/validators/terms.validator'
import { ApplicationStorageService } from 'src/app/application/services/application-storage.service';
import { ProgressReportService } from 'src/app/application/services/progress-report.service';


@Component({
  selector: 'app-sale-agreement',
  templateUrl: './sale-agreement.component.html',
  styleUrls: ['./sale-agreement.component.scss']
})

export class SaleAgreementComponent implements OnInit {
@Input() formData;
@Input() bondApplication;
@ViewChildren("signaturePad") signaturePads: QueryList<SignaturePad>;
sale_agreement: FormGroup;
requiredMessage = "The following terms must be accepted";
topSectionHtml = '';

months=[
  "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
];


signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
  'minWidth': 5,
  'canvasWidth': 500,
  'canvasHeight': 300
};
  transformBondApplicationSubscription: any;

  constructor(
    private fb: FormBuilder,
    private _bond_application_storage: ApplicationStorageService,
    private _utility_service: UtilityService,
    private _progressReport: ProgressReportService,
  ) {
   }

  ngOnInit(): void {
    this._bond_application_storage.getApplicantsCount$().subscribe((length)=>{
     
      this.clearSignatures()

    });
  }

  clearSignatures()
  {
    
    for (let i = 0; i < this.applicants.length; i++) 
      {
        if(this.signaturePads.toArray()[i])
        {
          this.signaturePads.toArray()[i].clear();
        }
        //remove the control and add it againg
        (this.applicants.at(i) as FormGroup).removeControl("sale_agreement");
        (this.applicants.at(i) as FormGroup).addControl("sale_agreement",
          this.fb.group({
            'purchaser_signature':this.fb.control('',[
              Validators.required
            ])
          })
        ); 
      
      }
  }
 
  
  ngOnChanges(changes: SimpleChanges) {
    if(changes.formData)
    {
      
      this.setTopSectionHtml();

      if(changes.formData.currentValue["development"])
      {
        this.setBondForm();
        this.setAgreementFields(changes.formData.currentValue["development"]["agreement"]);
      }
    }
  }

  setAgreementFields(agreement)
  {
    let agreementFields = this.termKeys;
    agreementFields.forEach(field => {
      //fields will be named in the following was to ensure that the fiels are just not indexes term-- etc
      this.sale_agreement.addControl(field,this.fb.control(null,validateTerms))
    });
  }
  
  setBondForm()
  {
    this.sale_agreement = this.fb.group({});
    
    
    if(this.bondApplication)
    {
      this.bondApplication.removeControl('sale_agreement');
      this.bondApplication.addControl('sale_agreement',this.sale_agreement);
    }
  }


 setTopSectionHtml()
 {
    if(this.transformBondApplicationSubscription instanceof Subscription)
    {
      this.transformBondApplicationSubscription.unsubscribe();
    }

    this.transformBondApplicationSubscription = this.transformBondApplication().subscribe((content)=> {

      let topSectionText = this.transformPlaceHolders(content);
      this.topSectionHtml = topSectionText;
    });
 }

 get termKeys()
  {
    if(this.formData.development)
    {
      let termLength = this.formData.development.meta_pairs.sale_agreement_terms.length;
      let terms = [];
      for (let i = 0; i < termLength; i++) {
        terms.push(`term_${i}`);
      }


      return terms;
    }
    return [];
  }

  transformPlaceHolders(text)
  {
    if(text)
    {
      const date = new Date();

      let replaceList = {
        unit_number: this.formData.unit?.number,
        sum_price: this.defaultPrice,
        day: date.getDate(),
        month: this.months[date.getMonth()],
        year: date.getFullYear()
      };
  
      text = this._utility_service.templateReplace(replaceList,text)
      return text;
    }
  
    return null;
  }
  
  get applicants()
  {
    return (this.bondApplication.get("applicants") as FormArray);
  }


  transformBondApplication()
  {
    return this.applicants.valueChanges.pipe(map((applicants)=>{
      let text = null; 

      if(this.formData && this.formData.development)
      {
         text = this.formData.development.meta_pairs.sale_agreement;

      }
      if(text)
      {
        let finalText = [];
        applicants.forEach(applicant => {
          
          let first_name = applicant.personal_details?.first_name ? applicant.personal_details?.first_name  : "";
          let last_name =  applicant.personal_details?.last_name ? applicant.personal_details?.last_name : "";
          let initials = `(${first_name.charAt(0)}.${last_name.charAt(0)})`;

          let id_number = applicant.personal_details?.id_number ?  applicant.personal_details?.id_number : "";
          let passport_number = applicant.personal_details?.passport_number ? applicant.personal_details?.passport_number : "" ;
          let email_address  = applicant.contact_details?.email_address ? applicant.contact_details?.email_address : "";

          finalText.push(`<div>
              <p><strong> ${first_name} ${initials} ${last_name}  ${id_number} ${passport_number} </strong></p>
              <p> <strong>${email_address}</strong></p>
            </div>`);
        });
        text = text.replace(`{{applicant_details}}`,finalText.join("<div>&</div>"));
        return text;
      }
      return null;

    }));
    
  }


  get unitFormControl()
  {
   if(this._bond_application_storage.getBondApplicationForm)
   {
      return (this._bond_application_storage.getBondApplicationForm().get('application_type.unit_type_id') as FormControl);
   }
   return null
  }

  numberWithSpaces(numToFormat) {
    var parts = numToFormat.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
  }

  get defaultPrice()
  {
    let selected_unit_type = null;
    let unit_type_id = null;
    
    if(this.unitFormControl)
    {
      unit_type_id = this.unitFormControl.value;
    }
    if(this.formData && this.formData["unit"] && this.formData["unit"]["unit_types"])
    {
      selected_unit_type = this.formData["unit"]["unit_types"].find((unit_type)=>{
        return unit_type_id == unit_type.id;
      });
    }
   
    if(selected_unit_type)
    {
      return this.numberWithSpaces(selected_unit_type.pivot.price);
    }
    
    return 0;
  }

  fullName(i)
  {
    let first_name = this.applicants.at(i).get('personal_details.first_name') ? this.applicants.at(i).get('personal_details.first_name').value : null;
    let last_name = this.applicants.at(i).get('personal_details.first_name') ? this.applicants.at(i).get('personal_details.last_name').value : null;
    return `${first_name} ${last_name}`;
  }

  drawComplete(i) {
    // will be notified of szimek/signature_pad's onEnd event
    if(this.signaturePads.toArray()[i])
    {
      let signataureValue = this.signaturePads.toArray()[i].toDataURL();
      this.applicants.at(i).get('sale_agreement.purchaser_signature').setValue(signataureValue);
    }

  }
 
  drawStart(index) {
    // will be notified of szimek/signature_pad's onBegin event
  }

  clearSignature(i){
    if(this.signaturePads.toArray()[i])
    {
      this.signaturePads.toArray()[i].clear();
      this.applicants.at(i).get('sale_agreement.purchaser_signature').setValue("");
    }
  }

  get bondApplicationValid()
  {
    const totalApplicants = this.applicants.length;
    let contact_details = 0;
    let personal_details = 0;
    let application_type = (this.bondApplication as FormGroup).get("application_type").valid;
    for (let index = 0; index < totalApplicants; index++) 
    {
      contact_details += this._progressReport.progress((this.applicants.at(index) as FormGroup).get('contact_details')) / totalApplicants;
      personal_details += this._progressReport.progress((this.applicants.at(index) as FormGroup).get('personal_details')) / totalApplicants;
    }

    return application_type && contact_details == 100 && personal_details == 100;
  }

}
