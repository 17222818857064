<div class="table-responsive">

<table [formGroup]="fixed_debt_repayment" class="table table-striped">
   <thead>
      <tr>
         <th scope="col-md">Fixed Debt Repayment</th>
         <th scope="col-md"></th>
      </tr>
   </thead>
    <tbody>
      <tr>
         <th scope="row">Total Monthly Home Repayments</th>
         <td>
            <div class="form-group">
               <input currencyMask formControlName="total_monthly_home_repayments" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':fixed_debt_repayment.get('total_monthly_home_repayments').invalid && (fixed_debt_repayment.get('total_monthly_home_repayments').dirty || fixed_debt_repayment.get('total_monthly_home_repayments').touched) }" id="total_monthly_home_repayments-field">
               <app-invalid-feedback  class="invalid-feedback" name="Amount of rent/Home loan repayment that will stop once load is activated" [control]="fixed_debt_repayment.get('total_monthly_home_repayments')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
            </div>
         </td>
       </tr>
      <tr>
         <th scope="row">Amount of rent/Home loan repayment that will stop once loan is activated</th>
         <td>
            <div class="form-group">
               <input currencyMask formControlName="amount_loan_repayment_will_stop_after_ativation" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':fixed_debt_repayment.get('amount_loan_repayment_will_stop_after_ativation').invalid && (fixed_debt_repayment.get('amount_loan_repayment_will_stop_after_ativation').dirty || fixed_debt_repayment.get('amount_loan_repayment_will_stop_after_ativation').touched) }" id="amount_loan_repayment_will_stop_after_ativation-field">
               <app-invalid-feedback  class="invalid-feedback" name="Amount of rent/Home loan repayment that will stop once loan is activated" [control]="fixed_debt_repayment.get('amount_loan_repayment_will_stop_after_ativation')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
            </div>
         </td>
       </tr>
       
       <tr>
         <th scope="row">Asset Finance(Vehicle Repayments)</th>
         <td>
            <div class="form-group">
               <input currencyMask formControlName="asset_finance_vehicle_repayments" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':fixed_debt_repayment.get('asset_finance_vehicle_repayments').invalid && (fixed_debt_repayment.get('asset_finance_vehicle_repayments').dirty || fixed_debt_repayment.get('asset_finance_vehicle_repayments').touched) }" id="asset_finance_vehicle_repayments-field">
               <app-invalid-feedback  class="invalid-feedback" name="Asset Finance(Vehicle repayment)" [control]="fixed_debt_repayment.get('asset_finance_vehicle_repayments')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
            </div>
         </td>
       </tr>
       
       <tr>
         <th scope="row">Loan/Overdraft Repayments</th>
         <td>
            <div class="form-group">
               <input currencyMask formControlName="loan_overdraft_repayment" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':fixed_debt_repayment.get('loan_overdraft_repayment').invalid && (fixed_debt_repayment.get('loan_overdraft_repayment').dirty || fixed_debt_repayment.get('loan_overdraft_repayment').touched) }" id="loan_overdraft_repayment-field">
               <app-invalid-feedback  class="invalid-feedback" name="Loan/Overdraft Repayments" [control]="fixed_debt_repayment.get('loan_overdraft_repayment')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
            </div>
         </td>
       </tr>
       
       <tr>
         <th scope="row">Credit Card Repayments</th>
         <td>
            <div class="form-group">
               <input currencyMask formControlName="credit_card_repayment" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':fixed_debt_repayment.get('credit_card_repayment').invalid && (fixed_debt_repayment.get('credit_card_repayment').dirty || fixed_debt_repayment.get('credit_card_repayment').touched) }" id="credit_card_repayment-field">
               <app-invalid-feedback  class="invalid-feedback" name="Credit Card Repayments" [control]="fixed_debt_repayment.get('credit_card_repayment')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
            </div>
         </td>
       </tr>
       
       <tr>
         <th scope="row">Retail Account Repayment</th>
         <td>
            <div class="form-group">
               <input currencyMask formControlName="retail_account_repayment" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':fixed_debt_repayment.get('retail_account_repayment').invalid && (fixed_debt_repayment.get('retail_account_repayment').dirty || fixed_debt_repayment.get('retail_account_repayment').touched) }" id="retail_account_repayment-field">
               <app-invalid-feedback  class="invalid-feedback" name="Retail account Repayment" [control]="fixed_debt_repayment.get('retail_account_repayment')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
            </div>
         </td>
       </tr>
       
       <tr>
         <th scope="row">Other Fixed Debt Repayments(Please specify)</th>
         <td>
            <div class="form-group">
               <input currencyMask formControlName="other_fixed_debt_repayments" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':fixed_debt_repayment.get('other_fixed_debt_repayments').invalid && (fixed_debt_repayment.get('other_fixed_debt_repayments').dirty || fixed_debt_repayment.get('other_fixed_debt_repayments').touched) }" id="other_fixed_debt_repayments-field">
               <app-invalid-feedback  class="invalid-feedback" name="Other fixed debt repayments(Please specify)" [control]="fixed_debt_repayment.get('other_fixed_debt_repayments')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
            </div>
            <div class="form-group">
               <input formControlName="other_specification" type="input" class="form-control" [ngClass]="{'is-invalid':fixed_debt_repayment.get('other_specification').invalid && (fixed_debt_repayment.get('other_specification').dirty || fixed_debt_repayment.get('other_specification').touched) }" id="other_specification-field" placeholder="Please Specify Other">
               <app-invalid-feedback  class="invalid-feedback" name="Please Specify" [control]="fixed_debt_repayment.get('other_specification')"></app-invalid-feedback>
            </div>
         </td>
       </tr>
       <tr>
         <th scope="row">Total of all fixed debt repayment</th>
         <td>
            <div class="form-group">
               <span class="text-right">
                  {{ fixed_debt_repayments_total | currency:'R'}}
               </span>
            </div>
         </td>
      </tr>  
    </tbody>
</table>
</div>