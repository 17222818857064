import { Component, OnInit, Input, SimpleChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faChevronDown,faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { Event } from 'img-comparison-slider/dist/types/stencil-public-runtime';
@Component({
  selector: 'app-bond-application-form',
  templateUrl: './bond-application-form.component.html',
  styleUrls: ['./bond-application-form.component.scss']
})
export class BondApplicationFormComponent implements OnInit {

  @Input() bondApplication: FormGroup;
  @Input() index:number;
  @Input() errorSection:any;
  @Input() formData: any;
  @Input() shouldEvaluate: boolean;
  @Output() partialSave = new EventEmitter();
  @ViewChild('acc') accordionComponent: NgbAccordion;

  iconDown = faChevronDown;
  iconRight = faChevronRight;
  constructor(
  ) { 
  }
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.errorSection)
    {
      this.openErrorPanels(changes.errorSection.currentValue);
    }
  }

  onPartialSave() {
    this.partialSave.emit();
  }

  openErrorPanels(errorSection)
  {
      if( this.accordionComponent && errorSection)
      {
        let sections = Object.keys(errorSection);
        sections.forEach((section)=>{
          if(errorSection[section])
          {
            this.accordionComponent.expand(this.index+"_"+section);
          }
  
        });
      }
    
  }
 
  getSectionControlStatus(section_name:string): string {
    if(
      this.shouldEvaluate
      && this.bondApplication 
      && this.bondApplication.get(section_name) 
      && this.bondApplication.get(section_name).invalid
    ) {
      return 'invalid-section'
    }

    return '';
    return `{'invalid-section':${this.bondApplication && this.bondApplication.get(section_name) ? this.bondApplication.get(section_name).invalid: false} }`;
  }
}
