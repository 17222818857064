<div *ngIf="message" class="alert alert-info">
    {{ message }}
</div>
<fieldset [formGroup]="juristic_application">

        <div class="row">
            <div class="col-md override-p-m" *ngIf="juristic_application.get('business_name')">
                <!-- start of  Business Name field -->
                <div class="form-group">
                    <label for="business_name-field">Business Name</label>
                    <input formControlName="business_name" type="input" class="form-control" [ngClass]="{'is-invalid':juristic_application.get('business_name').invalid && (juristic_application.get('business_name').dirty || juristic_application.get('business_name').touched) }" id="business_name-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Business Name" [control]="juristic_application.get('business_name')"></app-invalid-feedback>
                </div>
                <!-- end of Business Name Field -->
            </div>
            <div class="col-md override-p-m" *ngIf="juristic_application.get('registration_number')">
                <!-- start of  Registration Number field -->
                <div class="form-group">
                    <label for="registration_number-field">Registration Number</label>
                    <input formControlName="registration_number" type="input" class="form-control" [ngClass]="{'is-invalid':juristic_application.get('registration_number').invalid && (juristic_application.get('registration_number').dirty || juristic_application.get('registration_number').touched) }" id="registration_number-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Registration Number" [control]="juristic_application.get('registration_number')"></app-invalid-feedback>
                </div>
                <!-- end of Registration Number Field -->
            </div>
        </div> 
        <div class="row">
            <div class="col-md override-p-m" *ngIf="juristic_application.get('trading')">
                <!-- start of  Trading As field -->
                <div class="form-group">
                    <label for="trading-field">Trading As</label>
                    <input formControlName="trading" type="input" class="form-control" [ngClass]="{'is-invalid':juristic_application.get('trading').invalid && (juristic_application.get('trading').dirty || juristic_application.get('trading').touched) }" id="trading-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Trading As" [control]="juristic_application.get('trading')"></app-invalid-feedback>
                </div>
                <!-- end of Trading As Field -->
            </div>
            <div class="col-md override-p-m"  *ngIf="juristic_application.get('foundation_date')">
                <!-- start of Foundation Date field -->
                <div class="form-group">
                    <label for="foundation_date-field">Foundation Date</label>
                    <input formControlName="foundation_date" type="input" class="form-control" [ngClass]="{'is-invalid':juristic_application.get('foundation_date').invalid && (juristic_application.get('foundation_date').dirty || juristic_application.get('foundation_date').touched) }" id="foundation_date-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Foundation Date" [control]="juristic_application.get('foundation_date')"></app-invalid-feedback>
                </div>
                <!-- end of Foundation Date Field -->
            </div>
        </div> 
        <div class="row">
            <div class="col-md override-p-m" *ngIf="juristic_application.get('country_of_incorporation')">
                <!-- start of  Country Of Incorporation field -->
                <div class="form-group">
                    <label for="country_of_incorporation-field">Country Of Incorporation</label>
                    <input formControlName="country_of_incorporation" type="input" class="form-control" [ngClass]="{'is-invalid':juristic_application.get('country_of_incorporation').invalid && (juristic_application.get('country_of_incorporation').dirty || juristic_application.get('country_of_incorporation').touched) }" id="country_of_incorporation-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Country Of Incorporation" [control]="juristic_application.get('country_of_incorporation')"></app-invalid-feedback>
                </div>
                <!-- end of Country Of Incorporation Field -->
            </div>
            <div class="col-md override-p-m"  *ngIf="juristic_application.get('business_industry')">
                <!-- start of  Business Industry field -->
                <div class="form-group">
                    <label for="business_industry-field">Business Industry</label>
                    <input formControlName="business_industry" type="input" class="form-control" [ngClass]="{'is-invalid':juristic_application.get('business_industry').invalid && (juristic_application.get('business_industry').dirty || juristic_application.get('business_industry').touched) }" id="business_industry-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Business Industry" [control]="juristic_application.get('business_industry')"></app-invalid-feedback>
                </div>
                <!-- end of Business Industry Field -->
            </div>
        </div>
        <div class="row">
            <div class="col-md override-p-m" *ngIf="juristic_application.get('gross_monthly_turnover')">
                <!-- start of  Gross Monthly Turnover field -->
                <div class="form-group">
                    <label for="gross_monthly_turnover-field">Gross Monthly Turnover</label>
                    <input formControlName="gross_monthly_turnover" type="input" class="form-control" [ngClass]="{'is-invalid':juristic_application.get('gross_monthly_turnover').invalid && (juristic_application.get('gross_monthly_turnover').dirty || juristic_application.get('gross_monthly_turnover').touched) }" id="gross_monthly_turnover-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Gross Monthly Turnover" [control]="juristic_application.get('gross_monthly_turnover')"></app-invalid-feedback>
                </div>
                <!-- end of Gross Monthly Turnover Field -->
            </div>

            <div class="col-md override-p-m" *ngIf="juristic_application.get('registered_business_address')">
                <!-- start of  Registered Business Address field -->
                <div class="form-group">
                    <label for="registered_business_address-field">Registered Business Address</label>
                    <input formControlName="registered_business_address" type="input" class="form-control" [ngClass]="{'is-invalid':juristic_application.get('registered_business_address').invalid && (juristic_application.get('registered_business_address').dirty || juristic_application.get('registered_business_address').touched) }" id="registered_business_address-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Registered Business Address" [control]="juristic_application.get('registered_business_address')"></app-invalid-feedback>
                </div>
                <!-- end of Registered Business Address Field -->
            </div>
        </div>
        <div class="row">
            <div class="col-md override-p-m" *ngIf="juristic_application.get('legal_form')">
                <!-- start of  Legal form field -->
                <div class="form-group">
                    <label for="legal_form-field">Legal form</label>                   
                    <select formControlName="legal_form"  class="form-control" [ngClass]="{'is-invalid':juristic_application.get('legal_form').invalid && (juristic_application.get('legal_form').dirty || juristic_application.get('legal_form').touched) }" id="legal_form-field">
                        <option class="d-none" [ngValue]="''">
                            Select preferred communication channel
                        </option>
                        <option *ngFor="let option of options['legal_form']"  [ngValue]="option.value">
                            {{option.name }}
                        </option>
                    </select>

                    
                    <app-invalid-feedback  class="invalid-feedback" name="Legal form" [control]="juristic_application.get('legal_form')"></app-invalid-feedback>
                </div>
                <!-- end of Legal form Field -->
            </div>
        </div>  
</fieldset>