import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastService } from '../../services/toast.service';


@Component({
  selector: 'app-toasts-message',
  templateUrl: './toasts-message.component.html',
  styleUrls: ['./toasts-message.component.scss'],
  host: {'[class.ngb-toasts]': 'true'}
})
export class ToastsMessageComponent implements OnInit {
  constructor(public toastService: ToastService) {}
	
  ngOnInit(): void {}

isTemplate(toast) {
  return toast.textOrTpl instanceof TemplateRef;
}


}
