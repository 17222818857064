import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ApplicationStorageService } from 'src/app/application/services/application-storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-application-dashboard',
  templateUrl: './application-dashboard.component.html',
  styleUrls: ['./application-dashboard.component.scss']
})
export class ApplicationDashboardComponent implements OnInit, OnDestroy {

  token:string;
  application:any;
  applicationLoading: boolean;

  /**
   * List of application status and fragment assosiations
   * @var {Object}
   */
  fragment_map = {
    awt_cmpl:"bond_application",
    awt_fica: "cash_application",
  };

  /**
   * link fragments
   * @var {Object}
   */
  links = {
    unit_details :{ title: 'Unit Details', fragment: 'unit_details' },
    bond_application: { title: 'Bond Application', fragment: 'bond_application' },
    cash_application: { title: 'Cash Application', fragment: 'cash_application' },
    deposit: { title: 'Deposit', fragment: 'deposit' },
    finishes: { title: 'Finishes', fragment: 'finishes' },
    extras: { title: 'Extras', fragment: 'extras' },
    documents: { title: 'Documents', fragment: 'documents' }
  };

  active_fragment:string
  active_fragment_subscription:Subscription
  default_fragment_key = "unit_details";

  constructor(
    private _route: ActivatedRoute,
    public _router: Router,
    public _active_route: ActivatedRoute,
    private _bond_application_storage: ApplicationStorageService,
    private _toast_service: ToastService,
  ) { 

  }

  ngOnDestroy(): void {

    this.active_fragment_subscription.unsubscribe();
  }

  ngOnInit(): void {

    this._route.paramMap.subscribe(params => {

      this.token = params.get('token');
      this.initApplication();

    });

    this.applicationLoading = true;

  }

  initApplication()
  {
      this._bond_application_storage.get$(this.token)
      .subscribe((data) => {

        this.applicationLoading = false;
        this.application = data["data"];

        if(this.application.status.code == "awt_dpst_slct")
        {
          this._router.navigate(['/application/pay-deposit/',this.token]);
        }

        if(this.application.status.code == "awt_app_slct") {
          this._router.navigate(['/application/select-type/',this.token]);
        }

        if(this.application.status.code == "susp")
        {
          this._router.navigate(['/']);
        }



        this.active_fragment_subscription = this._active_route.fragment.subscribe( fragment => this.fragmentAssertion(fragment) )

      }, (res) => {
        this._toast_service.show(res.error.message, { classname: 'bg-danger text-light' });
      });
  }

  /**
   * Assosiate human redable fragments with 
   * application statuses
   * 
   * @param {string}
   * @return {Void}
   */
  fragmentAssertion(fragment){

    this.active_fragment = fragment;

    if(this.fragment_map.hasOwnProperty(this.application.status.code)){
      this.default_fragment_key = this.fragment_map[this.application.status.code];
    }

    if(!Object.keys(this.links).includes(fragment)){
      this.active_fragment  = this.links[this.default_fragment_key].fragment;
    }

  }

  updateApplicationStatus(status) {
    this.application.status = status;
  }

}
