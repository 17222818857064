import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { faChevronDown,faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { UnitTypeService } from 'src/app/application/services/unit-type.service';

@Component({
  selector: 'app-extras',
  templateUrl: './extras.component.html',
  styleUrls: ['./extras.component.scss']
})
export class ExtrasComponent implements OnInit, OnChanges  {

  @Input() bondApplication: any;
  @Input() formData: any;
  @Input() token;
  extras:Array<any>;
  additions:Array<any>;
  extras_group:FormGroup;
  loading:boolean;

  purchase_price: number;

  iconDown = faChevronDown;
  iconRight = faChevronRight;

  constructor(
    private fb: FormBuilder,
    private _unit_type_service: UnitTypeService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.ngOnChanges();
  }

  initForm()
  {
    this.extras_group = this.fb.group({
        extras: this.fb.array([]),
    });
    this.bondApplication.addControl('extras_group',this.extras_group);
  }
  
  ngOnChanges()
  {
     (this.bondApplication.get('application_type.unit_type_id') as FormControl).valueChanges.subscribe((nv)=>{
       this.loadAdditions(nv);
     });
  }

  get previousExtras()
  {
    let selectedControls = (this.extras_group.controls.extras as FormArray).controls;
    return selectedControls.map(control => control.value);
  }


  hasExtra(id)
  {
    id = String(id)
    return this.previousExtras.includes(id);
  }

  get bondApplicationValid()
  {
    return (this.bondApplication.get('application_type.unit_type_id') as FormControl).value;
  }


  loadAdditions(unit_id) {
    this.loading = true;
    this._unit_type_service.getAdditions$(unit_id).subscribe(response => {
      this.additions = response['data'];
      this.loading = false;
  }, error => {
      this.loading = false;
      // this._toast_service.show(error.error.message, { classname: 'bg-danger text-light' });
  })
  }

}
