import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment'
import { developments } from 'src/app/config/developments.json'
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-bond-application-header',
  templateUrl: './bond-application-header.component.html',
  styleUrls: ['./bond-application-header.component.scss']
})
export class BondApplicationHeaderComponent implements OnInit,OnDestroy {
  header:string;
  navbarCollapsed = true;
  @Input() development: any;
  subscriptions: Array<Subscription>;
  linkData:Array<{link:string,title:string}>
  
  constructor(
    private _route: ActivatedRoute,
  ) { 
    this.subscriptions = [];
  }

  ngOnInit(): void {
      // {
      //   this.linkData=environment.headers.data;
      //   this.header=environment.headers.name;
      // }
  }

  

  ngOnDestroy(){
    // prevent memory leak when component destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }


}
