<ngb-toast
      *ngFor="let toast of toastService.toasts"
      [class]="toast.classname"
      [autohide]="false"
      [delay]="toast.delay || 5000"
      (hide)="toastService.remove(toast)">
      
        <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
          
            <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
        </ng-template>

        <ng-template #text>
          <button (click)="toastService.remove(toast)" type="button"class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          {{ toast.textOrTpl }}
        </ng-template>
</ngb-toast>
