import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-debit-order-details',
  templateUrl: './debit-order-details.component.html',
  styleUrls: ['./debit-order-details.component.scss']
})
export class DebitOrderDetailsComponent implements OnInit {

  debit_order_details: FormGroup;
  @Input() bondApplication: FormGroup;
  @Input() index: number;
  monthly = []; 
  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.AddToForm();
    this.monthly = Array(31).fill(0).map((x,i)=>i+1);
  }

  AddToForm(): void {
    this.debit_order_details = this.fb.group({
      bank_name: ['', [Validators.required]],
      bank_account_number: ['',[Validators.required]],
      account_type: ['', [Validators.required]],
      branch_name: ['',[Validators.required]],
      branch_code: ['',[]],
      bank_account_holder_fullname: ['', [Validators.required]],
      monthly_repayment_day: ['',[Validators.required,Validators.max(31),Validators.min(1)]]
      

    });
    this.bondApplication.addControl('debit_order_details', this.debit_order_details);
}

}
