<div class="card-header form-header">
 <div class="container-fluid">

   <div class="row">
     <div  (click)="gotoSection(section)" [class]="i!=sections.length-1?'col border-right-style':'col'" *ngFor="let section of sections; index as i;">
      <div class="header-section">
        
        <span [class]="{'text-danger':progress[section] !=100,'text-success': progress[section] ==100}">
          <fa-icon [icon]="progress[section] ==100?complete:incomplete" class="icon-sizing"></fa-icon>
        </span>
        
        <div>
          
          <p class="mb-1 d-none d-lg-block section-heading">{{section}}</p>
        </div>
      </div>
      
      
    </div>
  </div>
</div>
</div>