<div class="container finish-background">
    <div class="card" *ngIf="loading">
        <div class="card-body">
            <div [class]="{'h-150px':loading}" [appLoader]="loading">

            </div>
        </div>
    </div>
    <ng-container *ngIf="!loading && finishes.length > 1">
        <div *ngIf="!displayOnly()" class="finish-inner-container finish-text">
            <div class="row py-3">
                <div class="col-12">
                    <div class="py-3">Select your choice of finishes here. Please note that at a certain date you will not be able to change your selection. Ts & Cs apply.</div>
                </div>
            </div>
            <div class="row py-3">
                <div class="col-12 col-md-6">
                    <div>Please select your preferred finish:</div>
                </div>
                <div class="col-12 col-md-6">
                    <button class="btn btn-primary float-right" (click)="save()" *ngIf="!application.has_expired">
                        Save
                    </button>
                </div>
            </div>
        </div>
        <div class="finish-inner-container slider-container">
            <div class="row" >
                <ng-container *ngIf="!application.has_expired">
                    <div class="col-12 col-md-6" *ngFor="let finish of finishes; let i = index" >
                        <div *ngIf="!displayOnly()" class="finish-selection">
                        <div class="form-check py-3 text-left">
                            <input class="form-check-input" type="radio" name="finishRadios" id="finishRadios{{i}}" [value]="finish" [(ngModel)]="selectedFinish">
                            <label class="form-check-label" for="finishRadios{{i}}">
                                {{finish.name}} (Finish Selection {{i+1}})
                            </label>
                            </div>
                        </div>
                        <div class="finish-featured-image py-3">
                            <div class="featured-image" [ngStyle]="{'background-image': 'url(' + finish['featured_image'] + ')'}">
            
                            </div>
                        </div>
                        <div class="finish-description py-3" [innerHTML]="finish.description">
                            
                        </div>
                        <div class="button-container text-center" *ngIf="finish.schedule || application.development.finish_schedule_url">
                            <a class="btn btn-primary d-inline p-2 mb-2" href="{{finish.schedule ? finish.schedule : application.development.finish_schedule_url}}" target="_blank" download>
                                Download Schedule of Finishes
                            </a>
                        </div>
                </div>
                </ng-container>
                <ng-container *ngIf="application.has_expired">
                    <div class="col-12 selection-display py-5">
                        You selected {{selectedFinish ? selectedFinish.name : "nothing"}}. Unfortunatly the date to change your finish has passed.
                    </div>
                    <div class="col-12 col-md-6" *ngFor="let finish of finishes; let i = index" >
                        <div class="finish-selection">
                            <div class="col-12">
                                {{finish.name}}
                            </div>       
                            <div class="finish-featured-image py-3">
                                <div class="featured-image" [ngStyle]="{'background-image': 'url(' + finish['featured_image'] + ')'}">
                
                                </div>
                            </div>
                            <div class="finish-description py-3" [innerHTML]="finish.description">
                                
                            </div>
                            <div class="button-container text-center" *ngIf="finish.schedule || application.development.finish_schedule_url">
                                <a class="btn btn-primary d-inline p-2 mb-2" href="{{finish.schedule ? finish.schedule : application.development.finish_schedule_url}}" target="_blank" download>
                                    Download Schedule of Finishes
                                </a>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf="!displayOnly()">
                <div class="col-12 mt-3" *ngIf="finishComparisons && getObjectKeys(finishComparisons).length > 0">
                    <div class="py-3 font-italic text-left">
                        Use the sliders below to preview each of the above finishes.
                    </div>
                    <div class="pb-3" *ngFor="let label of getObjectKeys(finishComparisons) ; let i = index">
                        <ng-container *ngIf="finishComparisons[label]['active1'] && finishComparisons[label]['active2']">
                            <div class="row no-gutters py-3 slider-label font-weight-bold">
                                {{label}}
                            </div>
                            <div class="slider-splitter row no-gutters">
                                <div class="col-6">
                                    {{finishComparisons[label]['active1']['name']}}
                                </div>
                                <div class="col-6">
                                    {{finishComparisons[label]['active2']['name']}}
                                </div>
                            </div>
                            <div class="img-slider">
                                <img-comparison-slider>
                                    <img class="w-100" slot="after" src="{{finishComparisons[label]['active1']['url']}}" />
                                    <img class="w-100" slot="before" src="{{finishComparisons[label]['active2']['url']}}" />
                                </img-comparison-slider>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="finishComparisons[label]['images'].length > 2">
                            <div class="py-2 font-italic">
                                Select from the options below to compare different finishes
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="finish-radio" *ngFor="let image of finishComparisons[label]['images']" (click)="setActiveFinish(label, 'active1', image)" [ngClass]="{'active': image['url'] == finishComparisons[label]['active1']['url']}">
                                        {{image['name']}}
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="finish-radio" *ngFor="let image of finishComparisons[label]['images']" (click)="setActiveFinish(label, 'active2', image)" [ngClass]="{'active': image['url'] == finishComparisons[label]['active2']['url']}">
                                        {{image['name']}}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!loading && finishes.length == 1">
        <div class="finish-inner-container finish-text">
            <div class="col-12">
                <div class="finish-selection">
                    <div class="form-check py-3 text-left">
                        <div class="text-center">
                            {{finishes[0].name}}
                        </div>
                    </div>
                </div>
                <div class="finish-featured-image py-3">
                    <div class="featured-image" [ngStyle]="{'background-image': 'url(' + finishes[0]['featured_image'] + ')'}">
    
                    </div>
                </div>
                <div class="finish-description py-3 text-center" [innerHTML]="finishes[0].description">
                    
                </div>
                <div class="button-container text-center" *ngIf="finishes[0].schedule || application.development.finish_schedule_url">
                    <a class="btn btn-primary d-inline p-2" href="{{finishes[0].schedule ? finishes[0].schedule : application.development.finish_schedule_url}}" target="_blank" download>
                        Download Schedule of Finishes
                    </a>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!loading && finishes.length == 0">
        <div class="row">
            <div class="col-12 py-5">
                <div>There are no finishes for this unit type</div>
            </div>
        </div>
    </ng-container>
</div>