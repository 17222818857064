import { Component, Input, OnInit } from '@angular/core';
import { ApplicationStorageService } from 'src/app/application/services/application-storage.service'

@Component({
	selector: 'app-application-download',
	templateUrl: './application-download.component.html',
	styleUrls: ['./application-download.component.scss']
})
export class ApplicationDownloadComponent implements OnInit {

	/**
	 * Application 
	 * @var {Object}
	 */
	 @Input() application;

	/**
	 * Application download url
	 * @var {String}
	 */
	public application_download_url;

	/**
	 * Loading state
	 * @var {boolean}
	 */
	public loading = <boolean>false;

	constructor(private _application_service: ApplicationStorageService) {}

	ngOnInit(): void {
	 	
	}

	/**
	 * Dpwnload application by treiving new url token
	 * @return {void}
	 */
	download()
	{

		this.loading = true;

		let subscription = this._application_service.getApplicationToken$(this.application.token).subscribe( response => {
			subscription.unsubscribe();
			this.loading = false;
			window.open(response.data.url, "_blank");
			
		}, (error) => {

			this.loading = false;
			subscription.unsubscribe();
			console.error(error);

		});

	}


}
