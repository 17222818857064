import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserService } from 'src/app/user/services/user.service';
import { PaymentService } from '../../services/payment.service';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-payment-complete',
  templateUrl: './payment-complete.component.html',
  styleUrls: ['./payment-complete.component.scss']
})
export class PaymentCompleteComponent implements OnInit {
  checkCircle = faCheckCircle;
  timesCircle = faTimesCircle;
  availableActions:Array<any>;
  
  paymentStatus: any;
  actions: Array<any> = [];
  form_actions : Array<any> = [];

  paymentDataLoading: boolean;

  constructor(
    private _toast_service: ToastService,
    private _utility_service: UtilityService,
    private _payment: PaymentService,
    private _route: ActivatedRoute,
    private http: HttpClient,
    private _router: Router
  ) {

    this.availableActions = [
      {
        "action": "dashboard",
        "path": ['/']
      },
      {
        "action": "bond-application",
        "path": [':development','bond-application',':token']
      },
      {
        "action": "manual-application",
        "path": ['application',':token']
      },
      {
        "action": "cash-application",
        "path": ['application',':token']
      }
    ];
   };


  ngOnInit(): void {
    this.paymentDataLoading = false;
    
    this._route.paramMap.subscribe(params => {
      const id = params.get('id');
      const status = params.get('status');
      const queryParams = this._route.snapshot['queryParams'];

      this._payment.paymentStatus$(id,status,queryParams).subscribe(paymentStatus=>{
        this.paymentStatus = paymentStatus["data"];
        this.generateFormActions(paymentStatus["data"]["form_actions"]);
        this.generateActions(paymentStatus["data"]["actions"]);
      });;
    });

  
  }


  generateFormActions(actions)
  {
    this.form_actions = actions;
  }

  executeFormAction($event,action)
  {
    $event.preventDefault();
    let method = null;
    switch (action["method"]) {
      case "GET":
        method = this.http.get(action['url'],action['data']);
        break;
        case "PATCH":
          method =this.http.patch(action['url'],action['data']);
        break;
      default:
        method = this.http.post(action['url'],action['data']);
        break;
    }
    this.paymentDataLoading = true;
    method.subscribe((s)=>{
     this.paymentDataLoading = false;
     this._router.navigate(this.generateAppRoute(action['redirect_action']));
    })

    

  }

  generateActions(actions)
  {
      actions.forEach(serverRoute => {
          let action:any = {};
          action.title = serverRoute.title;
          action.path = this.generateAppRoute(serverRoute);
          this.actions.push(action);
      });
  }

  generateAppRoute(serverRoute)
  {
    let selectedAction = this.availableActions.find(a => a.action == serverRoute.action);
    if(serverRoute.data)
    {
      let finalPath = selectedAction.path;
        for (const key in serverRoute.data) {
          
          if (serverRoute.data.hasOwnProperty(key)) {
            this._utility_service.arrayFindReplace(finalPath,`:${key}`,serverRoute.data[key]);
          }
        }
        return   finalPath;
    }else
    {
      return selectedAction.path;
    }
  }


}
