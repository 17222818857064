import { Medium } from './medium'

export class Finish {
    id: number;
    description: string;
    featured_image: string;
    media: Array<Medium>
    name: String;
    resource_url: string;
    schedule:string

    constructor(
        id?: number,
        description?: string,
        featured_image?: string,
        media?: Array<Medium>,
        name?: string,
        resource_url?: string,
        schedule?:string
    )
    {
        this.id = id;
        this.description = description;
        this.featured_image = featured_image;
        this.media = media;
        this.name = name;
        this.resource_url = resource_url;
    }

    fromJson(data):Finish
    {
        return Object.assign(this,data);
    }
}