import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormDataService } from 'src/app/shared/services/form-data.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profileDetails: FormGroup;
 
  savePrompt: boolean;
  formLoading: boolean;
  submitLoading: boolean;
  provinces: any[];
  
  
  constructor(
    private _utility_service: UtilityService,
    private fb: FormBuilder,
    private _toast_service: ToastService,
    private _user_service: UserService,
    private _form_data_service: FormDataService,
    private _validation_service: ValidationService

  ) { }
  
  ngOnInit(): void {
    this.formLoading = false;
    this.initForm();
  }

  initForm(): void {
    this.formLoading = true;

    this.profileDetails = this.fb.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      id_number: ['', [Validators.required]],
      work_number: ['', [Validators.required]],
      mobile_number: ['', [Validators.required]],
      address: this.fb.group({
        address_line_1: ['', [Validators.required]],
        address_line_2: ['', [Validators.required]],
        city: ['', [Validators.required]],
        postal_code: ['', [Validators.required]],
        province_id: ['', [Validators.required]],
      })
    });
  
    this._form_data_service.get$().subscribe(data=>{
      this.provinces = data["data"]["provinces"];
    });

    this._user_service.get$().subscribe(data=>{
      this.formLoading = false;
      this.profileDetails.patchValue(data["data"]);
    })

  }

  onSubmit(): void {
    this._utility_service.markFormGroupTouched(this.profileDetails);    
    if(!this.profileDetails.invalid)
    {
      this.submitLoading = true;
  
        this._user_service.update$(this.profileDetails.value).subscribe(data => {
          this.submitLoading = false;
          this._toast_service.show(data['message'],{ classname: 'bg-success text-light' });

        }, (res) => {
          this.submitLoading = false;    
          if (res.status == 422) {
            this._validation_service.setServerValidation(this.profileDetails,res.error.errors);
          }     
        });
    }else
    {
      this._toast_service.show("Please ensure that your input is valid", { classname: 'bg-danger text-light' });
    }
  }

}
