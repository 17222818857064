export const environment = {
  production: true,
  api:{
    base_url:"https://crafthomes-admin.com/api"
  },
  echo:{
    userModel: 'App.User',
    notificationNamespace: '',
    options: {
        prefix: '',
        broadcaster: 'socket.io',
        authEndpoint: 'https://crafthomes-admin.com/broadcasting/auth',
        host: 'https://echo.crafthomes-admin.com:6001',
        wsPort: 6001
    }
},
  headers:{
    name:"theterraceatsandtongate",
    data: [
      {
        link: "https://crafthomes.co.za/theterraceatsandtongate/",
        title: "Home"
      },
      {
        link: "https://crafthomes.co.za/theterraceatsandtongate/#overview",
        title: "Overview"
      },
      {
        link: "https://crafthomes.co.za/theterraceatsandtongate/#gallery",
        title: "Gallery"
      },
      {
        link: "https://crafthomes.co.za/theterraceatsandtongate/#units",
        title: "Unit Types"
      },
      {
        link: "https://crafthomes.co.za/theterraceatsandtongate/#reserve",
        title: "Buy your unit"
      },
      {
        link: "https://crafthomes.co.za/theterraceatsandtongate/#locality",
        title: "Locality"
      },
      {
        link: "https://crafthomes.co.za/theterraceatsandtongate/#contacts",
        title: "Contact"
      }
    ]
  }
};
