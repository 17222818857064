import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changePassword: FormGroup;
  componentStatus: {
    display_form
    message
  };
  formLoading: boolean;

  constructor(
    private fb: FormBuilder,
    private _toast_service: ToastService,
    private _userService: UserService,
    private _validation_service: ValidationService,
    private _utility_service: UtilityService,
    private _router: Router,
  ) { }


  ngOnInit(): void {
    this.componentStatus = {
      display_form:true,
      message:null
    };

    this.initForm();
  }


  initForm(): void {
    
    this.changePassword = this.fb.group({
      old_password: ['', [Validators.required]],
      password: ['', [Validators.required]],
      password_confirmation: ['', [Validators.required]]
    });

  }

  onSubmit()
  {
    this._utility_service.markFormGroupTouched(this.changePassword);    
    if(!this.changePassword.invalid)
    {
      this.formLoading = true;
        this._userService.passwordUpdate$(this.changePassword.value).subscribe(data => {
          this.formLoading = false;
          this._toast_service.show(data['message'], { classname: 'bg-success text-light' });
          this._router.navigate(['/']);
        }, (res) => {
          this.formLoading = false;       
          if (res.status == 422) {
            this._validation_service.setServerValidation(this.changePassword,res.error.errors);
          }
  
        });
    }else
    {
      this._toast_service.show("Please ensure that your input is valid", { classname: 'bg-danger text-light' });
    }
  }

}
