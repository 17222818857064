import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {  Observable } from 'rxjs';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserService } from 'src/app/user/services/user.service';
import { ApplicationStorageService } from 'src/app/application/services/application-storage.service';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  token:string;
  application:any;
  applicationDataLoading:boolean;
  subscriptions:any;
  auth: boolean;
  successfullLoad: boolean;
  checkCircle = faCheckCircle;

  constructor(
    private _route: ActivatedRoute,
    private _application_storage: ApplicationStorageService,
    private _toast_service: ToastService,
    private _auth_service: UserService,
  ) {
    this.subscriptions = {};
    this.auth = false;
   }


   ngOnInit(): void {
    this.subscriptions.auth == this._auth_service.isAuthed$().subscribe((a)=>{
      this.auth = a;
    });

    this.subscriptions.routeParams = this._route.paramMap.subscribe(params => {
      this.token = params.get('token');
      this.initApplication();
    });
  }

  initApplication()
  {
    this.applicationDataLoading = true;
    this.subscriptions.applicationHttp = this._application_storage.thankYou$(this.token)
      .subscribe((data) => {
        this.applicationDataLoading = false;
        this.successfullLoad = true;
        this.application = data["data"];
      }, (res) => {
        this.applicationDataLoading = false;
        this.successfullLoad = false;
        this._toast_service.show(res.error.message, { classname: 'bg-danger text-light' });
      });
  }


  ngOnDestroy(): void {

    for (const subscription in this.subscriptions) {
      if (this.subscriptions.hasOwnProperty(subscription)) {
          this.subscriptions[subscription].unsubscribe();
      }
    }
  }
  

}
