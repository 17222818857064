
<div class="container">
    <div *ngIf="componentStatus.display_form; else messageBlock " class="card craft-card bg-light mt-5">
      <div class="card-body">
        <h3 class="text-center mb-4">Forgot Password</h3>
        <form [formGroup]="forgotPasswordDetails">
            <div class="row" [ngClass]="{'mb-2': forgotPasswordDetails.get('password')}">
              <div class="col-lg-4 col-md-4 m-auto">
                <!-- start of  Email field -->
                  <div class="form-group craft-form-group">
                      <input placeholder="Email" formControlName="email" type="email" class="form-control" [ngClass]="{'is-invalid':forgotPasswordDetails.get('email').invalid && (forgotPasswordDetails.get('email').dirty || forgotPasswordDetails.get('email').touched) }" id="email-field">
                      <app-invalid-feedback  class="invalid-feedback" name="Email" [control]="forgotPasswordDetails.get('email')"></app-invalid-feedback>
                  </div>
                <!-- end of Email Field -->
              </div>
            </div>

            <div class="col-lg-4 col-md-4 m-auto">
              <!-- start of  token field -->
              <input placeholder=""  *ngIf="forgotPasswordDetails.get('token')" formControlName="token" type="hidden" class="form-control">
              <!-- end of token Field -->
            </div>

            <div class="row mb-2">
              <div class="col-lg-4 col-md-4 m-auto">
                <!-- start of  Email field -->
                  <div class="form-group craft-form-group" *ngIf="forgotPasswordDetails.get('password')">
                    <input placeholder="Password" formControlName="password" type="password" class="form-control" [ngClass]="{'is-invalid':forgotPasswordDetails.get('password').invalid && (forgotPasswordDetails.get('password').dirty || forgotPasswordDetails.get('password').touched) }" id="email-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Password" [control]="forgotPasswordDetails.get('password')"></app-invalid-feedback>
                  </div>
                <!-- end of Email Field -->
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-lg-4 col-md-4 m-auto">
                <!-- start of  Email field -->
                  <div class="form-group craft-form-group" *ngIf="forgotPasswordDetails.get('password_confirmation')">
                    <input placeholder="Confirm Password" formControlName="password_confirmation" type="password" class="form-control" [ngClass]="{'is-invalid':forgotPasswordDetails.get('password_confirmation').invalid && (forgotPasswordDetails.get('password_confirmation').dirty || forgotPasswordDetails.get('password_confirmation').touched) }" id="email-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Confirm Password" [control]="forgotPasswordDetails.get('password_confirmation')"></app-invalid-feedback>
                  </div>
                <!-- end of Email Field -->
              </div>
            </div>

            <div class="row mb-4" *ngIf="!forgotPasswordDetails.get('password')">
              <button [appLoader]="formLoading" type="submit" class="btn btn-primary d-block m-auto" (click)="onSubmit()">Forgot Password</button>
            </div>

            <div class="row mb-4" *ngIf="forgotPasswordDetails.get('password')">
              <button [appLoader]="formLoading" type="submit" class="btn btn-primary d-block m-auto" (click)="onSubmit()">Update</button>
            </div>
  
        </form>
    </div>
    </div>
    
    <ng-template #messageBlock>
        <div class="card craft-card bg-light mt-5">
            <div class="card-body">
                <div class="row">
                    <fa-icon [icon]="checkCircle" class="fa-5x fa-icon d-block m-auto"></fa-icon>
                </div>
                <ng-container *ngIf="!forgotPasswordDetails.get('password')">
                  <h3 class="text-center mb-4">Email Sent</h3>
                  <h5 class="text-center mb-4">Please check your email for further instructions</h5>
                </ng-container>
                <ng-container *ngIf="forgotPasswordDetails.get('password')">
                  <h3 class="text-center mb-4">Password Reset</h3>
                  <h5 class="text-center mb-4">You can try logging in now</h5>
                </ng-container>
                <div class="row mb-4" >
                    <a class="btn btn-primary d-block m-auto" [routerLink]="'/login'">
                        Return to Login
                    </a>
                </div>
            </div>
        </div>
    </ng-template>

  </div>
  