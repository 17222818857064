
<div class="container">
    <div class="card craft-card bg-light mt-5" [appLoader]="applicationDataLoading">
        <div class="card-body" *ngIf="application">
            <h3 class="text-center mb-4">Thank You</h3>
            <p class="text-center mb-4">
                We have received your deposit and your unit is now reserved. Now we need you to select how you would like to pay for the rest.
            </p>
            <div class="row">
                <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12">
                    <a class="card" (click)="selectApplicationType($event, 'cash')">
                        <div class="card-body payment-card">
                            <div class="row">
                                <fa-icon [icon]="cashHand" class="fa-3x fa-icon d-block m-auto"></fa-icon>
                            </div>
                            <p class="text-center">
                                Pay with Cash <br><br>
                                We will require you to upload and submit a few documents in order to proceed
                            </p>
                        </div>
                    </a>
                </div>
                <div class="col-xl-2 col-lg-12 col-md-12 col-sm-12 align-self-center">
                    <h3 class="text-center">or</h3>
                </div>
                <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12">
                    <a class="card" (click)="selectApplicationType($event, 'bond')">
                        <div class="card-body payment-card">
                            <div class="row">
                                <fa-icon [icon]="contract" class="fa-3x fa-icon d-block m-auto"></fa-icon>
                            </div>
                            <p class="text-center">
                                Complete a bond application <br><br>
                                We will submit your application to the major banks on your behalf
                            </p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>


