import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import options from 'src/app/data/options.json'
import { PropertyDetails } from 'src/app/models/property-details';
@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss']
})
export class PropertyDetailsComponent implements OnInit, OnChanges {
  property_details:FormGroup;
  options = options;
  developmentAddress:PropertyDetails;
  @Input() bondApplication: FormGroup;
  @Input() index: number;
  @Input() formData: any;

  constructor(private fb: FormBuilder,
    //  private _validation_service: ValidationService
  ) { 
    
  }
  ngOnInit(): void {
    
  }

  get registerFormControl() {
    return this.property_details.controls;
  }

  ngOnChanges(changes: SimpleChanges)
  {
    if(changes["formData"] && changes["formData"]["currentValue"])
    {
      this.setPropertyDetails(changes["formData"]["currentValue"]);
    }
  }

  setPropertyDetails(formData)
  {
    this.developmentAddress = new PropertyDetails();
    if(formData["development"])
    {
      this.developmentAddress.development_name = formData["development"]["name"];
      this.developmentAddress.property_description = formData["development"]["description"];
      Object.assign(this.developmentAddress,formData["development"]["full_address"]);
    }

    if(this.formData && formData["unit"])
    {
      this.developmentAddress.unit_number = formData["unit"]["number"];
    }
    this.AddToForm();
  }

  AddToForm(): void{
    this.property_details = this.fb.group({
        development_name:[this.developmentAddress.development_name,[Validators.required]],
        unit_number:[this.developmentAddress.unit_number,[Validators.required]],
        property_description:[this.developmentAddress.property_description,[Validators.required]],
        property_type:[this.developmentAddress.property_type,[Validators.required]],
        street_name:[this.developmentAddress.street_name,[Validators.required]],
        suburb:[this.developmentAddress.suburb,[Validators.required]],
        city:[this.developmentAddress.city,[Validators.required]],
        province:[this.developmentAddress.province,[Validators.required]],
        transferring_attorney_name:[this.developmentAddress.transferring_attorney_name,[Validators.required]],
    });
    let self = this;
    setTimeout(function() {
        self.bondApplication.setControl('property_details',self.property_details);
    }, 0);
  }

}
