export class User {
    id:number;
    first_name: string;
    email: string;
    email_verified_at: string;
    password: string;
    last_name: string;
    id_number: string;
    work_number: string;
    mobile_number: string;
    full_name:string;

    constructor(
        id?: number,
        first_name?: string,
        email?: string,
        email_verified_at?: string,
        password?: string,
        last_name?: string,
        id_number?: string,
        work_number?: string,
        mobile_number?: string,
        full_name?: string,
    )
    {
        this.id = id;
        this.first_name = first_name;
        this.email = email;
        this.email_verified_at = email_verified_at;
        this.password = password;
        this.last_name = last_name;
        this.id_number = id_number;
        this.work_number = work_number;
        this.mobile_number = mobile_number;
        this.full_name = full_name;
    }

    fromJson(data):User
    {
        return Object.assign(this,data);
    }
}
