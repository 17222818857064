<div class="container">
        <div class="card craft-card bg-light mt-5" *ngIf="application && successfullLoad else unsuccessfullTemplate">
            <div class="card-body" [appLoader]="applicationDataLoading">
                <div class="row">
                    <fa-icon [icon]="checkCircle" class="fa-5x fa-icon d-block m-auto"></fa-icon>
                </div>
                <h3 class="text-center mb-4">Thank you</h3>
                <h5 class="text-center mb-4">Your form has been submitted</h5>
                <p class="text-center">
                    You will receive an email confirming your reservation.
                    <br>
                    You will be required to pay a R5000 deposit. The payment can be made now, or alternatively, after the finance is in place.
                    <br>
                    Please ensure your bond application is submitted to Craft Homes within 48 hours of receipt of the confirmation email.
                    <br>
                    Failing to submit a bond application could result in your unit becoming available again.
                </p>
                <ng-container *ngIf="!auth">
                    <p class="text-center">
                        To confirm your unit's reservation click on the link below to log into your Craft Client Profile. <br>
                        If you are not yet registered, please look out for an email from us which will contain your temporary login details.
                    </p>
                    <div class="row mb-4" >
                        <a class="btn btn-primary d-block m-auto" [routerLink]="['/application', application.token]">
                            Login
                        </a>
                    </div>
                </ng-container>
                <p class="text-center">For any queries, please contact Craft Homes on <a href="tel:0860027238">086 002 7238</a> or <a href="mailto:info@crafthomes.co.za">info@crafthomes.co.za</a></p>
                <p class="text-center">We look forward to building this relationship with you</p>
            </div>
        </div>
        <ng-template #unsuccessfullTemplate>
            <div class="alert alert-info" *ngIf="!applicationDataLoading">
                Application may been already loaded
            </div>
            <div class="card craft-card min-vh-100">
                <div class="card-body" [appLoader]="applicationDataLoading">
                </div>
            </div>
        </ng-template>
   
</div>
