import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { ApplicationStorageService } from 'src/app/application/services/application-storage.service';
import options from 'src/app/data/options.json'


@Component({
  selector: 'app-loan-details',
  templateUrl: './loan-details.component.html',
  styleUrls: ['./loan-details.component.scss']
})
export class LoanDetailsComponent implements OnInit {

  loan_details:FormGroup;
  options = options;
  @Input() bondApplication: FormGroup;
@Input() index: number;
  @Input() formData: FormGroup;
  monthly = []; 

  requiredMessage = "Value must be 0 or higher";
  constructor(private fb: FormBuilder,
     private _bond_application_storage: ApplicationStorageService
  ) { 
  }

  ngOnInit(): void {
    this.AddToForm();
    this.monthly = Array(31).fill(0).map((x,i)=>i+1);
  }

  get registerFormControl() {
    return this.loan_details.controls;
  }

  get unitFormControl()
  {
   if(this._bond_application_storage.getBondApplicationForm)
   {
      return (this._bond_application_storage.getBondApplicationForm().get('application_type.unit_type_id') as FormControl);
   }
   return null
  }

  get defaultPrice()
  {
    let selected_unit_type = null;
    let unit_type_id = null;
    
    if(this.unitFormControl)
    {
      unit_type_id = this.unitFormControl.value;
    }
    if(this.formData && this.formData["unit"] && this.formData["unit"]["unit_types"])
    {
      selected_unit_type = this.formData["unit"]["unit_types"].find((unit_type)=>{
        return unit_type_id == unit_type.id;
      });
    }
   
    if(selected_unit_type)
    {
      return  selected_unit_type.pivot.price;
    }
    
    return 0;
  }

  fill(): void{
    this.loan_details.controls.loan_amount.setValue(this.loan_details.get("purchase_price").value);
  }

  AddToForm(): void{

    this.loan_details = this.fb.group({
        purchase_price: [this.defaultPrice,[Validators.required]],
        loan_amount: ['',[Validators.required]],
        loan_term: ['',[Validators.required]],
        monthly_repayment_day: ['',[Validators.required]],
    });
    this.bondApplication.addControl('loan_details',this.loan_details);
  }

}
