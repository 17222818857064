<fieldset [formGroup]="declaration">
<div class="row">
    <div class="col">
        <p>
            <u>
                Marketing consent on behalf of the bank(s) you have selected above.
            </u>
        </p>
        <p>
            I consent to:
        </p>
    </div>
</div>
<div class="row">
    <div class="col">
        <div class="form-group">
            <label for="marketing_products-field{{index}}">
                You marketing your products, services, and special offers to me
            </label>
            <div class="row">
                <div class="col-md pr-2 pl-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="marketing_products" id="marketing_products-yes{{index}}"
                            value="1">
                        <label class="form-check-label" for="marketing_products-yes{{index}}">
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="marketing_products" id="marketing_products-no{{index}}"
                            value="0">
                        <label class="form-check-label" for="marketing_products-no{{index}}">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-invalid-feedback  [ngClass]="{'d-block':declaration.get('marketing_products').invalid && (declaration.get('marketing_products').dirty || declaration.get('marketing_products').touched) }" class="invalid-feedback" name="This " [control]="declaration.get('marketing_products')"></app-invalid-feedback>

        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <div class="form-group">
            <label for="positive_contact_me-field{{index}}">
                You communicating other companies’ products, services and special offers to me. If I respond
                positively to such communication, that company may contact me.
            </label>
            <div class="row">
                <div class="col-md pr-2 pl-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="positive_contact_me" id="positive_contact_me-yes{{index}}"
                            value="1">
                        <label class="form-check-label" for="positive_contact_me-yes{{index}}">
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="positive_contact_me" id="positive_contact_me-no{{index}}"
                            value="0">
                        <label class="form-check-label" for="positive_contact_me-no{{index}}">
                            No
                        </label>
                    </div>
                    <app-invalid-feedback  [ngClass]="{'d-block':declaration.get('positive_contact_me').invalid && (declaration.get('positive_contact_me').dirty || declaration.get('positive_contact_me').touched) }" class="invalid-feedback" name="This " [control]="declaration.get('positive_contact_me')"></app-invalid-feedback>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <div class="form-group">
            <label for="sharing_personal_information-field{{index}}">
                You sharing my personal information within the group for marketing purposes and the group then
                marketing its products, services and special offers to me.
            </label>
            <div class="row">
                <div class="col-md pr-2 pl-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="sharing_personal_information" id="sharing_personal_information-yes{{index}}"
                            value="1">
                        <label class="form-check-label" for="sharing_personal_information-yes{{index}}">
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="sharing_personal_information" id="sharing_personal_information-no{{index}}"
                            value="0">
                        <label class="form-check-label" for="sharing_personal_information-no{{index}}">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-invalid-feedback  [ngClass]="{'d-block':declaration.get('sharing_personal_information').invalid && (declaration.get('sharing_personal_information').dirty || declaration.get('sharing_personal_information').touched) }" class="invalid-feedback" name="This " [control]="declaration.get('sharing_personal_information')"></app-invalid-feedback>

        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <div class="form-group">
            <label for="research_purposes-field{{index}}">
                You contacting me for research purposes. (The research companies we use follow the strict codes
                of conduct and treat customer information confidentially).
            </label>
            <div class="row">
                <div class="col-md pr-2 pl-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="research_purposes" id="research_purposes-yes{{index}}"
                            value="1">
                        <label class="form-check-label" for="research_purposes-yes{{index}}">
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="research_purposes" id="research_purposes-no{{index}}"
                            value="0">
                        <label class="form-check-label" for="research_purposes-no{{index}}">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-invalid-feedback  [ngClass]="{'d-block':declaration.get('research_purposes').invalid && (declaration.get('research_purposes').dirty || declaration.get('research_purposes').touched) }" class="invalid-feedback" name="This " [control]="declaration.get('research_purposes')"></app-invalid-feedback>

        </div>
    </div>
</div>

<div class="row mt-4">
    <div class="col">
        <p>
            CREDIT & GENERAL CONSENTS
        </p>
    </div>
</div>

<div class="row">
    <div class="col">
        <div class="form-group">
            <label for="bank_enquire_credit-field{{index}}">
                I agree that the bank may enquire about my credit record with any credit reference agencies to consider this credit application or to update my information
            </label>
            <div class="row">
                <div class="col-md pr-2 pl-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="bank_enquire_credit" id="bank_enquire_credit-yes{{index}}"
                            value="1">
                        <label class="form-check-label" for="bank_enquire_credit-yes{{index}}">
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="bank_enquire_credit" id="bank_enquire_credit-no{{index}}"
                            value="0">
                        <label class="form-check-label" for="bank_enquire_credit-no{{index}}">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-invalid-feedback  [ngClass]="{'d-block':declaration.get('bank_enquire_credit').invalid && (declaration.get('bank_enquire_credit').dirty || declaration.get('bank_enquire_credit').touched) }" class="invalid-feedback" name="This " [control]="declaration.get('bank_enquire_credit')"></app-invalid-feedback>

        </div>
    </div>
</div>


<div class="row">
    <div class="col">
        <div class="form-group">
            <label for="bank_share_information-field{{index}}">
                I agree that the bank may share information with other credit agencies about how I manage this loan agreement during the time of the loan. The agencies may share this information with other credit providers.
            </label>
            <div class="row">
                <div class="col-md pr-2 pl-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="bank_share_information" id="bank_share_information-yes{{index}}"
                            value="1">
                        <label class="form-check-label" for="bank_share_information-yes{{index}}">
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="bank_share_information" id="bank_share_information-no{{index}}"
                            value="0">
                        <label class="form-check-label" for="bank_share_information-no{{index}}">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-invalid-feedback  [ngClass]="{'d-block':declaration.get('bank_share_information').invalid && (declaration.get('bank_share_information').dirty || declaration.get('bank_share_information').touched) }" class="invalid-feedback" name="This " [control]="declaration.get('bank_share_information')"></app-invalid-feedback>

        </div>
    </div>
</div>

<div class="row">
    <div class="col">
        <div class="form-group">
            <label for="bank_fraud_identity-field{{index}}">
                I agree that the bank may carry out identify and fraud prevention checks and share information relating to this application with South African Fraud Prevention Service
            </label>
            <div class="row">
                <div class="col-md pr-2 pl-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="bank_fraud_identity" id="bank_fraud_identity-yes{{index}}"
                            value="1">
                        <label class="form-check-label" for="bank_fraud_identity-yes{{index}}">
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="bank_fraud_identity" id="bank_fraud_identity-no{{index}}"
                            value="0">
                        <label class="form-check-label" for="bank_fraud_identity-no{{index}}">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-invalid-feedback  [ngClass]="{'d-block':declaration.get('bank_fraud_identity').invalid && (declaration.get('bank_fraud_identity').dirty || declaration.get('bank_fraud_identity').touched) }" class="invalid-feedback" name="This " [control]="declaration.get('bank_fraud_identity')"></app-invalid-feedback>
        </div>
    </div>
</div>

<div class="row">
    <div class="col">
        <div class="form-group">
            <label for="admin_fees-field{{index}}">
                I agree that I will be responsible for the administration fees and wasted costs that the bank incurs if I cancel my loan request after the bank has granted it.
            </label>
            <div class="row">
                <div class="col-md pr-2 pl-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="admin_fees" id="admin_fees-yes{{index}}"
                            value="1">
                        <label class="form-check-label" for="admin_fees-yes{{index}}">
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="admin_fees" id="admin_fees-no{{index}}"
                            value="0">
                        <label class="form-check-label" for="admin_fees-no{{index}}">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-invalid-feedback  [ngClass]="{'d-block':declaration.get('admin_fees').invalid && (declaration.get('admin_fees').dirty || declaration.get('admin_fees').touched) }" class="invalid-feedback" name="This " [control]="declaration.get('admin_fees')"></app-invalid-feedback>

        </div>
    </div>
</div>

<div class="row">
    <div class="col">
        <div class="form-group">
            <label for="married_community-field{{index}}">
                Please note that if You are married in community of property, this agreement will be in both You and your spouses names.
            </label>
            <div class="row">
                <div class="col-md pr-2 pl-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="married_community" id="married_community-yes{{index}}"
                            value="1">
                        <label class="form-check-label" for="married_community-yes{{index}}">
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="married_community" id="married_community-no{{index}}"
                            value="0">
                        <label class="form-check-label" for="married_community-no{{index}}">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-invalid-feedback  [ngClass]="{'d-block':declaration.get('married_community').invalid && (declaration.get('married_community').dirty || declaration.get('married_community').touched) }" class="invalid-feedback" name="This  " [control]="declaration.get('married_community')"></app-invalid-feedback>

        </div>
    </div>
</div>

<div class="row">
    <div class="col">
        <div class="form-group">
            <label for="information_reflection-field{{index}}">
                I confirm that the information I have given is correct and a true reflection of my financial position and that there is no other information that would affect the bank’s decision to approve the loan.
            </label>
            <div class="row">
                <div class="col-md pr-2 pl-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="information_reflection" id="information_reflection-yes{{index}}"
                            value="1">
                        <label class="form-check-label" for="information_reflection-yes{{index}}">
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="information_reflection" id="information_reflection-no{{index}}"
                            value="0">
                        <label class="form-check-label" for="information_reflection-no{{index}}">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-invalid-feedback  [ngClass]="{'d-block':declaration.get('information_reflection').invalid && (declaration.get('information_reflection').dirty || declaration.get('information_reflection').touched) }" class="invalid-feedback" name="This " [control]="declaration.get('information_reflection')"></app-invalid-feedback>
        </div>
    </div>
</div>

<div class="row">
    <div class="col">
        <div class="form-group">
            <label for="third_party_providers-field{{index}}">
                Third-party qualification data providers consent:<br><br>
                I/we consent that the Lender may obtain and use my/our information from third-party
                qualification data providers to conduct an assessment to determine my/our eligibility
                for and the appropriateness of the supply of the loan applied for.
            </label>
            <div class="row">
                <div class="col-md pr-2 pl-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="third_party_providers" id="third_party_providers-yes{{index}}"
                            value="1">
                        <label class="form-check-label" for="third_party_providers-yes{{index}}">
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="third_party_providers" id="third_party_providers-no{{index}}"
                            value="0">
                        <label class="form-check-label" for="third_party_providers-no{{index}}">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-invalid-feedback  [ngClass]="{'d-block':declaration.get('third_party_providers').invalid && (declaration.get('third_party_providers').dirty || declaration.get('third_party_providers').touched) }" class="invalid-feedback" name="This " [control]="declaration.get('third_party_providers')"></app-invalid-feedback>
        </div>
    </div>
</div>

<div class="row">
    <div class="col">
        <div class="form-group">
            <label for="fraud_prevention_services-field{{index}}">
                South African Fraud Prevention Services consent:<br><br>
                I/we consent that the Lender may obtain, use and share information about me/us
                with the South African Fraud Prevention Services for financial crime detection,
                prevention and prosecution purposes, or if the Lender reasonably believes that I/we
                have provided any false and/or misleading information and/or documents to it.
            </label>
            <div class="row">
                <div class="col-md pr-2 pl-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="fraud_prevention_services" id="fraud_prevention_services-yes{{index}}"
                            value="1">
                        <label class="form-check-label" for="fraud_prevention_services-yes{{index}}">
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="fraud_prevention_services" id="fraud_prevention_services-no{{index}}"
                            value="0">
                        <label class="form-check-label" for="fraud_prevention_services-no{{index}}">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-invalid-feedback  [ngClass]="{'d-block':declaration.get('fraud_prevention_services').invalid && (declaration.get('fraud_prevention_services').dirty || declaration.get('fraud_prevention_services').touched) }" class="invalid-feedback" name="This " [control]="declaration.get('fraud_prevention_services')"></app-invalid-feedback>
        </div>
    </div>
</div>

<div class="row">
    <div class="col">
        <div class="form-group">
            <label for="payslip_verification-field{{index}}">
                Payslip verification consent:<br><br>
                I/we consent that the Lender may obtain and share information about me/us with the
                payroll systems utilised by my/our employer(s) in order to retrieve copies of my/our
                payslips to verify the financial information provided by me/us to the Lender.
            </label>
            <div class="row">
                <div class="col-md pr-2 pl-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="payslip_verification" id="payslip_verification-yes{{index}}"
                            value="1">
                        <label class="form-check-label" for="payslip_verification-yes{{index}}">
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio"
                            formControlName="payslip_verification" id="payslip_verification-no{{index}}"
                            value="0">
                        <label class="form-check-label" for="payslip_verification-no{{index}}">
                            No
                        </label>
                    </div>
                </div>
            </div>
            <app-invalid-feedback  [ngClass]="{'d-block':declaration.get('payslip_verification').invalid && (declaration.get('payslip_verification').dirty || declaration.get('payslip_verification').touched) }" class="invalid-feedback" name="This " [control]="declaration.get('payslip_verification')"></app-invalid-feedback>
        </div>
    </div>
</div>

<div class="row">
    <div class="col">
        <p>
            POPIA CONSENTS
        </p>
        <p>
            You give consent for us to collect and process your Personal Information as set out below:
        </p>
        <ol>
            <li>
                Where lawful and reasonable, from external and public sources for credit, fraud prevention, identity, address, income and compliance purposes.
            </li>
            <li>
                You confirm that, if you give us Personal Information about or on behalf of other persons (including account signatories, sharehliders, principal executive officers, trustees and beneficiaries), you are authorised to: (a) give us the Personal Information; (b) consent on their behalf to the processing of their Personal Information, and specifically any cross-border transfer of Personal Information to, from and outside the country where the products or services are provided; and (c) receive any privacy notices on their behalf.
            </li>
            <li>
                For the purpose of assessing your application for products and services.
            </li>
            <li>
                To enable us to create a personalised communications experience, including with regard to operational notifications, between you and the Bank in the application process by analysing your interactions with the bank.
            </li>
            <li>
                By sharing your Personal Information within the Group and/or with our third-party service providers, which may not be within the jurisdiction where the products and services are provided.
            </li>
        </ol>
    </div>
</div>

</fieldset>
<div class="row">
    <div class="col">
        <p>
            I/we declare that:
        </p>
        <p>
            I/We have disclosed to the Lender all relevant information relating to existing credit agreements, suretyships and
            current credit applications submitted to any other credit provider. And I/We hereby certify that the Income
            and Expenditure indicated above is a true and correct statement of my/our financial situation inclusive of
            the loan hereby granted. The applicant/s completing this questionnaire are hereby reminded that in terms of
            section 81(1) of the National Credit Act when applying for a credit agreement, and while that credit
            agreement is being considered by the credit provider, the applicant/s must fully and truthfully answer any
            requests for information made by the credit provider as part of the assessment and hereby confirms the
            correctness of the above mentioned information. Misrepresentation of facts will be dealt with in the terms
            of the applicable law
        </p>
        <p>
            I/ We hold no other citizenships or residencies and will inform the lender in writing within 30 days of change of status. 
        </p>
    </div>
</div>
<div class="row">
    <div class="col">
        <p>
            Please sign here using your mouse:
        </p>
    </div>
</div>
<div class="row">
    <div class="col-md">

        <signature-pad #signaturePad [options]="signaturePadOptions" (onBeginEvent)="drawStart()"
            (onEndEvent)="drawComplete()"></signature-pad>
        <app-invalid-feedback
            [ngClass]="{'d-block':declaration.get('signature').invalid && (declaration.get('signature').dirty || declaration.get('signature').touched) }"
            class="invalid-feedback" name="Declaration" [control]="declaration.get('signature')"></app-invalid-feedback>

    </div>
</div>
<div class="row">
    <div class="col-md">
        <button type="button" class="btn btn-primary" (click)="clearSignature()">Clear</button>
    </div>
</div>
