import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import  * as converter from 'number-to-words';
import moment  from 'moment';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { ApplicationStorageService } from '../../services/application-storage.service';

import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/user/services/user.service';
import { faHandPointDown } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-manual-form',
  templateUrl: './manual-form.component.html',
  styleUrls: ['./manual-form.component.scss']
})
export class ManualFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input() application;
  @Input() token;

  @Output() status = new EventEmitter<{}>();
  faHandPointDown = faHandPointDown;


  idDocFiles = [];
  proofOfResFiles = [];
  salarySlipFiles = [];
  bankStatementFiles = [];
  applicationFiles = [];
  salesAgreementFiles = [];
  selectedUnitType;

  unitTypes;
  unitTypeSub;

  manualForm: FormGroup;

  loading:boolean;

  checkCircle = faCheckCircle;
  activeUser;
  activeUserSub;
  proofOfResFilesDirty = false;
  get proofOfResFilesValid(): boolean {
    return this.proofOfResFiles.length > 0 && this.proofOfResFilesDirty;
  }

  idDocFilesDirty = false;
  get idDocFilesValid(): boolean {
    return this.idDocFiles.length > 0 && this.idDocFilesDirty;
  }

  salarySlipFilesDirty = false;
  get salarySlipFilesValid(): boolean {
    return this.salarySlipFiles.length > 0 && this.salarySlipFilesDirty;
  }

  bankStatementFilesDirty = false;
  get bankStatementFilesValid(): boolean {
    return this.bankStatementFiles.length > 0 && this.bankStatementFilesDirty;
  }

  applicationFilesDirty = false;
  get applicationFilesValid(): boolean {
    return this.applicationFiles.length > 0 && this.applicationFilesDirty;
  }

  salesAgreementFilesDirty = false;
  get salesAgreementFilesValid(): boolean {
    return this.salesAgreementFiles.length > 0 && this.salesAgreementFilesDirty;
  }

  constructor(private _bond_application_storage: ApplicationStorageService, private _toast_service: ToastService, private _utility_service: UtilityService, private modalService: NgbModal, private _userService: UserService) { }

  ngOnInit(): void {
   
    this.activeUserSub = this._userService.currentUser$.subscribe(user => {
      this.activeUser = user;
       //clear application storage for this user if it was previously set before manual
       this._bond_application_storage.localSave(null, `${this.activeUser.email}/${this.token}`);
    });
    this.initForm();
  }

  ngOnDestroy() {
    if(this.unitTypeSub) {
      this.unitTypeSub.unsubscribe();
    }
    if(this.activeUserSub) {
      this.activeUserSub.unsubscribe();
    }
  }

  ngOnChanges(changes:SimpleChanges) {
    if(changes['application']) {
      if(this.application.unit) {
        this.selectedUnitType = this.application.unit;
        this.selectedUnitType.price_words = converter.toWords(this.selectedUnitType.price);
      }
      this.application.complete_by_date = moment(this.application.created_at).add(30, 'days').format("Do MMMM YYYY");
    }
  }

  initForm() {
    this.loading = true;
    this._bond_application_storage.formdata$(this.token).subscribe((response) => {
      this.unitTypes = response['data'].unit.unit_types;
      this.manualForm = new FormGroup({
        'unit_unit_type_id': new FormControl('', [Validators.required])
      });
      this.unitTypeSub = this.manualForm.get('unit_unit_type_id').valueChanges.subscribe(value => {
        for(let x = 0; x < this.unitTypes.length; x++) {
          if(value == this.unitTypes[x].pivot.id) {
            this.selectedUnitType = this.unitTypes[x].pivot;
            this.selectedUnitType.number = this.application.reserved_unit.number;
            this.selectedUnitType.price_words = converter.toWords(this.selectedUnitType.price);
            break;
          }
        }
      })
      this.loading = false;
    });
   
  }



  onSelect(event, array, touch) {
    this[touch] = true;
    array.push(...event.addedFiles);
  }
   
  onRemove(event, array) {
    array.splice(array.indexOf(event), 1);
  }

  submitApplication() {


    this._utility_service.markFormGroupTouched(this.manualForm);
    this.proofOfResFilesDirty = true;
    this.idDocFilesDirty = true;
    this.salarySlipFilesDirty = true;
    this.bankStatementFilesDirty = true;
    this.applicationFilesDirty = true;
    this.salesAgreementFilesDirty = true;

    if(this.manualForm.valid && this.idDocFilesValid && this.proofOfResFilesValid && this.salarySlipFilesValid && this.bankStatementFilesValid && this.applicationFilesValid && this.salesAgreementFilesValid) {

      this.loading = true;
      let data = this.manualForm.value;
      data['sa_id_document_manual'] = this.idDocFiles;
      data['proof_of_residence_manual'] = this.proofOfResFiles;
      data['salary_slips_manual'] = this.salarySlipFiles;
      data['bank_statements_manual'] = this.bankStatementFiles;
      data['application_manual'] = this.applicationFiles;
      data['sales_agreement_manual'] = this.salesAgreementFiles;
      let formData = this._bond_application_storage.objectToFormData(data);

      this._bond_application_storage.submitManualApplication$(this.token, formData).subscribe(response => {

        this.loading = false;
        this._toast_service.show(response['message'], { classname: 'bg-success text-light' });
        this.status.emit(response['data'].manual_application.status);

      }, error => {

        this.loading = false;
        this._toast_service.show(error.error.message, { classname: 'bg-danger text-light' });
      
      })
      
    }
  }

  toggleManualApplication(event) {
    event.preventDefault();
    this.loading = true;
    this._bond_application_storage.toggleManualApplication$(this.token, false).subscribe(response => {
      this.loading = false;
      this.application.is_manual = false;
    }, error => {

        this.loading = false;
        this._toast_service.show(error.error.message, { classname: 'bg-danger text-light' });
      
      });
  }

  open(event, content) {
    event.preventDefault();
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'xl'}).result.then((result) => {
    }, (reason) => {
    });
  }

}
