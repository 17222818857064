<div class="p-4" style="background-color: white;" *ngIf="bondApplicationValid">
        <div class="row">
            <div class="col-12 col-md-6" *ngFor="let finish of finishes; let i = index" >

                    <div *ngIf="finish.featured_image" class="d-flex justify-content-center text-center py-3">
                        <img [src]="finish.featured_image" class="img-thumbnail"/>
                    </div>

                    <h2 class="text-center">{{finish.name}}</h2><hr>

                    <div [innerHTML]="finish.description"></div>

                    <a *ngIf="schedule_url" class="btn btn-block btn-primary " target="_blank" [href]="schedule_url">
                        Download {{finish.name}}
                        <fa-icon [icon]="faDownload"></fa-icon>
                    </a>

                    <a *ngIf="!schedule_url" class="btn btn-block btn-primary disabled" target="_blank" href="#">
                        Download {{finish.name}} unavailable
                        <fa-icon [icon]="faDownload"></fa-icon>
                    </a>

            </div>
        </div>
</div>

<div *ngIf="!bondApplicationValid" class="alert alert-info mt-2">
    Please ensure you have selected your unit type from the drop down field under section A
</div>







