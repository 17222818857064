
<div class="table-responsive">

	<table class="table w-100">

		<tbody>
			<tr>
				<th scope="row" >Nett Income (Gross Income less Salary Deductions) </th>
				<td width="50%">
					<div class="form-group">
						<span class="form-control">{{ sum_of_values | currency  : "R " }}</span>
						<!-- [value]="sum_of_values" -->
					</div>
				</td>
			</tr>
		</tbody>

	</table>

</div>