import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { EchoService } from 'ngx-laravel-echo';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Token, TokenVar } from 'src/app/models/token';
import { User } from 'src/app/models/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  public currentUser$: BehaviorSubject<User>;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  private intendedUrl: ActivatedRouteSnapshot = null;

  /**
  * The constructor for the User Service
  * @param {HttpClient} http 
  * @param {Router} router 
  * @param {AuthenticationService} auth 
  */
  constructor(
    private http: HttpClient,
		private echo: EchoService,
    ) {
    this.currentUser$ = new BehaviorSubject<User>(this.getUserFromStorage());
  }

  /**
   * Handles API request to create a new users profile
   * @param {User} user - The user profile to be created.
   * @return {Obserable} - An Observable that can be subscribed to, to send the HTTP request
   */
  login$(auth: any) {
    return this.http.post(`${environment.api.base_url}/login`, auth, this.httpOptions)
      .pipe(
        map(res => {
          let data = res["data"];
          return new Token(data['token_type'], data['expires_at'], data['access_token'], data['user'],data['is_impersonation']);
        }),
        tap((token) => {
          this.currentUser$.next(token.getCurrentUser());
        })
      )
  }

  impersonate$(auth: any) {
    return this.http.post(`${environment.api.base_url}/impersonate-user`, auth, this.httpOptions)
      .pipe(
        map(res => {
          let data = res["data"];
          return new Token(data['token_type'], data['expires_at'], data['access_token'], data['user'],data['is_impersonation']);
        }),
        tap((token) => {
          this.currentUser$.next(token.getCurrentUser());
        })
      )
  }



  public isAuthed$(): Observable<boolean> {
    return this.currentUser$.pipe(map((user) => user !== null));
  }


  /**
  * Handles API request to create a new users profile
  * @param {} data - The user profile to be created.
  * @return {Obserable} - An Observable that can be subscribed to, to send the HTTP request
  */
  passwordUpdate$(data: any) {
    return this.http.put(`${environment.api.base_url}/user/update-password`, data, this.httpOptions)
  }


  /**
  * Handles API request to create a new users profile
  * @param {} data - The user profile to be created.
  * @return {Obserable} - An Observable that can be subscribed to, to send the HTTP request
  */
  passwordReset$(data: any) {
    return this.http.post(`${environment.api.base_url}/password/reset`, data, this.httpOptions)
  }

  /**
   * Handles API request to create a new users profile
   * @param {User} user - The user profile to be created.
   * @return {Obserable} - An Observable that can be subscribed to, to send the HTTP request
   */
  update$(data: any) {
    return this.http.put(`${environment.api.base_url}/user`, data, this.httpOptions)
  }

 

  /**
  * Handles API request to create a new users profile
  * @param {User} user - The user profile to be created.
  * @return {Obserable} - An Observable that can be subscribed to, to send the HTTP request
  */
  get$() {
    return this.http.get(`${environment.api.base_url}/user`, this.httpOptions)
  }


  /**
 * Handles API request to create a new users profile
 * @param {User} user - The user profile to be created.
 * @return {Obserable} - An Observable that can be subscribed to, to send the HTTP request
 */
  passwordEmail$(data: any) {
    return this.http.post(`${environment.api.base_url}/password/email`, data, this.httpOptions)
  }


  /**
 * Handles API request to create a new users profile
 * @param {User} user - The user profile to be created.
 * @return {Obserable} - An Observable that can be subscribed to, to send the HTTP request
 */
  logout$() {
    return this.http.post(`${environment.api.base_url}/logout`, {}, this.httpOptions)
      .pipe(
        tap(() => {
          localStorage.removeItem(TokenVar.token_type);
          localStorage.removeItem(TokenVar.expires_at);
          localStorage.removeItem(TokenVar.access_token);
          localStorage.removeItem(TokenVar.refresh_token);
          localStorage.removeItem(TokenVar.current_user);
          localStorage.removeItem(TokenVar.is_impersonation);
          this.echo.logout();
          this.currentUser$.next(null);
        })
      )
  }

  getUserFromStorage(): User {
    let user = null;
    if (localStorage.getItem(TokenVar.current_user)) {
      user = new User();
      user.fromJson(JSON.parse(localStorage.getItem(TokenVar.current_user)));
    }
    return user;
  }

  getTokenType(): string {
    return localStorage.getItem(TokenVar.token_type);
  }

  getToken(): string {
    return localStorage.getItem(TokenVar.access_token);
  }

  isImpersonation(): boolean {
    return JSON.parse(localStorage.getItem(TokenVar.is_impersonation));
  }

  setIntendedUrl(url: ActivatedRouteSnapshot): void {
    this.intendedUrl = url;
  }

  getIntendedUrl(): ActivatedRouteSnapshot {
    return this.intendedUrl;
  }

  echoLogin()
  {
    let user = this.getUserFromStorage();
    if(user)
    {
      return this.echo.login({Authorization: "Bearer "+this.getToken()},user.id);
    }
    return false;
  }

}
