<div class="row">
  <div class="col-12 pr-2 pl-2">
    <h3 class="">
      Personal Income
    </h3>
  </div>
</div>
<div class="table-responsive">

<table class="table table-striped" [formGroup]="monthly_gross_income">
    <thead>
       <tr>
          <th scope="col-md">Monthly Income</th>
          <th scope="col-md"></th>
       </tr>
    </thead>
    <tbody>
       <tr>
          <th scope="row">Gross Monthly Salary/Income</th>
          <td>
             <div class="form-group">
                <input currencyMask formControlName="gross_monthly_salary_income" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':monthly_gross_income.get('gross_monthly_salary_income').invalid && (monthly_gross_income.get('gross_monthly_salary_income').dirty || monthly_gross_income.get('gross_monthly_salary_income').touched) }" id="gross_monthly_salary_income-field">
                <app-invalid-feedback  class="invalid-feedback" name="Gross Monthly Salary/Income" [control]="monthly_gross_income.get('gross_monthly_salary_income')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
             </div>
          </td>
       </tr>
       <tr>
          <th scope="row">Commission</th>
          <td>
             <div class="form-group">
                <input currencyMask formControlName="commission" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':monthly_gross_income.get('commission').invalid && (monthly_gross_income.get('commission').dirty || monthly_gross_income.get('commission').touched) }" id="commission-field">
                <app-invalid-feedback  class="invalid-feedback" name="Commission" [control]="monthly_gross_income.get('commission')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
             </div>
          </td>
       </tr>
       <tr>
          <th scope="row">Overtime</th>
          <td>
             <div class="form-group">
                <input currencyMask formControlName="overtime" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':monthly_gross_income.get('overtime').invalid && (monthly_gross_income.get('overtime').dirty || monthly_gross_income.get('overtime').touched) }" id="overtime-field">
                <app-invalid-feedback  class="invalid-feedback" name="Overtime" [control]="monthly_gross_income.get('overtime')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
             </div>
          </td>
       </tr>
       <tr>
          <th scope="row">Maintenance</th>
          <td>
             <div class="form-group">
                <input currencyMask formControlName="maintenance" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':monthly_gross_income.get('maintenance').invalid && (monthly_gross_income.get('maintenance').dirty || monthly_gross_income.get('maintenance').touched) }" id="maintenance-field">
                <app-invalid-feedback  class="invalid-feedback" name="Maintenance" [control]="monthly_gross_income.get('maintenance')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
             </div>
          </td>
       </tr>
       <tr>
          <th scope="row">Rental Income</th>
          <td>
             <div class="form-group">
                <input currencyMask formControlName="rental_income" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':monthly_gross_income.get('rental_income').invalid && (monthly_gross_income.get('rental_income').dirty || monthly_gross_income.get('rental_income').touched) }" id="rental_income-field">
                <app-invalid-feedback  class="invalid-feedback" name="Rental Income" [control]="monthly_gross_income.get('rental_income')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
             </div>
          </td>
       </tr>
       <tr>
          <th scope="row">Pension</th>
          <td>
             <div class="form-group">
                <input currencyMask formControlName="pension" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':monthly_gross_income.get('pension').invalid && (monthly_gross_income.get('pension').dirty || monthly_gross_income.get('pension').touched) }" id="pension-field">
                <app-invalid-feedback  class="invalid-feedback" name="Pension" [control]="monthly_gross_income.get('pension')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
             </div>
          </td>
       </tr>
       <tr>
          <th scope="row">Other (Please specify)</th>
          <td>
             <div class="form-group">
                <input currencyMask formControlName="other" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':monthly_gross_income.get('other').invalid && (monthly_gross_income.get('other').dirty || monthly_gross_income.get('other').touched) }" id="other-field">
                <app-invalid-feedback  class="invalid-feedback" name="Other" [control]="monthly_gross_income.get('other')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
             </div>
             <div class="form-group">
               <input formControlName="other_specification" type="input" class="form-control" [ngClass]="{'is-invalid':monthly_gross_income.get('other_specification').invalid && (monthly_gross_income.get('other_specification').dirty || monthly_gross_income.get('other_specification').touched) }" id="other_specification-field" placeholder="Please Specify Other">
               <app-invalid-feedback  class="invalid-feedback" name="Please Specify" [control]="monthly_gross_income.get('other_specification')"></app-invalid-feedback>
            </div>
          </td>
       </tr>
       <tr>
         <th scope="row">Total</th>
         <td>
            <div class="form-group">
               <span class="text-right">
                  {{ monthly_gross_income_total | currency:'R'}}
               </span>
            </div>
         </td>
      </tr>
    </tbody>
 </table>
 </div>