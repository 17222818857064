<div class="container finish-background">
    <div class="card" *ngIf="loading">
        <div class="card-body">
            <div [class]="{'h-150px':loading}" [appLoader]="loading">

            </div>
        </div>
    </div>
  <ng-container *ngIf="!loading && additionCategories.length > 0">
    <div class="additions-floor-plan-preview">

    </div>
    
    <div class="additions-list py-3">

      <div  *ngIf="application.has_expired" class="alert alert-danger" role="alert">
     The development is closed for extras selection, please contact your agent for further information.
      </div>

      <form [formGroup]="additionsForm">
          <ngb-accordion #a="ngbAccordion">
              <ngb-panel id="panel-{{category.id}}" *ngFor="let category of additionCategories" formGroupName="{{category.id}}">
                  <ng-template ngbPanelHeader let-opened="opened">
                  <div class="d-flex align-items-center justify-content-between">
                     
                      <button ngbPanelToggle class="btn btn-link accordion-btn container-fluid">
                          <h5 class="m-0 float-left">{{category.name}}</h5>
                          <fa-icon class="float-right" [icon]="opened?iconDown:iconRight"></fa-icon>
                        </button>
                  </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                      <div class="addition row py-3 d-none d-md-flex" >
                          <div class="col-12 col-md-6">ITEM</div>
                          <div class="col-12 col-md-3">PRICE</div>
                          <div class="col-12 col-md-2">PICTURE</div>
                          <div class="col-12 col-md-1"></div>
                       </div>
                      <div class="addition variation row py-3" *ngFor="let addition of category.additions">
                         <div class="col-12 col-md-6 vertical-container">
                             <p class="vertical-text">{{addition.name}}</p>
                         </div>
                         <div class="col-12 col-md-3 vertical-container" >
                             <p>{{addition.purchasable_price | currency:'R' }}</p>
                         </div>
                         <ng-container *ngIf="!addition.has_variations && !displayOnly()">
                            <div class="col-12 col-md-2 vertical-container horizontal addition-image" [ngStyle]="{'background-image': 'url(' + addition['featured_image'] + ')'}">
                                
                            </div>
                            <div class="col-12 col-md-1 vertical-container horizontal">
                                <input type="checkbox" id="addition-{{addition.id}}" formControlName="{{addition.id}}" *ngIf="addition.is_available && !addition.disabled">
                                <fa-icon [icon]="checkCircle" class="fa-2x fa-icon d-block m-auto" *ngIf="addition.disabled" placement="top" ngbTooltip="We have already generated you an invoice with this extra"></fa-icon>
                                <fa-icon [icon]="timesCircle" class="fa-2x fa-icon d-block m-auto" *ngIf="!addition.is_available && !addition.disabled" placement="top" ngbTooltip="This addition is no longer available"></fa-icon>
                            </div>
                         </ng-container>
                         <ng-container *ngIf="addition.has_variations  && !displayOnly()">
                            <div class="col-12 col-md-3">
                                <button class="btn btn-primary float-right" (click)="setValue(additionsForm['controls'][category.id]['controls'][addition.id], 0)" *ngIf="additionsForm['controls'][category.id]['controls'][addition.id]['value'] > 0">
                                    Clear
                                </button>
                            </div>
                         </ng-container>
                         <div class="col-12 variations-col" *ngIf="addition.has_variations">
                            <div class="row variation no-gutters py-3 px-3" *ngFor="let variation of addition.variations">
                                <div class="col-12 col-md-6 vertical-container">
                                   <p>
                                    - {{variation.name}}
                                   </p>
                                </div>
                                <div class="col-12 col-md-3 vertical-container">
                                    <p>
                                        {{variation.purchasable_price}}
                                    </p>
                                </div>
                                <div class="col-12 col-md-2 vertical-container horizontal addition-image" [ngStyle]="{'background-image': 'url(' + variation['featured_image'] + ')'}">
                                    
                                </div>
                                <div *ngIf="!displayOnly()" class="col-12 col-md-1 vertical-container horizontal">
                                    <input type="radio" name="{{addition.id}}" id="variation-{{variation.id}}" [value]="variation.id" formControlName="{{addition.id}}" *ngIf="!addition.disabled && addition.is_available">
                                    <fa-icon [icon]="checkCircle" class="fa-2x fa-icon d-block m-auto" *ngIf="addition.disabled && variation.disabled" placement="top" ngbTooltip="We have already generated you an invoice with this extra"></fa-icon>
                                    <fa-icon [icon]="timesCircle" class="fa-2x fa-icon d-block m-auto" *ngIf="!addition.is_available && !addition.disabled && !variation.disabled" placement="top" ngbTooltip="This addition is no longer available"></fa-icon>
                                </div>
                            </div>
                         </div>
                      </div>
                  </ng-template>
              </ngb-panel>
          </ngb-accordion>
      </form>
    </div>  
    <div *ngIf="!displayOnly()">
    <div  *ngIf="!application.has_expired" class="py-1 text-right">
        Total Cost of Selected Extras
     </div>
    <div  *ngIf="!application.has_expired" class="py-3 text-right cost-amount">
       {{total | currency:'R'}}
    </div>
    <div  *ngIf="!application.has_expired" class="py-3 row flex-row-reverse">
        <div class="col-12">

            <div class="form-check mb-4 text-right">

              <input type="checkbox" class="form-check-input" name="checkValid" id="checkValid" [(ngModel)]="checkVaild" [ngModelOptions]="{standalone: true}" />
              <label class="form-check-label" for="checkValid">
                I accept the <a target="_blank" [routerLink]="'/terms-and-conditions'" routerLinkActive="active"><strong>Terms and Conditions</strong></a> of the extras purchase process.
              </label>

            </div>
            
        </div>
        <div class="col-12 col-md-3" *ngIf="hasExpired('extras_subscription')">

            <button type="submit" [disabled]="!checkVaild" class="btn btn-primary w-100" (click)="purchase('extras_subscription')">
                Pay via payment plan
            </button>

            <div class="w-100 text-center sub-text">
                <a href="#" (click)="open($event, content)">Click here</a> for more info on what a payment plan entails
             </div>

        </div>
        <div class="col-12 col-md-3" *ngIf="hasExpired('extras_onceoff')">
            
            <button type="submit" [disabled]="!checkVaild" class="btn btn-primary w-100" (click)="purchase('extras_onceoff')">
                Pay Now
            </button>

            <div class="w-100 text-center sub-text">
                (5% discount)
             </div>

        </div>
    </div>
  </div>
  </ng-container>
  <ng-container *ngIf="!loading && additionCategories.length == 0">
    <div class="row">
        <div class="col-12 py-5">Please contact Craft Homes on <a href="tel:0860027238">086 002 7238</a> for special requests with regards to extras.</div>
    </div>
  </ng-container>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Payment Plan Details</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
       <div class="info-header">
         Below are the details on what your payment plan would look like, with the amount due each month until the due date:
       </div>
      <div class="manual-form-heading my-4">
        <span class="info-label">Total Due:</span> {{total | currency:'R'}}
      </div>
      <div class="manual-form-heading my-4">
        <span class="info-label">Total Amount Due By:</span> {{paymentPlanDueDate}}
      </div>
      <div class="manual-form-heading mt-4 mb-5">
        <span class="info-label">Total number of payments due:</span> {{application.months_till_expiry}}
      </div>
      <div class="manual-form-heading my-4" *ngFor="let month of paymentPlanMonths">
        <span class="info-label">Due end of {{month}}:</span> {{total/application.months_till_expiry | currency:'R'}}
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Button click')">Close</button>
    </div>
  </ng-template>