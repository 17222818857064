import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import options from 'src/app/data/options.json'

@Component({
  selector: 'app-financial-details',
  templateUrl: './financial-details.component.html',
  styleUrls: ['./financial-details.component.scss']
})
export class FinancialDetailsComponent implements OnInit {
  
  financial_details:FormGroup;
  options = options;
  @Input() bondApplication: FormGroup;
@Input() index: number;

  constructor(private fb: FormBuilder,
    //  private _validation_service: ValidationService
  ) { 
  }

  ngOnInit(): void {
    this.AddToForm();
  }

  get registerFormControl() {
    return this.financial_details.controls;
  }

  AddToForm(): void{
    this.financial_details = this.fb.group({
        bank_name:['',[Validators.required]],
        bank_account_number:['',[Validators.required]],
        account_type:['',[Validators.required]],
        branch_name:['',[Validators.required]],
        branch_code:['',[]],
        bank_account_holder_fullname:['',[Validators.required]],
        existing_loan_be_cancelled:['',[Validators.required]],
        property_purchase_reason:['',[Validators.required]]
    });
    this.bondApplication.addControl('financial_details',this.financial_details);
  }

}
