<div class="card craft-card bg-light" *ngIf="application">
    <div class="card-body mt-4 mb-4" [appLoader]="applicationDataLoading">
        <div class="row justify-content-center">
            <div class="col-lg-5 col-12" href="#">
                <a class="card h-100"  (click)="payDeposit($event)">
                    <div class="card-body payment-card">
                        <div class="row">
                            <fa-icon [icon]="cashHand" class="fa-3x fa-icon d-block m-auto"></fa-icon>
                        </div>
                        <p class="text-center">
                            Pay a R5000 refundable reservation deposit <br>
                            This amount will be deducted from the purchase price
                        </p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>


