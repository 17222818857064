import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { ApplicationStorageService } from '../../services/application-storage.service';

import { faHandPointDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-cash-form',
  templateUrl: './cash-form.component.html',
  styleUrls: ['./cash-form.component.scss']
})
export class CashFormComponent implements OnInit {

  @Input() application;
  @Input() token;

  @Output() status = new EventEmitter<{}>();
  faHandPointDown = faHandPointDown;


  idDocFiles = [];
  proofOfResFiles = [];

  unitTypes;

  cashForm: FormGroup;

  loading:boolean;

  checkCircle = faCheckCircle;

  proofOfResFilesDirty = false;
  get proofOfResFilesValid(): boolean {
    return this.proofOfResFiles.length > 0 && this.proofOfResFilesDirty;
  }

  idDocFilesDirty = false;
  get idDocFilesValid(): boolean {
    return this.idDocFiles.length > 0 && this.idDocFilesDirty;
  }

  constructor(private _bond_application_storage: ApplicationStorageService, private _toast_service: ToastService, private _utility_service: UtilityService) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.loading = true;
    this._bond_application_storage.formdata$(this.token).subscribe((response) => {
      this.unitTypes = response['data'].unit.unit_types;
      this.cashForm = new FormGroup({
        'unit_unit_type_id': new FormControl('', [Validators.required]),
        'email': new FormControl(this.application.user ? this.application.user.email : '', [Validators.required, Validators.email]),
        'tax_number': new FormControl('', [Validators.required])
      });
      this.loading = false;
    });
   
  }

  onSelect(event, array, touch) {
    this[touch] = true;
    array.push(...event.addedFiles);
  }
   
  onRemove(event, array) {
    array.splice(array.indexOf(event), 1);
  }

  submitFica() {


    this._utility_service.markFormGroupTouched(this.cashForm);
    this.proofOfResFilesDirty = true;
    this.idDocFilesDirty = true;

    if(this.cashForm.valid && this.idDocFilesValid && this.proofOfResFilesValid ) {

      this.loading = true;
      let data = this.cashForm.value;
      data['sa_id_document_fica'] = this.idDocFiles;
      data['proof_of_residence_fica'] = this.proofOfResFiles;
      let formData = this._bond_application_storage.objectToFormData(data);

      this._bond_application_storage.submitFicaDocuments$(this.token, formData).subscribe(response => {

        this.loading = false;
        this._toast_service.show(response['message'], { classname: 'bg-success text-light' });
        this.status.emit(response['data'].cash_application.status);

      }, error => {

        this.loading = false;
        this._toast_service.show(error.error.message, { classname: 'bg-danger text-light' });
      
      })
      
    }
  }

}
