import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvalidFeedbackComponent } from './components/invalid-feedback/invalid-feedback.component';
import { LoaderDirective } from './directives/loader.directive';
import { NgbModule }  from '@ng-bootstrap/ng-bootstrap';
import { DepedencyChildDirective } from './directives/depedency-child.directive';
import { ToastsMessageComponent } from './components/toasts-message/toasts-message.component';
import { FileFieldComponent } from './components/file-field/file-field.component';


@NgModule({
  declarations: [
    InvalidFeedbackComponent,
     LoaderDirective, 
     DepedencyChildDirective, 
     ToastsMessageComponent, 
     FileFieldComponent
    ],
  imports: [
    CommonModule,
    NgbModule
  ],
  exports: [
    LoaderDirective,
    DepedencyChildDirective,
    InvalidFeedbackComponent,
    ToastsMessageComponent,
    FileFieldComponent
  ]
})
export class SharedModule { }
