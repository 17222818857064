import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { UserService } from 'src/app/user/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-password-setup',
  templateUrl: './password-setup.component.html',
  styleUrls: ['./password-setup.component.scss']
})
export class PasswordSetupComponent implements OnInit, OnDestroy {


  passwordSetup: FormGroup;
  formLoading: Boolean;
  paramData: {
    token
  };
  subscriptions = {
    reset:null,
  };
  
  constructor(
    private _utility_service: UtilityService,
    private fb: FormBuilder,
    private _toast_service: ToastService,
    private _userService: UserService,
    private _validation_service: ValidationService,
    private _route: ActivatedRoute,
    private _router: Router

  ) { 
  }

  ngOnInit(): void {

    this._route.paramMap.subscribe(params => {
      this.paramData = {
        token:params.get('token'),
      }
      this.initForm();
    });

  }

  initForm()
  {
    this.passwordSetup = this.fb.group({
      token: [this.paramData.token, [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      password_confirmation: ['', [Validators.required]]
    });
  }

  onSubmit()
  {
    this._utility_service.markFormGroupTouched(this.passwordSetup);    
    if(!this.passwordSetup.invalid)
    {
        this.formLoading = true;
        this.subscriptions.reset =  this._userService.passwordReset$(this.passwordSetup.value).subscribe(data => {
          this.formLoading = false;
          this._toast_service.show(data['message'], { classname: 'bg-success text-light' });
          this._router.navigate(['/']);
        }, (res) => {
          this.formLoading = false;

          if (res.status == 422) {
            this._validation_service.setServerValidation(this.passwordSetup,res.error.errors);
          }
  
        });
    }else
    {
      this._toast_service.show("Please ensure that your input is valid", { classname: 'bg-danger text-light' });
    }
  }

  ngOnDestroy(): void {
    Object.keys(this.subscriptions).forEach(s=>{
      if(this.subscriptions[s] != null)
      {
        this.subscriptions[s].unsubscribe();
      }
    })
  }

}
