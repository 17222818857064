<div class="table-responsive">

<table class="table table-striped" [formGroup]="monthly_expenses">
    <thead>
       <tr>
          <th scope="col-md">Monthly Expenses</th>
          <th scope="col-md"></th>
       </tr>
    </thead>
    <tbody>
       <tr>
          <th scope="row">Groceries</th>
          <td>
             <div class="form-group">
                <input currencyMask formControlName="groceries" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':monthly_expenses.get('groceries').invalid && (monthly_expenses.get('groceries').dirty || monthly_expenses.get('groceries').touched) }" id="groceries-field">
                <app-invalid-feedback  class="invalid-feedback" name="Groceries" [control]="monthly_expenses.get('groceries')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
             </div>
          </td>
       </tr>
       <tr>
          <th scope="row">Rates, Taxes & Electricity</th>
          <td>
             <div class="form-group">
                <input currencyMask formControlName="rates_taxes_electricity" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':monthly_expenses.get('rates_taxes_electricity').invalid && (monthly_expenses.get('rates_taxes_electricity').dirty || monthly_expenses.get('rates_taxes_electricity').touched) }" id="rates_taxes_electricity-field">
                <app-invalid-feedback  class="invalid-feedback" name="Rates, taxes & electricty" [control]="monthly_expenses.get('rates_taxes_electricity')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
             </div>
          </td>
       </tr>
       <tr>
          <th scope="row">Domestic & Garden Services</th>
          <td>
             <div class="form-group">
                <input currencyMask formControlName="domestic_garden_service" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':monthly_expenses.get('domestic_garden_service').invalid && (monthly_expenses.get('domestic_garden_service').dirty || monthly_expenses.get('domestic_garden_service').touched) }" id="domestic_garden_service-field">
                <app-invalid-feedback  class="invalid-feedback" name="Domestic & Garden Services" [control]="monthly_expenses.get('domestic_garden_service')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
             </div>
          </td>
       </tr>
       <tr>
          <th scope="row">Telephone & Mobile Phone</th>
          <td>
             <div class="form-group">
                <input currencyMask formControlName="telephone_mobile_phone" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':monthly_expenses.get('telephone_mobile_phone').invalid && (monthly_expenses.get('telephone_mobile_phone').dirty || monthly_expenses.get('telephone_mobile_phone').touched) }" id="telephone_mobile_phone-field">
                <app-invalid-feedback  class="invalid-feedback" name="Telephone & Mobile Phone" [control]="monthly_expenses.get('telephone_mobile_phone')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
             </div>
          </td>
       </tr>
       <tr>
          <th scope="row">Fixed Investments</th>
          <td>
             <div class="form-group">
                <input currencyMask formControlName="fixed_investments" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':monthly_expenses.get('fixed_investments').invalid && (monthly_expenses.get('fixed_investments').dirty || monthly_expenses.get('fixed_investments').touched) }" id="fixed_investments-field">
                <app-invalid-feedback  class="invalid-feedback" name="Fixed investments" [control]="monthly_expenses.get('fixed_investments')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
             </div>
          </td>
       </tr>
       <tr>
          <th scope="row">Insurance Policies</th>
          <td>
             <div class="form-group">
                <input currencyMask formControlName="insurance_policies" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':monthly_expenses.get('insurance_policies').invalid && (monthly_expenses.get('insurance_policies').dirty || monthly_expenses.get('insurance_policies').touched) }" id="insurance_policies-field">
                <app-invalid-feedback  class="invalid-feedback" name="Insurance Policies" [control]="monthly_expenses.get('insurance_policies')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
             </div>
          </td>
       </tr>
       <tr>
          <th scope="row">Educational Fees</th>
          <td>
             <div class="form-group">
                <input currencyMask formControlName="educational_fees" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':monthly_expenses.get('educational_fees').invalid && (monthly_expenses.get('educational_fees').dirty || monthly_expenses.get('educational_fees').touched) }" id="educational_fees-field">
                <app-invalid-feedback  class="invalid-feedback" name="Educational Fees" [control]="monthly_expenses.get('educational_fees')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
             </div>
          </td>
       </tr>
       <tr>
          <th scope="row">Memberships/Subscriptions</th>
          <td>
             <div class="form-group">
                <input currencyMask formControlName="memberships_subscriptions" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':monthly_expenses.get('memberships_subscriptions').invalid && (monthly_expenses.get('memberships_subscriptions').dirty || monthly_expenses.get('memberships_subscriptions').touched) }" id="memberships_subscriptions-field">
                <app-invalid-feedback  class="invalid-feedback" name="Memberships/subscriptions" [control]="monthly_expenses.get('memberships_subscriptions')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
             </div>
          </td>
       </tr>
       <tr>
          <th scope="row">Transport/Petrol Cost</th>
          <td>
             <div class="form-group">
                <input currencyMask formControlName="transport_petrol_cost" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':monthly_expenses.get('transport_petrol_cost').invalid && (monthly_expenses.get('transport_petrol_cost').dirty || monthly_expenses.get('transport_petrol_cost').touched) }" id="transport_petrol_cost-field">
                <app-invalid-feedback  class="invalid-feedback" name="Transport/Petrol Cost" [control]="monthly_expenses.get('transport_petrol_cost')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
             </div>
          </td>
       </tr>
       <tr>
          <th scope="row">Entertainment</th>
          <td>
             <div class="form-group">
                <input currencyMask formControlName="entertainment" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':monthly_expenses.get('entertainment').invalid && (monthly_expenses.get('entertainment').dirty || monthly_expenses.get('entertainment').touched) }" id="entertainment-field">
                <app-invalid-feedback  class="invalid-feedback" name="Entertainment" [control]="monthly_expenses.get('entertainment')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
             </div>
          </td>
       </tr>
       <tr>
         <th scope="row">Security</th>
         <td>
            <div class="form-group">
               <input currencyMask formControlName="security" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':monthly_expenses.get('security').invalid && (monthly_expenses.get('security').dirty || monthly_expenses.get('security').touched) }" id="security-field">
               <app-invalid-feedback  class="invalid-feedback" name="security" [control]="monthly_expenses.get('security')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
            </div>
         </td>
      </tr>
       <tr>
          <th scope="row">Other (Please specify)</th>
          <td>
             <div class="form-group">
                <input currencyMask formControlName="other" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':monthly_expenses.get('other').invalid && (monthly_expenses.get('other').dirty || monthly_expenses.get('other').touched) }" id="other-field">
                <app-invalid-feedback  class="invalid-feedback" name="Other" [control]="monthly_expenses.get('other')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
             </div>
             <div class="form-group">
               <input formControlName="other_specification" type="input" class="form-control" [ngClass]="{'is-invalid':monthly_expenses.get('other_specification').invalid && (monthly_expenses.get('other_specification').dirty || monthly_expenses.get('other_specification').touched) }" id="other_specification-field" placeholder="Please Specify Other">
               <app-invalid-feedback  class="invalid-feedback" name="Please Specify" [control]="monthly_expenses.get('other_specification')"></app-invalid-feedback>
            </div>
          </td>
       </tr>
       <tr>
         <th scope="row">Total Expenses</th>
         <td>
            <div class="form-group">
               <span class="text-right">
                  {{ monthly_expenses_total | currency:'R'}}
               </span>
            </div>
         </td>
      </tr>
    </tbody>
 </table>
 </div>