import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import options from 'src/app/data/options.json'
import { DependecyFieldsService } from 'src/app/shared/services/dependecy-fields.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss']
})
export class ContactDetailsComponent implements OnInit,OnDestroy {

  contact_details: FormGroup;
  options = options;
  subscriptions: Array<Subscription> = []
  @Input() bondApplication: FormGroup;
  @Input() index: number;
  constructor(private fb: FormBuilder,
    private _dependecy_fields_service: DependecyFieldsService
  ) {
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    })
  }

  ngOnInit(): void {
    this.AddToForm();
  }

  get registerFormControl() {
    return this.contact_details.controls;
  }

  AddToForm(): void {
    this.contact_details = this.fb.group({
      residential_address: ['', [Validators.required]],
      postal_address: [''],
      mobile_telephone: ['', [Validators.required]],
      home_telephone: [''],
      work_telephone: [''],
      legal_notice_delivery_method: ['', [Validators.required]],
      legal_notice_delivery_address: [''],
      email_address: ['', [Validators.required, Validators.email]],
      home_language: ['', [Validators.required]],
      preferred_communication_channel: ['', [Validators.required]]
    });
    this.bondApplication.addControl('contact_details', this.contact_details);

    const home_language_prompters = this.options['home_language'].filter(option => option.prompt);
    const home_language_prompters_values = home_language_prompters.map(option => option.value);
    let dependecyFields = [
      {
        "field": "home_language",
        "values": home_language_prompters_values,
        "depedent_fields": [
          {
            "name": "other_home_language_specification",
            "control": this.fb.control('', [Validators.required])
          }
        ]
      }
    ];
    this.subscriptions.push(...
      this._dependecy_fields_service.setConditionalFields(this.contact_details, dependecyFields)
    )
  }

}
