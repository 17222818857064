import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';

import {
  FormArray,
  FormControl,
  FormGroup
} from '@angular/forms';

import {
  UnitTypeService
} from 'src/app/application/services/unit-type.service';

import {
  ToastService
} from 'src/app/shared/services/toast.service';

import {
  DOCUMENT
} from '@angular/common';

import {
  Subscription
} from 'rxjs';
import moment from 'moment';
import {
  faChevronDown,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import {
  faCheckCircle
} from '@fortawesome/free-regular-svg-icons';
import {
  faTimesCircle
} from '@fortawesome/free-regular-svg-icons';
import {
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-addition-selection',
  templateUrl: './addition-selection.component.html',
  styleUrls: ['./addition-selection.component.scss']
})
export class AdditionSelectionComponent implements OnInit, OnDestroy {

  @Input() application;
  @Input() token;

  loading: boolean;
  disableSelection: boolean;
  total: number;

  checkVaild = false;
  checkCircle = faCheckCircle;
  timesCircle = faTimesCircle;

  additionCategories: Array < {} > ;
  additionsForm: FormGroup;
  updateSub: Subscription;
  purchasedAdditions: Array < {} > ;

  paymentPlanMonths: Array < String > ;
  paymentPlanDueDate:String;

  iconDown = faChevronDown;
  iconRight = faChevronRight;

  get availablePaymentOptions(): Array < string > {
    return this.application.available_payment_methods.map(payment_method => payment_method.code);
  }

  constructor(private _unit_type_service: UnitTypeService, private _toast_service: ToastService, @Inject(DOCUMENT) private _document: Document, private _modalService: NgbModal) {}

  ngOnInit(): void {
    this.loadAdditions();
    this.generatePaymentPlanInfo();
  }

  ngOnDestroy(): void {
    if (this.updateSub) {
      this.updateSub.unsubscribe();
    }
  }

  initForm() {
    let form = {};
    this.total = 0;
    for (let x = 0; x < this.additionCategories.length; x++) {
      let addition_category = {};
      for (let y = 0; y < this.additionCategories[x]['additions'].length; y++) {
        if (this.additionCategories[x]['additions'][y].has_variations) {
          addition_category[this.additionCategories[x]['additions'][y].id] = new FormControl(0);
        } else {
          addition_category[this.additionCategories[x]['additions'][y].id] = new FormControl(false);
        }
      }
      form[this.additionCategories[x]['id']] = new FormGroup(addition_category);
    }
    this.additionsForm = new FormGroup(form);
    this.updateSub = this.additionsForm.valueChanges.subscribe(update => {
      this.calcTotal();
    })
  }

  loadAdditions() {
    this.loading = true;
    this._unit_type_service.getPurchasedAdditions$(this.token).subscribe((response) => {
      this.purchasedAdditions = response['data'];
      this._unit_type_service.getAdditions$(this.application['unit'].unit_type_id).subscribe(response => {
        this.additionCategories = response['data'];
        this.checkPurchased();
        this.initForm();
        this.loading = false;
      }, error => {
        this.loading = false;
        this._toast_service.show(error.error.message, {
          classname: 'bg-danger text-light'
        });
      })
    });
  }
  translatePurchase(formValue) {
    let purchasableArray = [];
    for (let x = 0; x < this.additionCategories.length; x++) {
      for (let y = 0; y < this.additionCategories[x]['additions'].length; y++) {
        let controlValue = formValue[this.additionCategories[x]['id']][this.additionCategories[x]['additions'][y]['id']];
        if (typeof controlValue == 'number') {
          for (let z = 0; z < this.additionCategories[x]['additions'][y]['variations'].length; z++) {
            if (this.additionCategories[x]['additions'][y]['variations'][z].id == controlValue) {
              purchasableArray.push(this.additionCategories[x]['additions'][y]['variations'][z]['purchasable_entity']);
              break;
            }
          }

        } else if (controlValue) {
          purchasableArray.push(this.additionCategories[x]['additions'][y]['purchasable_entity']);
        }
      }
    }
    return purchasableArray;
  }
  setValue(control: FormControl, value) {
    control.setValue(value);
  }
  purchase(purchaseType) {
    let postArray = this.translatePurchase(this.additionsForm.value);
    if (postArray.length > 0) {
      this.loading = true;
      this._unit_type_service.purchaseAdditions$(postArray, purchaseType, this.application['unit'].unit_type_id, this.token).subscribe(response => {
          this._toast_service.show("Redirecting to payment", {
            classname: 'bg-success text-light'
          });
          this._document.location.href = response['data'].submission_url;
        },
        error => {
          this.loading = false;
          this._toast_service.show(error.error.message, {
            classname: 'bg-danger text-light'
          });
        })
    };
  }

  calcTotal() {
    let total = 0;
    let formValue = this.additionsForm.value;
    for (let x = 0; x < this.additionCategories.length; x++) {
      for (let y = 0; y < this.additionCategories[x]['additions'].length; y++) {
        let controlValue = formValue[this.additionCategories[x]['id']][this.additionCategories[x]['additions'][y]['id']];
        if (typeof controlValue == 'number' && controlValue > 0) {
          total += this.additionCategories[x]['additions'][y]['purchasable_price'];
        } else if (controlValue) {
          total += this.additionCategories[x]['additions'][y]['purchasable_price'];
        }
      }
    }
    this.total = total;
  }

  checkPurchased() {
    for (let x = 0; x < this.additionCategories.length; x++) {
      for (let y = 0; y < this.additionCategories[x]['additions'].length; y++) {
        if (this.additionCategories[x]['additions'][y]['has_variations']) {
          for (let z = 0; z < this.additionCategories[x]['additions'][y]['variations'].length; z++) {
            if (this.hasPurchased(this.additionCategories[x]['additions'][y]['variations'][z])) {
              this.additionCategories[x]['additions'][y]['disabled'] = true;
              this.additionCategories[x]['additions'][y]['variations'][z]['disabled'] = true;
              break;
            }
          }
        } else {
          if (this.hasPurchased(this.additionCategories[x]['additions'][y])) {
            this.additionCategories[x]['additions'][y]['disabled'] = true;
          }
        }

      }
    }
  }



  hasPurchased(item) {
    let item_entity = Object.keys(item['purchasable_entity'])[0];
    for (let x = 0; x < this.purchasedAdditions.length; x++) {
      if (item['id'] == this.purchasedAdditions[x]['purchasable_id'] && item_entity == this.purchasedAdditions[x]['purchasable_type']) {
        return true
      }
    }
    return false;
  }

  generatePaymentPlanInfo() {
    this.paymentPlanMonths = [];
    let now = moment();
    for (let x = 0; x < this.application.months_till_expiry; x++) {
      this.paymentPlanMonths.push(now.format('MMMM YYYY'));
      if(x == (this.application.months_till_expiry - 1)) {
        this.paymentPlanDueDate = now.clone().endOf('month').format('DD MMMM YYYY');
      }
      now.add(1, 'month');
    }
  }

  open(event, content) {
    event.preventDefault();
    this._modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl'
    }).result.then((result) => {}, (reason) => {});
  }

  hasExpired(payment_code): boolean {

    if (!this.availablePaymentOptions.includes(payment_code)) {
      console.warn(`${payment_code} not available`);
      return false;
    }

    let payment_type = this.application.available_payment_methods.find(payment => payment.code == payment_code);
    let development = this.application.development;
    let calculated_clode_date = moment();

    if (typeof payment_type == 'undefined') {
      console.warn(`${payment_code} not found`);
      return false;
    }

    let close_date_offset = Number(payment_type.meta_pairs.payment_expiry_offset);
    let close_date = moment(development.meta_pairs.due_date);

    if (isNaN(close_date_offset)) {
      console.warn(`${payment_code} offset is not a number`)
      return false;
    }

    if (close_date_offset == 0) {
      return true
    }

    if (close_date_offset < 0) {
      calculated_clode_date = close_date.subtract(Math.abs(close_date_offset), 'hours');
    }

    if (close_date_offset > 0) {
      calculated_clode_date = close_date.add(close_date_offset, 'hours');
    }

    if (moment().isSameOrBefore(calculated_clode_date)) {
      return true;
    }

    return false

  }

  displayOnly(): boolean{
    // "sbmtd" = Submitted
  return this.application.status.code === "sbmtd" ? true : false;
}

}
