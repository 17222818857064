import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-finishes',
  templateUrl: './finishes.component.html',
  styleUrls: ['./finishes.component.scss']
})
export class FinishesComponent implements OnInit {

  @Input() bondApplication: any;
  @Input() formData: any;
  finishes:Array<any>;
  faDownload = faDownload;
  schedule_url = <any>false;


  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.onChanges();
  
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(changes.formData)
    {

      let unitFormControl = (this.bondApplication.get('application_type.unit_type_id') as FormControl);
      if(unitFormControl)
      {
          
          this.setFinishes(unitFormControl.value,changes.formData.currentValue)


      }else
      {
          this.setFinishes(null,changes.formData.currentValue)
      }
    }

  }

  onChanges()
  {
     (this.bondApplication.get('application_type.unit_type_id') as FormControl).valueChanges.subscribe((nv)=>{
      this.setFinishes(nv,this.formData);
     });
  }


  setFinishes(unit_type_id,data)
  {
    this.finishes = [];
    if(data["unit"] && data["unit"]["unit_types"])
    {

      let selected_unit_type = data["unit"]["unit_types"].find((unit_type)=>{
        return unit_type_id == unit_type.id;
      });

      if(selected_unit_type)
      {

        this.finishes =  selected_unit_type["finishes"];
        this.schedule_url = data['development']['finish_schedule_url'];
       
      }
    }
  }


  get bondApplicationValid()
  {
    return (this.bondApplication.get('application_type.unit_type_id') as FormControl).value;
  }

}
