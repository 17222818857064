import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainFormComponent } from './application/components/main-form/main-form.component';
import { ChangePasswordComponent } from './user/components/change-password/change-password.component';
import { ForgotPasswordComponent } from './user/components/forgot-password/forgot-password.component';
import { LoginComponent } from './user/components/login/login.component';
import { ProfileComponent } from './user/components/profile/profile.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from './core/gaurds/auth.guard';
import { ThankYouComponent } from './application/components/thank-you/thank-you.component';
import { ApplicationListComponent } from './application/components/application-list/application-list.component';
import { ApplicationDashboardComponent } from './application/components/application-dashboard/application-dashboard.component';
import { PayDepositComponent } from './application/components/pay-deposit/pay-deposit.component';
import { PaymentCompleteComponent } from './application/components/payment-complete/payment-complete.component';
import { ApplicationTypeSelectComponent } from './application/components/application-type-select/application-type-select.component';
import { PasswordSetupComponent } from './user/components/password-setup/password-setup.component';
import { TermsAndConditionsComponent } from './application/components/terms-and-conditions/terms-and-conditions.component';


const routes: Routes = [
  { path: '', component: ApplicationListComponent, pathMatch: 'full', canActivate: [AuthGuard], data: { authed: true, notify: false } },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard], data: { authed: false } },
  { path: 'impersonate-login/:id', component: LoginComponent, canActivate: [AuthGuard], data: { authed: false } },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [AuthGuard], data: { authed: false } },
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard], data: { authed: true } },
  { path: 'my-profile', component: ProfileComponent, canActivate: [AuthGuard], data: { authed: true } },
  { path: 'password-setup/:token', component: PasswordSetupComponent },
  { path: 'api/password/reset/:email/:token', component: ForgotPasswordComponent },//look to removing api part

  { path: 'application/thank-you/:token', component: ThankYouComponent },

  { path: 'payment-complete/status/:status/invoice/:id', component: PaymentCompleteComponent },
  { path: 'application/pay-deposit/:token', component: PayDepositComponent, canActivate: [AuthGuard], data: { authed: true } },
  { path: 'application/select-type/:token', component: ApplicationTypeSelectComponent, canActivate: [AuthGuard], data: { authed: true } },
  { path: 'application/:token', component: ApplicationDashboardComponent, canActivate: [AuthGuard], data: { authed: true } },

  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },

  //leave development as a profix for backward support
  { path: ':development/bond-application/:token', component: MainFormComponent },
  { path: '**', component: NotFoundComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
