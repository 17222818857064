import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EchoService } from 'ngx-laravel-echo';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/user/services/user.service';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ReportingService {

	/**
	 * visitor counter channel
	 * @var EchoService:PresenceChannel
	 */
	private visitor_counter_channel = null;

	/**
	 * Visitor
	 * @var Subject
	 */
	public visitors$ = new Subject<number>();

	/**
	 * Visitor
	 * @var Subject
	 */
	public visitors:number;

	httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/json' })
	};

	constructor(
		private echo: EchoService,
		private http: HttpClient,
		private _user_service: UserService,
	) {
	}

	public join(development_id = 'visitors'){

		let visitor_counter_channel = this.echo.join(`public.development.reporting.${development_id}`,'presence')
		.listen(`public.development.reporting.${development_id}`,".App\\Events\\Reporting\\VisitorPresenceEvent")
		
		this.echo.users(`public.development.reporting.${development_id}`).subscribe( res => {
			this.visitors = res.length;
			this.visitors$.next(this.visitors);
		});

		this.echo.joining(`public.development.reporting.${development_id}`).subscribe( res => {
			this.visitors$.next(this.visitors += 1);
		});

		this.echo.leaving(`public.development.reporting.${development_id}`).subscribe( res => {
			this.visitors$.next(this.visitors -= 1);
		});

		return visitor_counter_channel;

	}



}
