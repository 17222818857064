import { Directive, SimpleChanges, Renderer2, ElementRef, Input, HostBinding, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { LoaderService } from '../services/loader.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appLoader]'
})
export class LoaderDirective implements OnChanges,AfterViewInit,OnDestroy {

  
  loading:boolean;
  spinner_classes:string[];
  element_classes:string[];
  overlay_classes:string[];
  extra_classes:string[];
  btn_loader_classes: string[];
  subscriptions:Subscription[];
  private el: ElementRef;
  isButtonType: boolean;
  @Input() appLoader:boolean | undefined | ''; 
  @Input() disabled:boolean | undefined | '';
  @HostBinding('disabled') get isDisbled()
  {
    if(this.isButtonType){
      return  (typeof this.disabled == "undefined" || this.disabled == false) && this.loading;    
    }
    return false;
  }

  constructor(private renderer:Renderer2,private elRef: ElementRef,private _loaderService:LoaderService) {

      this.subscriptions = [];
      this.spinner_classes = ['spinner-border'];
      this.btn_loader_classes = ['spinner-border-sm'];

      this.extra_classes = ["center-element","text-primary"];

      this.element_classes = ["center-parent"];
      this.overlay_classes = ["overlay"];
      

  }
  
  ngOnChanges(changes: SimpleChanges):void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case "appLoader":
              if(typeof changes.appLoader.currentValue !=  "undefined")
              {
                
                this.loading =  changes.appLoader.currentValue;
              
                  this.updateDom();
                
              }
            break;
        } 
      }
    }
  }
  
  //based on weather the input loader is set it will read using from the subscription or what has been passed
  ngAfterViewInit(): void {

    
    if(typeof this.appLoader != "boolean")//its set to an empty string so this bogus check is what works
    {
      this.subscriptions.push(this._loaderService.getStatus().subscribe((loading:boolean)=>{
        this.loading = loading;
        this.updateDom();
      }));
    }else
    {
      this.loading = this.appLoader;
      this.updateDom();
    }
  }

 

  updateDom():void{
    switch (this.elRef.nativeElement.type) {
      case "button":
      case "submit":
        this.isButtonType = true;
        this.updateButtonDom();
        break;
      default:
        this.isButtonType = false;
        this.updateDefaultDom();
        break;
    }

  }

  updateButtonDom():void{

    let spinner_selector = "."+this.spinner_classes.join('.');
    let child = this.elRef.nativeElement.querySelector(spinner_selector);

    if(this.loading)
    {
      if(!child)
      {
        let load = this.renderer.createElement('span');
        this.spinner_classes.forEach((l_class:string) => this.renderer.addClass(load, l_class));
        this.btn_loader_classes.forEach((btn_class:string) => this.renderer.addClass(load, btn_class));
        this.renderer.appendChild(this.elRef.nativeElement,load);
      }
     
    }else
    {
      if(this.elRef.nativeElement != null)
      {
        if(child)
        {
          this.renderer.removeChild(this.elRef.nativeElement,child);
        }
      }
    } 
  }

  updateDefaultDom()
  {
    let overlay_selector = "."+this.overlay_classes.join('.');
    let child = this.elRef.nativeElement.querySelector(overlay_selector);

    if(this.loading)
    {
      //check that there's no loader already added 
      if(!child)
      {
        let load = this.renderer.createElement('span');
        let overlay = this.renderer.createElement('div');
        this.renderer.appendChild(this.elRef.nativeElement,overlay);
        this.renderer.appendChild(overlay,load);
        this.element_classes.forEach((load_class:string) => this.renderer.addClass(this.elRef.nativeElement, load_class));
        this.spinner_classes.forEach((load_class:string) => this.renderer.addClass(load, load_class));
        this.extra_classes.forEach((extra_class:string) => this.renderer.addClass(load, extra_class)); 
        this.overlay_classes.forEach((o_class:string) => this.renderer.addClass(overlay, o_class)); 
      }
     
    }else
    {
      //remove the overlay by selecting it by the class and remove the extra classes on the parent element

      this.element_classes.forEach((load_class:string) => this.renderer.removeClass(this.elRef.nativeElement, load_class));

      if(child)
      {
         this.renderer.removeChild(this.elRef.nativeElement,child);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription)=>subscription.unsubscribe());
  }


}
