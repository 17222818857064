<div class="container">
    <h4 class="pt-5 pb-3">
        My Property Portfolio
    </h4>
    <hr>
    <p class="pt-3 pb-3">
        Click on your properties below to view more information.
    </p>
    <!-- min-vh-100 -->
    <div class="card craft-card bg-light" [class]="{'h-150px':applicationsLoading}" [appLoader]="applicationsLoading">
        <div class="card-body h-100">
            <ng-container *ngIf="applicationPagination && applicationPagination.last_page == 1">
               <div class="row justify-content-end mb-2 no-gutters">
                    <button class="btn btn-primary mr-2" (click)="displayMode='carousel'" [ngClass]="{'active': displayMode=='carousel'}" placement="top" ngbTooltip="Carousel View">
                        <fa-icon [icon]="playCircle"></fa-icon>
                    </button>
                    <button class="btn btn-primary" (click)="displayMode='grid'" [ngClass]="{'active': displayMode=='grid'}" placement="top" ngbTooltip="Grid View">
                        <fa-icon [icon]="thCircle"></fa-icon>
                    </button>   
               </div>
            </ng-container>
            <ng-container *ngIf="applicationPagination && applicationPagination.last_page == 1  && displayMode=='carousel'">
                <ngb-carousel  [keyboard]="true" [interval]="0" [animation]="true">
                    <ng-template ngbSlide *ngFor="let application of applicationPagination.data" >
                      <div class="picsum-img-wrapper" (click)="openApplication(application)" >
                        <div class="main-image" [ngStyle]="{'background-image': 'url(' + application.development?.featured_image_url + ')'}"></div>
                      </div>
                      <div class="carousel-caption" (click)="openApplication(application)" [ngClass]="{'active-application': application.status.code != 'susp', 'suspended-application': application.status.code == 'susp'}">
                        <h3>{{application.development.name}} {{ application.reserved_unit.number }}</h3>
                        <p class="font-weight-bold">{{ application.status.name }}</p>
                      </div>
                    </ng-template>
                </ngb-carousel>
            </ng-container>
            <ng-container *ngIf="applicationPagination && (applicationPagination.last_page != 1 || displayMode=='grid') ">
                <div class="row">

                    <div class="col-lg-4 col-md-6 col-sm-12 col-12 mb-2" *ngFor="let application of applicationPagination.data">
                        <a class="card rounded h-100" (click)="openApplication(application)" [ngClass]="{'active-application': application.status.code != 'susp', 'suspended-application': application.status.code == 'susp'}">
                            <div class="card-body">
                                <h5 class="card-title">{{ application.development.name }}</h5>
                                <img class="card-img-top mb-2 " [src]="application.development?.featured_image_url" alt="" [ngClass]="{'decorated-img': application.status.code != 'susp'}">
                                <p>
                                    Unit: {{ application.reserved_unit.number }}<br>
                                   Status: <strong> {{ application.status.name }} </strong>
                                </p>
                            </div>
                        </a>
                    </div>

                    <div *ngIf="applicationPagination && applicationPagination.last_page != 1" class="d-flex justify-content-center">
                        <ngb-pagination
                        (pageChange) = "onPageChange($event)"
                        [(page)]="applicationPagination.current_page"
                        [pageSize]="applicationPagination.per_page"
                        [collectionSize]="applicationPagination.total"></ngb-pagination>
                    </div>

                </div>
            </ng-container>
        </div>
    </div>

</div>