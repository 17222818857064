
    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" [destroyOnHide]="false" >
        <ngb-panel type="white" cardClass="background-card-form" [id]="index+'_section_a'">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between" [ngClass]="getSectionControlStatus('personal_details')">
              <button ngbPanelToggle [id]="'panel-'+index+'-section-a'" class="btn accordion-btn container-fluid text-left pl-2"><strong>SECTION A</strong> - Personal Details of Applicant<span class="float-right">
                <fa-icon [icon]="opened?iconDown:iconRight"></fa-icon>

            </span></button>
          
            
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="p-3">
         
              <app-personal-detail [index]="index" [bondApplication]="bondApplication" ></app-personal-detail>
              <app-contact-details [index]="index" [bondApplication]="bondApplication"></app-contact-details>

              <div class="row align-items-center mt-4">
                <div class="col-9 font-weight-bold">Please save before continuing to the next section</div>
                <div class="col-3 text-right"><button class="btn btn-outline-primary mt-2 mr-1" (click)="onPartialSave()" type="button">Save</button></div>
              </div>
              
            
            </div>
          </ng-template>
        </ngb-panel>
        <ngb-panel type="white" cardClass="background-card-form" [id]="index+'_section_b'">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between" [ngClass]="getSectionControlStatus('juristic_application')">
              <button ngbPanelToggle [id]="'panel-'+index+'-section-b'" class="btn accordion-btn container-fluid text-left pl-2"><strong>SECTION B</strong> - Juristic Application                <span class="float-right">
                <fa-icon [icon]="opened?iconDown:iconRight"></fa-icon>

            </span></button>
          
            
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="p-3">
         
              <app-juristic-applications [index]="index" [bondApplication]="bondApplication" ></app-juristic-applications>
              <div class="row align-items-center mt-4">
                <div class="col-9 font-weight-bold">Please save before continuing to the next section</div>
                <div class="col-3 text-right"><button class="btn btn-outline-primary mt-2 mr-1" (click)="onPartialSave()" type="button">Save</button></div>
              </div>
            
            </div>
          </ng-template>
        </ngb-panel>
        <ngb-panel type="white" cardClass="background-card-form"  [id]="index+'_section_c'">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between" [ngClass]="getSectionControlStatus('other_information')">
              <button ngbPanelToggle [id]="'panel-'+index+'-section-c'" class="btn accordion-btn container-fluid text-left pl-2"><strong>SECTION C</strong> - Other Information<span class="float-right">
             
              <fa-icon [icon]="opened?iconDown:iconRight"></fa-icon>
            
            </span></button>
          
            
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="p-3">
            <app-other-information [index]="index" [bondApplication]="bondApplication"></app-other-information>
            <div class="row align-items-center mt-4">
              <div class="col-9 font-weight-bold">Please save before continuing to the next section</div>
              <div class="col-3 text-right"><button class="btn btn-outline-primary mt-2 mr-1" (click)="onPartialSave()" type="button">Save</button></div>
            </div>
          </div>
          </ng-template>
        </ngb-panel>
         <ngb-panel type="white" cardClass="background-card-form"  [id]="index+'_section_d'">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between" [ngClass]="getSectionControlStatus('employment_information')">
              <button ngbPanelToggle [id]="'panel-'+index+'-section-d'" class="btn accordion-btn container-fluid text-left pl-2"><strong>SECTION D</strong> - Employment Information<span class="float-right">
             
              <fa-icon [icon]="opened?iconDown:iconRight"></fa-icon>
            
            </span></button>
          
            
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="p-3">
              <app-employment-information [index]="index" [bondApplication]="bondApplication"></app-employment-information>
              <div class="row align-items-center mt-4">
                <div class="col-9 font-weight-bold">Please save before continuing to the next section</div>
                <div class="col-3 text-right"><button class="btn btn-outline-primary mt-2 mr-1" (click)="onPartialSave()" type="button">Save</button></div>
              </div>
              </div>
          </ng-template>
        </ngb-panel>
         <ngb-panel type="white" cardClass="background-card-form"  [id]="index+'_section_e'">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between" [ngClass]="getSectionControlStatus('financial_details')">
              <button ngbPanelToggle [id]="'panel-'+index+'-section-e'" class="btn accordion-btn container-fluid text-left pl-2"><strong>SECTION E</strong> - Financial Details<span class="float-right">
             
              <fa-icon [icon]="opened?iconDown:iconRight"></fa-icon>
            
            </span></button>
          
            
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="p-3">
              
              <app-financial-details [index]="index" [bondApplication]="bondApplication"></app-financial-details>
              <app-monthly-gross-income [index]="index" [bondApplication]="bondApplication"></app-monthly-gross-income>
              <app-salary-deductions [index]="index" [bondApplication]="bondApplication"></app-salary-deductions>
              <app-sallery-deduction-calculation [index]="index" [bondApplication]="bondApplication"></app-sallery-deduction-calculation>
              <app-monthly-expenses [index]="index" [bondApplication]="bondApplication"></app-monthly-expenses>
              <app-fixed-debt-repayments [index]="index" [bondApplication]="bondApplication"></app-fixed-debt-repayments>
              <div class="row align-items-center mt-4">
                <div class="col-9 font-weight-bold">Please save before continuing to the next section</div>
                <div class="col-3 text-right"><button class="btn btn-outline-primary mt-2 mr-1" (click)="onPartialSave()" type="button">Save</button></div>
              </div>

            </div>
          </ng-template>
        </ngb-panel>
        <ngb-panel type="white" cardClass="background-card-form"  [id]="index+'_section_f'" *ngIf="index == 0">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between" [ngClass]="getSectionControlStatus('debit_order_details')">
              <button ngbPanelToggle [id]="'panel-'+index+'-section-f'" class="btn accordion-btn container-fluid text-left pl-2"><strong>SECTION F</strong> - Account Details for Debit Order<span class="float-right">
             
              <fa-icon [icon]="opened?iconDown:iconRight"></fa-icon>
            
            </span></button>
          
            
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="p-3">
              
              <app-debit-order-details [index]="index" [bondApplication]="bondApplication"></app-debit-order-details>
              <div class="row align-items-center mt-4">
                <div class="col-9 font-weight-bold">Please save before continuing to the next section</div>
                <div class="col-3 text-right"><button class="btn btn-outline-primary mt-2 mr-1" (click)="onPartialSave()" type="button">Save</button></div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>

         <ngb-panel type="white" cardClass="background-card-form"  [id]="index+'_section_g'" *ngIf="index == 0">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between" [ngClass]="getSectionControlStatus('loan_details')">
              <button ngbPanelToggle [id]="'panel-'+index+'-section-g'" class="btn accordion-btn container-fluid text-left pl-2"><strong>SECTION G</strong> - Details of Loan<span class="float-right">
             
              <fa-icon [icon]="opened?iconDown:iconRight"></fa-icon>
            
            </span></button>
          
            
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="p-3">

              <app-loan-details [index]="index" [bondApplication]="bondApplication" [formData]="formData"></app-loan-details>
              <div class="row align-items-center mt-4">
                <div class="col-9 font-weight-bold">Please save before continuing to the next section</div>
                <div class="col-3 text-right"><button class="btn btn-outline-primary mt-2 mr-1" (click)="onPartialSave()" type="button">Save</button></div>
              </div>
            </div>

          </ng-template>
        </ngb-panel>
        <ngb-panel type="white" cardClass="background-card-form"  [id]="index+'_section_h'" *ngIf="index == 0">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between" [ngClass]="getSectionControlStatus('property_details')">
              <button ngbPanelToggle [id]="'panel-'+index+'-section-h'" class="btn accordion-btn container-fluid text-left pl-2"><strong>SECTION H</strong> - Property Details<span class="float-right">
             
              <fa-icon [icon]="opened?iconDown:iconRight"></fa-icon>
            
            </span></button>
          
            
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="p-3">
              
              <app-property-details [index]="index" [bondApplication]="bondApplication" [formData]="formData"></app-property-details>
              <div class="row align-items-center mt-4">
                <div class="col-9 font-weight-bold">Please save before continuing to the next section</div>
                <div class="col-3 text-right"><button class="btn btn-outline-primary mt-2 mr-1" (click)="onPartialSave()" type="button">Save</button></div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
         <ngb-panel type="white" cardClass="background-card-form"  [id]="index+'_section_i'">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between" [ngClass]="getSectionControlStatus('prominent_influential_persons')">
              <button ngbPanelToggle [id]="'panel-'+index+'-section-i'" class="btn accordion-btn container-fluid text-left pl-2"><strong>SECTION I</strong> - Prominent Influential Persons (PIP)<span class="float-right">
             
              <fa-icon [icon]="opened?iconDown:iconRight"></fa-icon>
            
            </span></button>
          
            
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="p-3">
              
              <app-prominent-influential-persons [index]="index" [bondApplication]="bondApplication"></app-prominent-influential-persons>
              <div class="row align-items-center mt-4">
                <div class="col-9 font-weight-bold">Please save before continuing to the next section</div>
                <div class="col-3 text-right"><button class="btn btn-outline-primary mt-2 mr-1" (click)="onPartialSave()" type="button">Save</button></div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
        <ngb-panel type="white" cardClass="background-card-form"  [id]="index+'_section_j'">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between" [ngClass]="getSectionControlStatus('declaration')">
              <button ngbPanelToggle [id]="'panel-'+index+'-section-j'" class="btn accordion-btn  container-fluid text-left pl-2"><strong>SECTION J</strong> - Declaration<span class="float-right">
             
              <fa-icon [icon]="opened?iconDown:iconRight"></fa-icon>
            
            </span></button>
          
            
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="p-3">

              <app-declaration [index]="index" [bondApplication]="bondApplication"></app-declaration>
            </div>
          </ng-template>
        </ngb-panel>
        <ngb-panel type="white" cardClass="background-card-form"  [id]="index+'_section_k'">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between" [ngClass]="getSectionControlStatus('document_uploads')">
              <button ngbPanelToggle [id]="'panel-'+index+'-section-k'" class="btn accordion-btn container-fluid text-left pl-2"><strong>SECTION K</strong> -Documents to be Uploaded<span class="float-right">
             
              <fa-icon [icon]="opened?iconDown:iconRight"></fa-icon>
            
            </span></button>
          
            
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="p-3">

              <app-document-uploads [index]="index" [bondApplication]="bondApplication" [index]="index"></app-document-uploads>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>

  
