import { User } from './user';

export enum TokenVar {
    token_type = "token_type",
    expires_at = "expires_at",
    access_token = "access_token",
    refresh_token = "refresh_token",
    current_user = "current_user",
    is_impersonation = "is_impersonation"
}

export class Token {
    token_type: string;
    expires_at: string;
    access_token: string;
    refresh_token: string;
    current_user: any;
    is_impersonation: boolean;

    constructor(token_type: string, expires_at: string, access_token: string, current_user: any,is_impersonation:boolean) {
        this.token_type = token_type;
        this.expires_at = expires_at;
        this.access_token = access_token;
        this.current_user = current_user;
        this.is_impersonation = is_impersonation;
        this.setTokenConstruct();
    }

    public setTokenConstruct() {
        this.setToken(this.access_token);
        this.setTokenType(this.token_type);
        this.setExpiresAt(this.expires_at);
        this.setIsImpersonation(this.is_impersonation)
        this.setCurrentUser(this.current_user)
    }

    getTokenType(): string {
        return localStorage.getItem(TokenVar.token_type);
    }

    getToken(): String {
        return localStorage.getItem(TokenVar.access_token);
    }

    getIsImpersonation(): String {
        return localStorage.getItem(TokenVar.is_impersonation);
    }

    getCurrentUser(): User {
        let user =  null;
        if(localStorage.getItem(TokenVar.current_user))
        {
          user =  new User();
          user.fromJson(JSON.parse(localStorage.getItem(TokenVar.current_user)));
        }
        return user;
    }


    setToken(token: string) {
        localStorage.setItem(TokenVar.access_token, token);
    }

    setIsImpersonation(is_impersonation:boolean)
    {
        localStorage.setItem(TokenVar.is_impersonation, JSON.stringify(is_impersonation));
    }

    setCurrentUser(user: any) {
        localStorage.setItem(TokenVar.current_user, JSON.stringify(user));
    }

    setTokenType(tokenType: string) {
        localStorage.setItem(TokenVar.token_type, tokenType);
    }

    setExpiresAt(expires_at: string) {
        localStorage.setItem(TokenVar.expires_at, expires_at);
    }


}
