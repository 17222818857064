import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Paginate } from 'src/app/models/paginate';
import { ApplicationStorageService } from 'src/app/application/services/application-storage.service';
import { faPlayCircle, faThList } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-application-list',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.scss']
})
export class ApplicationListComponent implements OnInit {

	public applicationPagination: Paginate;
	public applicationsLoading: boolean;
	public queryParams = <any>{};
	public subscriptions = {
    	mediaList:null,
    	pageParams:null
  };
  numApplications: number;

  playCircle = faPlayCircle;
  thCircle = faThList;

  displayMode="grid";


	public searchForm = new FormGroup({
		search: new FormControl('', Validators.required),
	})

	get search(): any {
  	return this.searchForm.get('search');
	}

  constructor(
    private _activated_route: ActivatedRoute,
    private _router: Router,
    private _application_service: ApplicationStorageService
  ) { 
  	this.subscriptions.pageParams = this._activated_route.queryParams.subscribe((params)=>{
			this.queryParams = Object.assign(this.queryParams, params);
      	this.loadApplications(this.queryParams)
    }); 
  }

  ngOnInit(): void {}

  resetForm(){
		this._router.navigate([],{
      relativeTo: this._activated_route,
      queryParams: Object.assign(this.queryParams, {search: '',page: 1}), 
      queryParamsHandling: 'merge',
    });
	}

	onSearchSubmit(){
		this._router.navigate([],{
        relativeTo: this._activated_route,
        queryParams: Object.assign(this.queryParams, {search: this.search.value,page: 1}), 
        queryParamsHandling: 'merge',
    });
	}
  
	loadApplications(params) {
    this.applicationsLoading = true;

    this.subscriptions.mediaList = this._application_service.list$(params)
    .pipe( finalize(()=> this.applicationsLoading = false ))
    .subscribe((res)=> {
      this.applicationPagination = res;
      this.numApplications = this.applicationPagination.data.length;
    })
	}

	onPageChange($event) {
		this._router.navigate([],{
      relativeTo: this._activated_route,
      queryParams:  Object.assign(this.queryParams, {page:$event}), 
      queryParamsHandling: 'merge',
    });
  }
  
  openApplication(application) {
    console.log(application);
    if(application.status.code != 'susp') {
      this._router.navigate(application.action_url);
    }
  }

	ngOnDestroy(): void {
    Object.keys(this.subscriptions).forEach(s=>{
      this.subscriptions[s].unsubscribe();
    })
  }
}
