<div class="container">
    <div class="card craft-card bg-light mt-5" *ngIf="paymentStatus">
        <div class="card-body" [appLoader]="paymentDataLoading">
            <div class="row">
                <fa-icon *ngIf="paymentStatus.payment_succefull" [icon]="checkCircle" class="fa-5x fa-icon d-block m-auto"></fa-icon>
                <fa-icon *ngIf="!paymentStatus.payment_succefull" [icon]="timesCircle" class="fa-5x fa-icon d-block m-auto"></fa-icon>
            </div>
            <h3 class="text-center mb-4">{{ paymentStatus.title }}</h3>
            <h5 class="text-center mb-4">{{ paymentStatus.sub_title }}</h5>
            <p class="text-center">
                {{ paymentStatus.message }}
            </p>

            <div class="text-center">
                <a *ngFor="let action of actions" class="btn btn-primary mr-1" [routerLink]="action.path">
                    {{ action.title }}
                </a>

                <button *ngFor="let action of form_actions" type="button" class="btn btn-primary mr-1" (click)="executeFormAction($event,action)">
                    {{ action.title }}
                </button>
            </div>
        </div>
    </div>
    <ng-template #unsuccessfullTemplate>
        <div class="alert alert-info" *ngIf="!paymentDataLoading">
            Application may been already loaded
        </div>
        <div class="card craft-card min-vh-100">
            <div class="card-body" [appLoader]="paymentDataLoading">
            </div>
        </div>
    </ng-template>

</div>
