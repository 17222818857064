<fieldset [formGroup]="other_information">

    <div class="row">
        <div class="col-md">
            <!-- start of  under_debt_counselling field -->
            <div class="form-group" >
                <label class="invalid" for="under_debt_counselling-field">Are you currently under debt counselling in terms of the National Credit Act, 2005?</label>
                <!-- <input formControlName="under_debt_counselling" type="input" class="form-control" [ngClass]="{'is-invalid':other_information.get('under_debt_counselling').invalid && (other_information.get('under_debt_counselling').dirty || other_information.get('under_debt_counselling').touched) }" id="under_debt_counselling-field"> -->
                <div class="row">
                  <div class="col-md">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" formControlName="under_debt_counselling" id="under_debt_counselling-yes{{index}}" value="1">
                      <label class="form-check-label" for="under_debt_counselling-yes{{index}}">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" formControlName="under_debt_counselling" id="under_debt_counselling-no{{index}}" value="0">
                      <label class="form-check-label" for="under_debt_counselling-no{{index}}">
                        No
                      </label>
                    </div>
                    <app-invalid-feedback  [ngClass]="{'d-block':other_information.get('under_debt_counselling').invalid && (other_information.get('under_debt_counselling').dirty || other_information.get('under_debt_counselling').touched) }" class="invalid-feedback" name="This " [control]="other_information.get('under_debt_counselling')"></app-invalid-feedback>

                  </div>
                </div>
               

            </div>
            <!-- end of under_debt_counselling Field -->
        </div>
    </div>

    <div class="row">
      <div class="col-md">
        <!-- start of  debt_counsellor_name field ;-)-->
        <div class="form-group" *ngIf="other_information.get('debt_counsellor_name')">
            <label for="debt_counsellor_name-field">If Yes, Name of Debt Counsellor</label>
            <input formControlName="debt_counsellor_name" type="input" class="form-control" [ngClass]="{'is-invalid':other_information.get('debt_counsellor_name').invalid && (other_information.get('debt_counsellor_name').dirty || other_information.get('debt_counsellor_name').touched) }" id="debt_counsellor_name-field">
            <app-invalid-feedback  class="invalid-feedback" name="Debt Counsellor Name" [control]="other_information.get('debt_counsellor_name')"></app-invalid-feedback>
        </div>
        <!-- end of debt_counsellor_name Field -->
      </div>
        <div class="col-md">
            <!-- start of  debt_counsellor_contact_number field ;-)-->
            <div class="form-group" *ngIf="other_information.get('debt_counsellor_contact_number')">
                <label for="debt_counsellor_contact_number-field">If Yes,Contact number of Debt Counsellor</label>
                <input formControlName="debt_counsellor_contact_number" type="input" class="form-control" [ngClass]="{'is-invalid':other_information.get('debt_counsellor_contact_number').invalid && (other_information.get('debt_counsellor_contact_number').dirty || other_information.get('debt_counsellor_contact_number').touched) }" id="debt_counsellor_contact_number-field">
                <app-invalid-feedback  class="invalid-feedback" name="Debt Counsellor Contact Number" [control]="other_information.get('debt_counsellor_contact_number')"></app-invalid-feedback>
            </div>
            <!-- end of debt_counsellor_contact_number Field -->
        </div>
    </div>

    <div class="row">
        <div class="col-md">
            <!-- start of  debt_re_arrangement_issued field ;-)-->
            <div class="form-group">
                <label for="debt_re_arrangement_issued-field">Has a debt re-arrangement ever been issued in terms of the National Credit Act, 2005? </label>
                <div class="row">
                  <div class="col-md">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" formControlName="debt_re_arrangement_issued" id="debt_re_arrangement_issued-yes{{index}}" value="1">
                        <label class="form-check-label" for="debt_re_arrangement_issued-yes{{index}}">
                          Yes
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" formControlName="debt_re_arrangement_issued" id="debt_re_arrangement_issued-no{{index}}" value="0">
                        <label class="form-check-label" for="debt_re_arrangement_issued-no{{index}}">
                          No
                        </label>
                      </div>
                      <app-invalid-feedback  [ngClass]="{'d-block':other_information.get('debt_re_arrangement_issued').invalid && (other_information.get('debt_re_arrangement_issued').dirty || other_information.get('debt_re_arrangement_issued').touched) }" class="invalid-feedback" name="This " [control]="other_information.get('debt_re_arrangement_issued')"></app-invalid-feedback>

                  </div>
                </div>
              
                
                
                <app-invalid-feedback  class="invalid-feedback" name="debt_re_arrangement_issued" [control]="other_information.get('debt_re_arrangement_issued')"></app-invalid-feedback>
            </div>
            <!-- end of debt_re_arrangement_issued Field -->
        </div>
        <div class="col-md">
            <!-- start of  ever_insolvent_or_administration field -->
            <div class="form-group">
                <label for="ever_insolvent_or_administration-field">Have you ever been insolvent or placed under an administration order</label>
                <div class="row">
                  <div class="col-md">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" formControlName="ever_insolvent_or_administration" id="ever_insolvent_or_administration-yes{{index}}" value="1" >
                      <label class="form-check-label" for="ever_insolvent_or_administration-yes{{index}}">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" formControlName="ever_insolvent_or_administration" id="ever_insolvent_or_administration-no{{index}}" value="0">
                      <label class="form-check-label" for="ever_insolvent_or_administration-no{{index}}">
                        No
                      </label>
                    </div>
                    <app-invalid-feedback   [ngClass]="{'d-block':other_information.get('ever_insolvent_or_administration').invalid && (other_information.get('ever_insolvent_or_administration').dirty || other_information.get('ever_insolvent_or_administration').touched) }" class="invalid-feedback" name="This " [control]="other_information.get('ever_insolvent_or_administration')"></app-invalid-feedback>

                  </div>
                </div>
               

            </div>
            <!-- end of ever_insolvent_or_administration Field -->
        </div>
    </div>
    <div class="row">
        <div class="col-md">
            <!-- start of  date_of_rehabilitation field -->
            <div class="form-group" *ngIf="other_information.get('date_of_rehabilitation')">

                 <!-- start of  date of rehabilitation< field -->
                 <div class="form-group">
                  <label for="date_of_rehabilitation-field">If YES, state date of rehabilitation</label>
                  <!-- <input formControlName="date_of_rehabilitation" type="input" class="form-control" [ngClass]="{'is-invalid':other_information.get('date_of_rehabilitation').invalid && (other_information.get('date_of_rehabilitation').dirty || other_information.get('date_of_rehabilitation').touched) }" id="title-field"> -->
                  <div class="input-group" [ngClass]="{'is-invalid':other_information.get('date_of_rehabilitation').invalid && (other_information.get('date_of_rehabilitation').dirty || other_information.get('date_of_rehabilitation').touched) }">
                  
                      <input class="form-control" placeholder="dd-mm-yyyy" formControlName="date_of_rehabilitation" ngbDatepicker #d="ngbDatepicker" [ngClass]="{'is-invalid':other_information.get('date_of_rehabilitation').invalid && (other_information.get('date_of_rehabilitation').dirty || other_information.get('date_of_rehabilitation').touched) }">
                      <div class="input-group-append">
                      <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                        <fa-icon [icon]="faCalendar"></fa-icon>
                      </button>
                      </div>
                  </div>
                  <app-invalid-feedback  class="invalid-feedback" name="Date of rehabilitation" [control]="other_information.get('date_of_rehabilitation')"></app-invalid-feedback>
                  
              </div>
              
            </div>
            <!-- end of date_of_rehabilitation Field -->
        </div>
    </div>
    <div class="row">
      <div class="col-md">
        <div class="form-group">
          <label for="ever_insolvent_or_administration-field">Are you a first time home buyer?</label>
          <div class="row">
            <div class="col-md">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="first_time_owner" id="first_time_owner-yes{{index}}" [value]="true" >
                <label class="form-check-label" for="first_time_owner-yes{{index}}">
                  Yes
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="first_time_owner" id="first_time_owner-no{{index}}" [value]="false">
                <label class="form-check-label" for="first_time_owner-no{{index}}">
                  No
                </label>
              </div>
              <app-invalid-feedback   [ngClass]="{'d-block':other_information.get('first_time_owner').invalid && (other_information.get('first_time_owner').dirty || other_information.get('first_time_owner').touched) }" class="invalid-feedback" name="This " [control]="other_information.get('first_time_owner')"></app-invalid-feedback>

            </div>
          </div>
        </div>
      </div>
    </div>
</fieldset>