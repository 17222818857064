import { Component, OnInit, Input } from '@angular/core';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-sallery-deduction-calculation',
  templateUrl: './sallery-deduction-calculation.component.html',
  styleUrls: ['./sallery-deduction-calculation.component.scss']
})
export class SalleryDeductionCalculationComponent implements OnInit {

	/**
	 * Bond application
	 * @var {FormGroup}
	 */
	@Input() bondApplication: FormGroup;
	@Input() index: number;

	/**
	 * Salary deductions form group
	 * @var {FormGroup}
	 * @return {FormGroup}
	 */
	public get sum_of_values(): number {
		
		let monthly_gross_income;
		monthly_gross_income = this.utilityService.valuesSum(this.bondApplication.get('monthly_gross_income').value,[
			"gross_monthly_salary_income",
			"commission",
			"overtime",
			"maintenance",
			"rental_income",
			"pension",
			"other",
		]); 
		
		let salary_deductions;
		salary_deductions =  this.utilityService.valuesSum(this.bondApplication.get('salary_deductions').value,[
       		"income_tax",
        	"unemployment_insurance_fund",
        	"pension",
        	"medical_aid",
        	"other_specify",
    	]); 

    	return monthly_gross_income.toFixed(2) - salary_deductions.toFixed(2);

	}


  	constructor( private utilityService: UtilityService ) { }

  	ngOnInit(): void {

  		

  	}

}
