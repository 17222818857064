import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  private serverValidation$ = new Subject<any>();

  constructor() { }

  /**
   * @param FormGroup {form}
   * @param array {validationErrors}
   * set errors on form controls
   * @return {void}
   */
  setServerValidation(form:FormGroup,validationErrors):void{

    this.serverValidation$.next(validationErrors);
    let errorList = Object.keys(validationErrors);
    //method heavily inspired by https://juristr.com/blog/2019/02/display-server-side-validation-errors-with-angular/
    errorList.forEach( coloumn => {
      const formControl = form.get(coloumn);


      if (formControl) {
        // activate the error message
        formControl.setErrors({
          serverError: validationErrors[coloumn][0]
        });
      }
		})
  }

  getSeverValidation$()
  {
    return this.serverValidation$;
  }

  /**
   * @param array {validationErrors}
   * set errors on form controls
   * @return {array}
   */
  getErrorMessages(validationErrors: Array<any>):Array<string>
  {
    let server_errors = [];
    Object.keys(validationErrors).forEach( key => {
      server_errors = server_errors.concat(validationErrors[key]);
    });
    return server_errors;
  }



validateAllFormFields(formGroup: FormGroup) {         
  Object.keys(formGroup.controls).forEach(field => {  
    const control = formGroup.get(field);             
    if (control instanceof FormControl) {             
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {        
      this.validateAllFormFields(control);            
    }
  });
}

 formControlsInValid(formGroup: FormGroup,excluded:Array<string>)
 {
      let controls = Object.keys(formGroup.controls);
      controls = controls.filter((control)=>{
        return  excluded.find(excludes=> control != excludes);
      })
     
      for (let index = 0; index < controls.length; index++) {
        const control = controls[index];
        if(formGroup.get(control).invalid)
        {
          return true
        }
      }
      
      return false;
 }

}
