<fieldset [formGroup]="application_type">

    <!-- start of  Home Language field -->
    <div class="form-group">
        <label class="font-weight-bold" for="application_type-field">Type of Application</label>
        <div class="form-check" *ngFor="let option of options['application_type']" [ngClass]="{'is-invalid':application_type.get('application_type').invalid && (application_type.get('application_type').dirty || application_type.get('application_type').touched) }"
            id="application_type-field">
            <input class="form-check-input" type="radio" name="application_type" id="{{option.value}}" value="{{option.value}}" formControlName="application_type">
            <label class="form-check-label" for="{{option.value}}">
                    {{option.name}}
                </label>
        </div>

        <!-- <select formControlName="application_type" class="form-control" [ngClass]="{'is-invalid':application_type.get('application_type').invalid && (application_type.get('application_type').dirty || application_type.get('application_type').touched) }" id="application_type-field">
                <option class="d-none" [ngValue]="''">
                    Select type of application
                </option>
                <option *ngFor="let option of options['application_type']"  [ngValue]="option.value">
                    {{option.name }}
                </option>
            </select> -->

        <app-invalid-feedback class="invalid-feedback" name="Application Type" [control]="application_type.get('application_type')"></app-invalid-feedback>
    </div>

    <!-- end of Home Language Field -->

    <!-- start of  Home Language field -->
    <div class="form-group">
        <label class="font-weight-bold" for="preferred-field">Please select a minimum of two banks where you would like to apply for your bond:</label>
        <div class="justify-content-center">

            <div *ngFor="let option of options['banks']; let i=index" class="form-check form-check-inline">


                <label>
                    <input  type="checkbox" [checked]="previousBanks.includes(option.value)" [value]="option.value" (change)="onCheckboxChange($event)" />
                    {{option.name}}
                </label>
            </div>
        </div>


        <app-invalid-feedback [ngClass]="{'d-block':application_type.get('preferred_banks').invalid && (application_type.get('preferred_banks').dirty || application_type.get('preferred_banks').touched) }" class="invalid-feedback" name="Preferred Banks" [control]="application_type.get('preferred_banks')"></app-invalid-feedback>
    </div>
    <!-- end of Home Language Field -->

    <!-- start of  Home Language field -->
    <div class="form-group">
        <label class="font-weight-bold" for="unit_type_id-field">Type of unit</label>
        <p class="select-unit-type bounce" *ngIf="application_type.get('unit_type_id').invalid"><fa-icon [icon]="faHandPointDown"></fa-icon><b> Please select your Unit here</b> before you proceed with the application</p>
        <div *ngFor="let unitType of unitTypes" class="funkyradio-primary">
            <div class="funkyradio">
                <input formControlName="unit_type_id" class='unit-type' [ngClass]="{'is-invalid':application_type.get('unit_type_id').invalid && (application_type.get('unit_type_id').dirty || application_type.get('unit_type_id').touched) }" [id]="generateUnitTypeRadioId(unitType.id)"
                    name='unit_type_id' type='radio' value="{{ unitType.id }}">
                <label [for]="generateUnitTypeRadioId(unitType.id)">{{ unitType.name }} - {{ unitType.pivot.price  | currency:'R'}}</label>
            </div>
        </div>

        <app-invalid-feedback class="invalid-feedback" name="Application Type" [control]="application_type.get('unit_type_id')"></app-invalid-feedback>
    </div>
    <!-- end of Home Language Field -->


</fieldset>