<div class="row">
  <div class="col-12 pr-2 pl-2">
    <h3 class="">
      In what account is your income paid?
    </h3>
  </div>
</div>

<fieldset [formGroup]="financial_details">
  <div class="row">
    <div class="col-md-6 pr-2 pl-2">
      <!-- start of Bank Name field -->
      <div class="form-group">
        <label for="bank_name-field">Bank Name</label>
        <input formControlName="bank_name" type="input" class="form-control"
          [ngClass]="{'is-invalid':financial_details.get('bank_name').invalid && (financial_details.get('bank_name').dirty || financial_details.get('bank_name').touched) }"
          id="bank_name-field">
        <app-invalid-feedback class="invalid-feedback" name="Bank Name" [control]="financial_details.get('bank_name')">
        </app-invalid-feedback>
      </div>
      <!-- end of Bank Name Field -->
    </div>
    <div class="col-md-6 pr-2 pl-2">
      <!-- start of  Bank Account Number field -->
      <div class="form-group">
        <label for="bank_account_number-field">Bank Account Number</label>
        <input formControlName="bank_account_number" type="input" class="form-control"
          [ngClass]="{'is-invalid':financial_details.get('bank_account_number').invalid && (financial_details.get('bank_account_number').dirty || financial_details.get('bank_account_number').touched) }"
          id="bank_account_number-field">
        <app-invalid-feedback class="invalid-feedback" name="Bank Account Number"
          [control]="financial_details.get('bank_account_number')"></app-invalid-feedback>
      </div>
      <!-- end of Bank Account Number Field -->
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 pr-2 pl-2">
      <!-- start of  Account Type field -->
      <div class="form-group">
        <label for="account_type-field">Account Type</label>

        <select formControlName="account_type" class="form-control"
          [ngClass]="{'is-invalid':financial_details.get('account_type').invalid && (financial_details.get('account_type').dirty || financial_details.get('account_type').touched) }"
          id="account_type-field">
          <option class="d-none" [ngValue]="''">
            Select account type
          </option>
          <option *ngFor="let option of options['account_type']" [ngValue]="option.value">
            {{option.name }}
          </option>
        </select>

        <app-invalid-feedback class="invalid-feedback" name="Account Type"
          [control]="financial_details.get('account_type')"></app-invalid-feedback>
      </div>
      <!-- end of Account Type Field -->
    </div>
    <div class="col-md-6 pr-2 pl-2">
      <!-- start of  Branch Name field -->
      <div class="form-group">
        <label for="branch_name-field">Branch Name</label>
        <input formControlName="branch_name" type="input" class="form-control"
          [ngClass]="{'is-invalid':financial_details.get('branch_name').invalid && (financial_details.get('branch_name').dirty || financial_details.get('branch_name').touched) }"
          id="branch_name-field">
        <app-invalid-feedback class="invalid-feedback" name="Branch Name"
          [control]="financial_details.get('branch_name')"></app-invalid-feedback>
      </div>
      <!-- end of Branch Name Field -->
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 pr-2 pl-2">
      <!-- start of  Branch Code field -->
      <div class="form-group">
        <label for="branch_code-field">Branch Code</label>
        <input formControlName="branch_code" type="input" class="form-control"
          [ngClass]="{'is-invalid':financial_details.get('branch_code').invalid && (financial_details.get('branch_code').dirty || financial_details.get('branch_code').touched) }"
          id="branch_code-field">
        <app-invalid-feedback class="invalid-feedback" name="Branch Code"
          [control]="financial_details.get('branch_code')"></app-invalid-feedback>
      </div>
      <!-- end of Branch Code Field -->
    </div>
    <div class="col-md-6 pr-2 pl-2">
      <!-- start of  Bank Account Holder Name and Surname field -->
      <div class="form-group">
        <label for="bank_account_holder_name-field">Bank Account Holder Name and Surname</label>
        <input formControlName="bank_account_holder_fullname" type="input" class="form-control"
          [ngClass]="{'is-invalid':financial_details.get('bank_account_holder_fullname').invalid && (financial_details.get('bank_account_holder_fullname').dirty || financial_details.get('bank_account_holder_fullname').touched) }"
          id="bank_account_holder_fullname-field">
        <app-invalid-feedback class="invalid-feedback" name="Bank Account Holder Name and Surname"
          [control]="financial_details.get('bank_account_holder_fullname')"></app-invalid-feedback>
      </div>
      <!-- end of Bank Account Holder Name and Surname Field -->
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 ">
      <div class="form-group">
        <label for="existing_loan_be_cancelled-field">Will an existing home loan be cancelled?</label>
        <div class="row">
          <div class="col-md pr-2 pl-2">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" formControlName="existing_loan_be_cancelled"
                id="existing_loan_be_cancelled-yes{{index}}" value="1">
              <label class="form-check-label" for="existing_loan_be_cancelled-yes{{index}}">
                Yes
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" formControlName="existing_loan_be_cancelled"
                id="existing_loan_be_cancelled-no{{index}}" value="0">
              <label class="form-check-label" for="existing_loan_be_cancelled-no{{index}}">
                No
              </label>
            </div>
          </div>
        </div>
      </div>
      <app-invalid-feedback class="invalid-feedback" name="This "
    [control]="financial_details.get('existing_loan_be_cancelled')"></app-invalid-feedback>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="property_purchase_reason-field">Is this property being purchased for</label>
        <div class="row">
          <div class="col-md pr-2 pl-2">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" formControlName="property_purchase_reason"
                id="property_purchase_reason-main" value="Main Residence">
              <label class="form-check-label" for="property_purchase_reason-main">
                 Main Residence
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" formControlName="property_purchase_reason"
                id="property_purchase_reason-Investment" value="Investment">
              <label class="form-check-label" for="property_purchase_reason-Investment">
                Investment
              </label>
            </div>
          </div>
        </div>
      </div>
      <app-invalid-feedback class="invalid-feedback" name="This "
      [control]="financial_details.get('property_purchase_reason')"></app-invalid-feedback>
    </div>
  </div>

</fieldset>