import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { iif } from 'rxjs';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordDetails: FormGroup;
  formLoading: Boolean
  paramData: {
    email,
    token
  };
  componentStatus: {
    is_reset,
    display_form
    message
  };
  checkCircle = faCheckCircle;

  

  constructor(
    private _utility_service: UtilityService,
    private fb: FormBuilder,
    private _toast_service: ToastService,
    private _userService: UserService,
    private _validation_service: ValidationService,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this._route.paramMap.subscribe(params => {
      this.componentStatus = {
        is_reset:true,
        display_form:true,
        message:null
      };

      this.paramData = {
        token:params.get('token'),
        email:params.get('email')
      }

      this.componentStatus.is_reset = this.paramData.token != null;
      this.initForm();
    });

  }

  initForm(): void {
    
    this.forgotPasswordDetails = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    if(this.componentStatus.is_reset)
    {
      this.forgotPasswordDetails.get('email').setValue(this.paramData.email)
      this.forgotPasswordDetails.addControl('token', this.fb.control(this.paramData.token, Validators.required));
      this.forgotPasswordDetails.addControl('password', this.fb.control('', Validators.required));
      this.forgotPasswordDetails.addControl('password_confirmation', this.fb.control('', Validators.required));
    }

  }

  onSubmit(): void {
    this._utility_service.markFormGroupTouched(this.forgotPasswordDetails);    
    if(!this.forgotPasswordDetails.invalid)
    {
      this.formLoading = true;
        

        iif(
          ()=>this.componentStatus.is_reset,
          this._userService.passwordReset$(this.forgotPasswordDetails.value),
          this._userService.passwordEmail$(this.forgotPasswordDetails.value)
        ).subscribe(data => {
          this.formLoading = false;
          this.componentStatus.display_form = false;
          this.componentStatus.message = data['message'];
        }, (res) => {
          this.formLoading = false;
          this._toast_service.show(res.error.message, { classname: 'bg-danger text-light' });
          if (res.status == 422) {
            this._validation_service.setServerValidation(this.forgotPasswordDetails,res.error.errors);
          }
        });

    }else
    {
      this._toast_service.show("Please ensure that your input is valid", { classname: 'bg-danger text-light' });
    }
  }

}
