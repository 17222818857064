
import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormArray, FormGroup, Form } from '@angular/forms';
import { distinctUntilChanged, pairwise, startWith } from "rxjs/operators";

import options from 'src/app/data/options.json'
import { Subscription } from 'rxjs';

import { faHandPointDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-application-type',
  templateUrl: './application-type.component.html',
  styleUrls: ['./application-type.component.scss']
})
export class ApplicationTypeComponent implements OnInit, OnDestroy {

  application_type: FormGroup;
  options = options;
  subscriptions: Array<Subscription> = []
  unitTypes:Array<any>;
  @Output()
  changeApplicationType: EventEmitter<any> = new EventEmitter<any>();
  @Input() formData: any;
  @Input() bondApplication: FormGroup;
  @Input() index: number;
  faHandPointDown = faHandPointDown;



constructor(private fb: FormBuilder,
  ) {
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    })  }


  ngOnInit(): void {
    this.AddToForm();
  }

  AddToForm(): void {

    this.application_type = this.fb.group({
        preferred_banks: this.fb.array([],[Validators.minLength(2),Validators.required]),
        application_type: ['',[Validators.required]],
        unit_type_id: ['',[Validators.required]],
        unit_unit_type_id: ['', [Validators.required]],
    });
    this.bondApplication.addControl('application_type', this.application_type);
    this.onChanges();
  }


  ngOnChanges(changes: SimpleChanges): void {

    if(changes.formData)
    {
      this.setUnitTypes(changes.formData.currentValue);
    }
  }

  setUnitTypes(data: any) {
    this.unitTypes = [];
    if(data["unit"] && data["unit"]["unit_types"])
    {
      this.unitTypes = data["unit"]["unit_types"];
      if(this.application_type.get('unit_type_id').value) {
        this.setPurchasePrice(this.application_type.get('unit_type_id').value);
        this.setUnitUnitType(this.application_type.get('unit_type_id').value);
      }
    }
  }

  get previousBanks()
  {
    let selectedControls = (this.application_type.controls.preferred_banks as FormArray).controls;
    return selectedControls.map(control => control.value);
  }

  onChanges(): void {
    this.subscriptions.push(
      this.application_type.get('application_type').valueChanges.pipe(
        startWith(null),
        distinctUntilChanged(),
        pairwise() // gets a pair of old and new value
      ).subscribe(([oldValue, newValue]) => {

        this.changeApplicationType.emit({

          "old_value":oldValue,
          "new_value":newValue
      });
    }));

    this.subscriptions.push(this.application_type.get('unit_type_id').valueChanges.pipe(
      distinctUntilChanged(),
    ).subscribe((unit_type_id)=>{
        this.setPurchasePrice(unit_type_id);
        this.setUnitUnitType(unit_type_id);
    }));
  }

  setUnitUnitType(unit_type_id)
  {
    let selected_unit_type = this.unitTypes.find((unit_type)=>{
      return unit_type_id == unit_type.id;
    });

    if(selected_unit_type)
    {
        selected_unit_type.pivot.id;
        this.application_type.get('unit_unit_type_id').setValue(selected_unit_type.pivot.id);
    }
  }

  setPurchasePrice(unit_type_id)
  {
      let selected_unit_type = this.unitTypes.find((unit_type)=>{
        return unit_type_id == unit_type.id;
      });

      if(selected_unit_type)
      {
       let applicants = (this.bondApplication.get('applicants') as FormArray);
       applicants.controls.forEach((applicant)=>{

        if(applicant.get('loan_details.purchase_price')) {
          applicant.get('loan_details.purchase_price').setValue(selected_unit_type.pivot.price);
        }

       });
      }
  }


  onCheckboxChange(e) {
    const checkArray: FormArray = this.application_type.get('preferred_banks') as FormArray;

    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  generateUnitTypeRadioId(id: number){
    return 'unit_type_id'+id;
  }

}
