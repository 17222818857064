<fieldset [formGroup]="document_uploads">
    <div class="alert alert-info" role="alert">
      <p>Please note the following for the file uploads:</p> 
      <ul>
        <li>Only the following file types are allowed: pdf, jpeg, bmp, png.</li>
        <li>Please also ensure that the documents you are uploading are less than 2MB.</li>
        <li>
          If you are unable to upload your documents currently, please send them via email to: <a href = "mailto: info@crafthomes.co.za">info@crafthomes.co.za</a>.<br>
          Kindly inform your sale executive if you are unable to upload your documents. These documents are required to complete your bond application.
        </li>
      </ul>
    </div>
      
     <!-- start of  First Name field -->
     <div class="form-group">
      <app-file-field [invalid]="document_uploads.get('id_copy').invalid && document_uploads.get('id_copy').touched" [accept]="fileTypes" label="Copy of ID - If uploading an ID card please ensure both sides are uploaded" fieldId="id_copy_field" formControlName="id_copy" [multiple]="true"></app-file-field>
      <app-invalid-feedback [ngClass]="{'d-block':document_uploads.get('id_copy').invalid && (document_uploads.get('id_copy').touched || document_uploads.get('id_copy').touched) }" class="invalid-feedback" name="Copy of ID" [control]="document_uploads.get('id_copy')"></app-invalid-feedback>
      <ul>
        <li class="text-danger" *ngFor="let error of idCopyErrors">
          {{ error }}
        </li>
      </ul>
    </div>

    <div class="form-group">
     <app-file-field [invalid]="document_uploads.get('proof_of_residence').invalid && document_uploads.get('proof_of_residence').touched" [accept]="fileTypes" label="Proof of residence - Not older than three months" fieldId="proof_of_residence_field" formControlName="proof_of_residence" [multiple]="true"></app-file-field>
      <app-invalid-feedback [ngClass]="{'d-block':document_uploads.get('proof_of_residence').invalid && (document_uploads.get('proof_of_residence').touched || document_uploads.get('proof_of_residence').touched) }" class="invalid-feedback" name="Proof of residence" [control]="document_uploads.get('proof_of_residence')"></app-invalid-feedback>
      <ul>
        <li class="text-danger" *ngFor="let error of proofOfResidenceErrors">
          {{ error }}
        </li>
      </ul>
    </div>

    <div class="form-group">
      <app-file-field [invalid]="document_uploads.get('salary_slips').invalid && document_uploads.get('salary_slips').touched" [accept]="fileTypes" label="Three months salary slips " fieldId="salary_slips_field" formControlName="salary_slips" [multiple]="true"></app-file-field>
      <span class="text-muted">
        Multiple files must be selected all at the same time.
      </span>
      <app-invalid-feedback [ngClass]="{'d-block':document_uploads.get('salary_slips').invalid && (document_uploads.get('salary_slips').touched || document_uploads.get('salary_slips').touched) }" class="invalid-feedback" name="Three months salary slips" [control]="document_uploads.get('salary_slips')"></app-invalid-feedback>
          <ul>
            <li class="text-danger" *ngFor="let error of salarySlipErrors">
              {{ error }}
            </li>
          </ul>
    </div>

    <div class="form-group">
      <app-file-field [invalid]="document_uploads.get('bank_statements').invalid && document_uploads.get('bank_statements').touched" [accept]="fileTypes" label="Three months bank statements " fieldId="bank_statements_field" formControlName="bank_statements" [multiple]="true"></app-file-field>
      <span class="text-muted">
        Multiple files must be selected all at the same time.
      </span>
      <app-invalid-feedback [ngClass]="{'d-block':document_uploads.get('bank_statements').invalid && (document_uploads.get('bank_statements').touched || document_uploads.get('bank_statements').touched) }" class="invalid-feedback" name="Three months salary slips" [control]="document_uploads.get('bank_statements')"></app-invalid-feedback>
          <ul>
            <li class="text-danger" *ngFor="let error of bankStatementErrors">
              {{ error }}
            </li>
          </ul>
    </div>

    <div class="form-group" *ngIf="document_uploads.get('anc_contract')">
      <app-file-field [invalid]="document_uploads.get('anc_contract').invalid && document_uploads.get('anc_contract').touched" [accept]="fileTypes" label="ANC contract (if selected married ANC)" fieldId="anc_contract_field" formControlName="anc_contract" [multiple]="true"></app-file-field>
      <app-invalid-feedback [ngClass]="{'d-block':document_uploads.get('anc_contract').invalid && (document_uploads.get('anc_contract').touched || document_uploads.get('anc_contract').touched) }" class="invalid-feedback" name="ANC contract" [control]="document_uploads.get('anc_contract')"></app-invalid-feedback>
      <ul>
        <li class="text-danger" *ngFor="let error of ancContractErrors">
          {{ error }}
        </li>
      </ul>
    </div>


</fieldset>