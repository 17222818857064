<div class="card craft-card bg-light" *ngIf="application">
    <div class="card-body">
    <div class="row">
        <div class="col-12">
            <a *ngIf="application.status.code == 'awt_cmpl'" class="btn btn-primary mt-2 float-right" [routerLink]="['/',application.development.code,'bond-application',application.token]" >Update Bond Application</a>
        </div>
    </div>

    <div class="row mx-0">
    <div class="col-lg-12 text-center pt-3 pb-3 bg-download">
        <h2 class="text-center"> Download My Application </h2>
        <p class="text-center"> Download a copy of your bond application documents below. Use your ID number as the password</p>
         <app-application-download [application]="application" ></app-application-download>
    </div>
    </div>

    <table [appLoader]="revisionLoading" class="table mt-2">
       
        <thead>
            <tr>
                <th>
                    Name
                </th>
                <th>
                    Submitted
                </th>
            </tr>
        </thead>
        <tbody *ngIf="revisionPagination">
            <tr *ngFor="let revision of revisionPagination.data">
                <td>
                    {{ application.label }}
                </td>
                <td>
                    {{ revision.human_created_at }}
                </td>
            </tr>
        </tbody>
        <ng-container *ngIf="revisionPagination && revisionPagination.data.length == 0">
            <tbody>
                <td class="text-center" colspan="2">
                    No Revisions
                </td>
            </tbody>
        </ng-container>
    </table>

</div>
</div>