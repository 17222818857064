<div class="container" [appLoader]="loading" >
    <ng-container *ngIf="application.status.code != 'awt_fica'">
        <div class="card craft-card bg-light mt-5">
            <div class="card-body">
                <div class="row">
                    <fa-icon [icon]="checkCircle" class="fa-5x fa-icon d-block m-auto"></fa-icon>
                </div>
                <h3 class="text-center mb-4">Thank you</h3>
                <h5 class="text-center mb-4">Your application has been submitted</h5>
                <h5 class="text-center mb-4">Shortly, you will receive an email to book an appointment with the Craft Sales agent to sign the agreement, download a copy of your cash application documents below. Use your ID number as the password</h5>
                <div class="row mb-4 d-flex justify-content-center" >
                    <a class="btn btn-primary mr-3" [routerLink]="'/'">
                        Return to Dashboard
                    </a>
                    <app-application-download [application]="application" ></app-application-download>
                </div>

            </div>
        </div>
    </ng-container>
    <ng-container  *ngIf="application.status.code == 'awt_fica'">
        <div class="cash-form-header">

            <div class="fica-heading py-4">
                    FICA Documents
            </div>
            <div class="fica-subheading my-4" *ngIf="application.status.code == 'awt_fica'">
                Please fill in the form and upload your documents below
            </div>
           
        </div>
        <ng-container *ngIf="!loading">
           <form class="form-background" [formGroup]="cashForm" *ngIf="application.status.code == 'awt_fica'" (submit)="submitFica()">
            <div class="form-group">
                <label class="font-weight-bold" for="unit_unit_type_id-field">Type of unit</label>
                <p class="select-unit-type bounce" *ngIf="cashForm.get('unit_unit_type_id').invalid"><fa-icon [icon]="faHandPointDown"></fa-icon><b> Please select your Unit here</b> before you proceed with the application</p>
                <select formControlName="unit_unit_type_id" class="form-control" [ngClass]="{'is-invalid':cashForm.get('unit_unit_type_id').invalid && (cashForm.get('unit_unit_type_id').dirty || cashForm.get('unit_unit_type_id').touched) }" id="unit_unit_type_id-field">
                    <option class="d-none" [ngValue]="''">
                        Select type of unit
                    </option>
                    <option *ngFor="let unitType of unitTypes"  value="{{ unitType.pivot.id }}">
                        {{ unitType.name }} - {{ unitType.pivot.price  | currency:'R'}}
                    </option>
                </select>
                
                <app-invalid-feedback  class="invalid-feedback" name="Application type" [control]="cashForm.get('unit_unit_type_id')"></app-invalid-feedback>
            </div>
            <div class="form-group">
                <label class="font-weight-bold" for="email">Email</label>
                <input formControlName="email" type="email" class="form-control" [ngClass]="{'is-invalid':cashForm.get('email').invalid && (cashForm.get('email').dirty || cashForm.get('email').touched) }" id="email">
                <app-invalid-feedback  class="invalid-feedback" name="Email" [control]="cashForm.get('email')"></app-invalid-feedback>
            </div>
            <div class='form-group'>
                <label class="font-weight-bold">Clear Copy of SA ID document / New ID card - back & front</label>
                <ngx-dropzone (change)="onSelect($event, idDocFiles, 'idDocFilesDirty')"  [ngClass]="{'border-danger': !idDocFilesValid && idDocFilesDirty }">
                    <ngx-dropzone-label>Drag and Drop or Click here to upload your Id Documents</ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of idDocFiles" [removable]="true" (removed)="onRemove(f, idDocFiles)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-preview>

                </ngx-dropzone>
                <small *ngIf="!idDocFilesValid && idDocFilesDirty" class="text-danger">A ID document / New ID card is required</small>
               </div>
                <div class="form-group">
                    <label class="font-weight-bold">Proof of Residence / Utility Bill (not older than 3 months)</label>
                    <ngx-dropzone (change)="onSelect($event, proofOfResFiles, 'proofOfResFilesDirty')"  [ngClass]="{'border-danger': !proofOfResFilesValid && proofOfResFilesDirty}">
                        <ngx-dropzone-label>Drag and Drop or Click here to upload your Proof of Residence Documents</ngx-dropzone-label>
                        <ngx-dropzone-preview *ngFor="let f of proofOfResFiles" [removable]="true" (removed)="onRemove(f, proofOfResFiles)">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                        </ngx-dropzone-preview>
                    </ngx-dropzone>
                    <small *ngIf="!proofOfResFilesValid && proofOfResFilesDirty" class="text-danger">Proof of Residence / Utility Bill is required</small>
                </div>
                <div class="form-group">
                    <label class="font-weight-bold" for="tax_number">Tax number</label>
                    <input formControlName="tax_number" type="input" class="form-control" [ngClass]="{'is-invalid':cashForm.get('tax_number').invalid && (cashForm.get('tax_number').dirty || cashForm.get('tax_number').touched) }" id="tax_number">
                    <app-invalid-feedback  class="invalid-feedback" name="Tax number" [control]="cashForm.get('tax_number')"></app-invalid-feedback>
                </div>
                <div class="form-group clearfix">
                    <input type="submit" class="btn btn-primary float-right" value="Submit">
                </div>
           </form>
        </ng-container>
    </ng-container>
</div>