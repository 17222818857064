<fieldset [formGroup]="debit_order_details">

        <div class="row">
            <div class="col-md override-p-m" *ngIf="debit_order_details.get('bank_name')">
                <!-- start of  Business Name field -->
                <div class="form-group">
                    <label for="bank_name-field">Bank Name</label>
                    <input formControlName="bank_name" type="input" class="form-control" [ngClass]="{'is-invalid':debit_order_details.get('bank_name').invalid && (debit_order_details.get('bank_name').dirty || debit_order_details.get('bank_name').touched) }" id="bank_name-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Business Name" [control]="debit_order_details.get('bank_name')"></app-invalid-feedback>
                </div>
                <!-- end of Business Name Field -->
            </div>
            <div class="col-md override-p-m" *ngIf="debit_order_details.get('bank_account_number')">
                <!-- start of  Bank Account Number field -->
                <div class="form-group">
                    <label for="bank_account_number-field">Bank Account Number</label>
                    <input formControlName="bank_account_number" type="input" class="form-control" [ngClass]="{'is-invalid':debit_order_details.get('bank_account_number').invalid && (debit_order_details.get('bank_account_number').dirty || debit_order_details.get('bank_account_number').touched) }" id="bank_account_number-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Bank Account Number" [control]="debit_order_details.get('bank_account_number')"></app-invalid-feedback>
                </div>
                <!-- end of Bank Account Number Field -->
            </div>
        </div>
        <div class="row">
            <div class="col-md override-p-m">
                    <div class="form-group">
                      <label for="account_type-field">Account type</label>
                      <div class="row">
                        <div class="col-md pr-2 pl-2">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" formControlName="account_type"
                              id="account_type-cheque{{index}}" value="Cheque">
                            <label class="form-check-label" for="account_type-cheque{{index}}">
                               Cheque
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" formControlName="account_type"
                              id="account_type-savings{{index}}" value="Savings">
                            <label class="form-check-label" for="account_type-savings{{index}}">
                              Savings
                            </label>
                          </div>
                        </div>
                      </div>
                      <app-invalid-feedback [ngClass]="{'d-block':debit_order_details.get('account_type').invalid && (debit_order_details.get('account_type').dirty || debit_order_details.get('account_type').touched) }" class="invalid-feedback" name="This " [control]="debit_order_details.get('account_type')"></app-invalid-feedback>

                    </div>

           
            </div>
            <div class="col-md override-p-m">
                <!-- start of  Branch Name field -->
                <div class="form-group">
                    <label for="branch_name-field">Branch Name</label>
                    <input formControlName="branch_name" type="input" class="form-control" [ngClass]="{'is-invalid':debit_order_details.get('branch_name').invalid && (debit_order_details.get('branch_name').dirty || debit_order_details.get('branch_name').touched) }" id="branch_name-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Branch Name" [control]="debit_order_details.get('branch_name')"></app-invalid-feedback>
                </div>
                <!-- end of Branch Name Field -->
            </div>
        </div>
        <div class="row">
            <div class="col-md override-p-m">
                <!-- start of  Branch Code field -->
                <div class="form-group">
                    <label for="branch_code-field">Branch Code</label>
                    <input formControlName="branch_code" type="input" class="form-control" [ngClass]="{'is-invalid':debit_order_details.get('branch_code').invalid && (debit_order_details.get('branch_code').dirty || debit_order_details.get('branch_code').touched) }" id="branch_code-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Branch Code" [control]="debit_order_details.get('branch_code')"></app-invalid-feedback>
                </div>
                <!-- end of Branch Code Field -->
            </div>
            <div class="col-md override-p-m">
                <!-- start of  Bank Account Holder Name And Surname field -->
                <div class="form-group">
                    <label for="bank_account_holder_fullname-field">Bank Account Holder Name And Surname</label>
                    <input formControlName="bank_account_holder_fullname" type="input" class="form-control" [ngClass]="{'is-invalid':debit_order_details.get('bank_account_holder_fullname').invalid && (debit_order_details.get('bank_account_holder_fullname').dirty || debit_order_details.get('bank_account_holder_fullname').touched) }" id="bank_account_holder_fullname-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Bank Account Holder Name And Surname" [control]="debit_order_details.get('bank_account_holder_fullname')"></app-invalid-feedback>
                </div>
                <!-- end of Bank Account Holder Name And Surname Field -->
            </div>

        </div>
        <div class="row">
            <div class="col-md override-p-m">
                <!-- start of  Monthly Repayment Day field -->
                <div class="form-group">
                    <label for="monthly_repayment_day-field">Monthly Repayment Day</label>
                    <select formControlName="monthly_repayment_day" class="form-control" [ngClass]="{'is-invalid':debit_order_details.get('monthly_repayment_day').invalid && (debit_order_details.get('monthly_repayment_day').dirty || debit_order_details.get('monthly_repayment_day').touched) }" id="monthly_repayment_day-field">
                      <option *ngFor="let month of monthly" [ngValue]="month">{{month}}</option>
                    </select>
                    <app-invalid-feedback  class="invalid-feedback" name="Monthly Repayment Day" [control]="debit_order_details.get('monthly_repayment_day')"></app-invalid-feedback>
                </div>
                <!-- end of Monthly Repayment Day Field -->
            </div>
        </div>

</fieldset>