import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserService } from 'src/app/user/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private redirects = 
  {
    guest:{
      url:['login'],
      message:"You have not logged in to the application yet, please login to continue.",

    },
    authorized:{
      url:[''],
      message:null
    },
  }

  constructor(
    private _user_service: UserService,
    private _router: Router,
    private _toast_service: ToastService,
    ){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let notify = true;
      if(next.data.hasOwnProperty('notify'))
      {
        notify = next.data.notify;
      }
      return this._user_service.isAuthed$().pipe(map((isAuthed)=> {
        if(next.data.authed && !isAuthed)
        {
          this._user_service.setIntendedUrl(next);
          if(this.redirects.guest.message && notify)
          {
            this._toast_service.show(this.redirects.guest.message, { classname: 'bg-warning text-light'});
          }
          this._router.navigate(this.redirects.guest.url);
          return false;
        }else if(!next.data.authed && isAuthed)
        {
          if(this.redirects.authorized.message && notify)
          {
            this._toast_service.show(this.redirects.authorized.message, { classname: 'bg-warning text-light'});

          }
          this._router.navigate(this.redirects.authorized.url);
          return false;
        }else
        {
          return true;
        }

      
    }));
  }
  
}
