import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ApplicationStorageService } from 'src/app/application/services/application-storage.service'
import { ProgressReportService } from 'src/app/application/services/progress-report.service';
import { Formtypes } from 'src/app/config/form-types';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { Subscription } from 'rxjs';
import { faChevronDown, faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { UserService } from 'src/app/user/services/user.service';
import { User } from 'src/app/models/user';



@Component({
  selector: 'app-main-form',
  templateUrl: './main-form.component.html',
  styleUrls: ['./main-form.component.scss']
})
export class MainFormComponent implements OnInit, OnDestroy {

  token: string;
  formLoading: Boolean
  dataLoading: Boolean;
  bondApplication: FormGroup;
  formTypes = new Formtypes();
  removedControls: any = [];
  closeResult = '';
  active = 1;
  activeForm: null;
  formSubscriptions: Subscription = new Subscription()
  componentsLoaded = false;
  formData: Array<any> = [];
  activeUser:User;
  applicationData: any = {};
  applicationStatus: any = {
      completed:false,
      message:null,
      success:null
  };
  progress: any = {
    "Section A": 0,
    "Section B": 0,
    "Section C": 0,
    "Section D": 0,
    "Section E": 0,
    "Section F": 0,
    "Section G": 0,
    "Section H": 0,
    "Section I": 0,
  }
  shouldEvaluate:boolean = false;
  errorSection:Array<any> = [{},{}];
  iconDown = faChevronDown;
  iconRight = faChevronRight;
  iconUp = faChevronUp;
  checkCircle = faCheckCircle;
  edit_after_load: boolean;
  downloadApplication = null;
  
  @ViewChild('modal') modal: any;
  @ViewChild('postModal') postModal: any;
  @ViewChild('mainNav') mainNav: any;

  isShow: boolean;
  topPosToStartShowing = 100;

  @HostListener('window:scroll')
  checkScroll() {

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }

  }

  constructor(
    private fb: FormBuilder,
    private _application_storage: ApplicationStorageService,
    private _progressReport: ProgressReportService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _utility_service: UtilityService,
    private _toast_service: ToastService,
    private _validation_service: ValidationService,
    private _userService: UserService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
  ) { }
  ngOnDestroy(): void {

    this.formSubscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.applicationStatus.completed = false;
    this.formSubscriptions.add(this._userService.currentUser$.subscribe(user => {
      this.activeUser = user;
    }))
    this._route.paramMap.subscribe(params => {
      this.token = params.get('token');
      this.initForm();
      this.retriveFormData();
    });
  }

  initView() {
    this.componentsLoaded = true;
    if (this._application_storage.localLoad(`${this.activeUser.email}/${this.token}`)) {
      this.onLocalLoad();
    } else {
      this.onLoadData();
    }
    this.cd.detectChanges();
  }

  initForm(): void {
    //works here
    this.edit_after_load = false;
    this.bondApplication = this.fb.group({
      "applicants": new FormArray([
        new FormGroup({})
      ])
    });
    this._application_storage.setBondApplicationForm(this.bondApplication);
    this.onChanges();
  }

  get showSaveWarning()
  {
    return this._application_storage.getServerSideExist() && this.edit_after_load;
  }

  toggleManualApplication(event) {
    event.preventDefault();
    this.formLoading = true;
    this._application_storage.toggleManualApplication$(this.token, true).subscribe(response => {
      this.formLoading = false;
      this.applicationData.is_manual = true;
      this._router.navigate(['application', this.token]);
    }, error => {
        this.formLoading = false;
        this._toast_service.show(error.error.message, { classname: 'bg-danger text-light' });
      });
  }

  onLoadData() {
    this.formLoading = true
    this.formSubscriptions.add(
      this._application_storage.get$(this.token)
        .subscribe((data) => {
          this.applicationData = data["data"];
          this._application_storage.setData(this.bondApplication, data["data"]["body"]);
          if(this.applicationData.status.code != 'awt_cmpl' || this.formData['development']['is_manual'] || this.applicationData['is_manual']) {
            this._router.navigate(['/']);
          }
          this.formLoading = false

          if (!this._application_storage.allowLocalApplication()) {
            this.open(this.modal);
          }

        }, (res) => {
          this._toast_service.show(res.error.message, { classname: 'bg-danger text-light' });
          this.formLoading = false;
        })
        );
  }

  retriveFormData() {
    this.dataLoading = true;
    this.formSubscriptions.add(
      this._application_storage.formdata$(this.token).subscribe((data) => {

        this.formData = data["data"];
        this.initView();
        this.dataLoading = false;
      },(res)=>{
        this._toast_service.show(res.error.message, { classname: 'bg-danger text-light' });
        this.formLoading = false
         if(res.status == 404)
         {
          this._toast_service.show(res.error.message, { classname: 'bg-danger text-light' });
         }
      }));
  }

  get applicationLoading() {
    return this.formLoading || this.dataLoading;
  }

  get firstApplicant() {
    return this.applicantGroup.at(0);
  }

  get secondApplicant() {
    return this.applicantGroup.at(1);
  }

  get applicantGroup() {
    return (this.bondApplication.get('applicants') as FormArray);
  }

  get formTitle() {
    return this.formData && this.formData['development'] && this.formData['unit'] ? (this.formData['development']["name"] + ' ' + this.formData['unit']['number']) : 'Application Form';
  }

  onChanges(): void {
    this.formSubscriptions.add(
      this.bondApplication.valueChanges.pipe(
        distinctUntilChanged(),
      ).subscribe((nv) => {

        const totalApplicants = this.applicantGroup.length;
        Object.keys(this.progress).forEach(element => {
          this.progress[element] = 0;
        })

        if (nv.applicants) {
          nv.applicants.forEach((applicant, index) => {
            this.updateSectionProgress(totalApplicants, index);
          });
        }

        if (this.componentsLoaded && !this.applicationLoading) {
          this.onLocalSave();
          this.edit_after_load = true;
        }
        //save to local storage
      }));
  }
  
  performSubmission()
  {
    let data = {};
    data["bond_application"] = this.bondApplication.value;
    data["_method"] = "PUT";
    const fd = this._application_storage.objectToFormData(data);
    this.formLoading = true;

    this.formSubscriptions.add(

      this._application_storage.serversideFinalSave$(this.token, fd).subscribe((res) => {
        this.formLoading = false;
        this._toast_service.show(res["message"], { classname: 'bg-success text-light' });

        this.downloadApplication = res["data"] && res["data"]['application'] ? res["data"]['application'] : null;
        this.applicationStatus.completed = true;
        this.applicationStatus.message = res["message"];
        this.applicationStatus.success = true;

        this._application_storage.clearLocalStorage();
      }, (res) => {
        this.formLoading = false;

        let message   = res.message;
        let newErrors = {};

        if(res.error.errors){
          
          let tKeys = Object.keys(res.error.errors);

          tKeys.forEach((element) => {

            let newKey = element.replace("bond_application.", "");
            newErrors[newKey] = res.error.errors[element];

          })

        }

        if( res.status == 0 ){

          message = `Sorry something went wrong. Please check your internet connection or contact the administrator`;

        }

        this._toast_service.show(res.error.message?res.error.message:message, { classname: 'bg-danger text-light' });
        if (res.status == 422) {
          this._validation_service.setServerValidation(this.bondApplication, newErrors);
          this.focusOnErrors();
        }
    
      }
      ));
  }
  onSubmit() {    
    if(this.bondApplicationValid()) {
      if(!this.salesAgreementValid())
      {
        this.openPostModal(this.postModal);
      }
      else
      {
        this.performSubmission();
      }
    } else {
      this.shouldEvaluate = true;
      this._utility_service.markFormGroupTouched(this.bondApplication);
      this._utility_service.focusOnError();
      this.focusOnErrors();
      this._toast_service.show("Please ensure that your input is valid", { classname: 'bg-danger text-light' });
    }
    
  }

  bondApplicationValid() {

    let ignore_keys = ['sale_agreement'];

    if((this.bondApplication.get('application_type') as FormGroup).invalid) {
      return false;
    }
   
    for(let x = 0; x < (this.bondApplication.get('applicants') as FormArray).length; x++) {
      let keys = Object.keys(((this.bondApplication.get('applicants') as FormArray).controls[x] as FormGroup).controls);
      for(let y = 0; y < keys.length; y++) {
        if(ignore_keys.indexOf(keys[y]) == -1) {
          if(((this.bondApplication.get('applicants') as FormArray).controls[x] as FormGroup).get(keys[y]).invalid) {
            return false;
          }
        }
      }
    }
    return true;
  }

  salesAgreementValid() {

    for(let x = 0; x < (this.bondApplication.get('applicants') as FormArray).length; x++) {
     if(((this.bondApplication.get('applicants') as FormArray).controls[x] as FormGroup).get('sale_agreement').invalid) {
       return false;
     }
    }

    if((this.bondApplication.get('sale_agreement') as FormGroup).invalid) {
      return false;
    }

    return true;

  }

  updateSectionProgress(totalApplicants, index) {

    let contact_details = this._progressReport.progress((this.applicantGroup.at(index) as FormGroup).get('contact_details')) / totalApplicants;
    let personal_details = this._progressReport.progress((this.applicantGroup.at(index) as FormGroup).get('personal_details')) / totalApplicants;

    this.progress["Section A"] = ( index > 0 ? this.progress["Section A"] : 0) + (contact_details + personal_details) / 2;

    let juristic_application = this._progressReport.progress((this.applicantGroup.at(index) as FormGroup).get('juristic_application')) / totalApplicants;
    this.progress["Section B"] = ( index > 0 ? this.progress["Section B"] : 0) + juristic_application;

    let other_information = this._progressReport.progress((this.applicantGroup.at(index) as FormGroup).get('other_information')) / totalApplicants;
    this.progress["Section C"] = ( index > 0 ? this.progress["Section C"] : 0) + other_information;

    let employment_information = this._progressReport.progress((this.applicantGroup.at(index) as FormGroup).get('employment_information')) / totalApplicants;
    this.progress["Section D"] = ( index > 0 ? this.progress["Section D"] : 0) + employment_information;

    //financial
    let salary_deductions = this._progressReport.progress((this.applicantGroup.at(index) as FormGroup).get('salary_deductions')) / totalApplicants;
    let financial_details = this._progressReport.progress((this.applicantGroup.at(index) as FormGroup).get('financial_details')) / totalApplicants;
    let fixed_debt_repayment = this._progressReport.progress((this.applicantGroup.at(index) as FormGroup).get('fixed_debt_repayment')) / totalApplicants;
    let monthly_expenses = this._progressReport.progress((this.applicantGroup.at(index) as FormGroup).get('monthly_expenses')) / totalApplicants;
    let monthly_gross_income = this._progressReport.progress((this.applicantGroup.at(index) as FormGroup).get('monthly_gross_income')) / totalApplicants;
    this.progress["Section E"] = ( index > 0 ? this.progress["Section E"] : 0) + (salary_deductions + financial_details + fixed_debt_repayment + monthly_expenses + monthly_gross_income) / 5;

    let debit_order_details = this._progressReport.progress((this.applicantGroup.at(index) as FormGroup).get('debit_order_details'));
    this.progress["Section F"] = ( index > 0 ? this.progress["Section F"] : 0) + debit_order_details;

    let loan_details = this._progressReport.progress((this.applicantGroup.at(index) as FormGroup).get('loan_details'))
    this.progress["Section G"] = ( index > 0 ? this.progress["Section G"] : 0) + loan_details;

    let property_details = this._progressReport.progress((this.applicantGroup.at(index) as FormGroup).get('property_details'));
    this.progress["Section H"] = ( index > 0 ? this.progress["Section H"] : 0) + property_details;


    let prominentInfluentialPersons = this._progressReport.progress((this.applicantGroup.at(index) as FormGroup).get('prominent_influential_persons')) / totalApplicants;
    this.progress["Section I"] = ( index > 0 ? this.progress["Section I"] : 0) + prominentInfluentialPersons;

    let declaration = this._progressReport.progress((this.applicantGroup.at(index) as FormGroup).get('declaration')) / totalApplicants;
    this.progress["Section J"] = ( index > 0 ? this.progress["Section J"] : 0) + declaration;

    let document_uploads = this._progressReport.getFileProgress();
    let doc_complete = 0;
    if(document_uploads) {
      for(let x = 0; x < document_uploads.length; x++) {
        doc_complete +=  document_uploads[x];
      }
    }
    this.progress["Section K"] = doc_complete / totalApplicants;
  }
  
  isSaleAgreementIncomplete()
  {
    for (let applicant of this.applicantGroup.controls) 
    {
      let app_sale_agreement = applicant.get('sale_agreement');
      if(!app_sale_agreement)
      {
        return true
      }else if(app_sale_agreement && app_sale_agreement.invalid)
      {
          return true;
      }
    }
    return false;
  }
  
  focusOnErrors()
  {
    this.errorSection = [];
  
    let applicationInvalid;
    
    if(this.firstApplicant && !this.secondApplicant)
    {
      applicationInvalid = this._validation_service.formControlsInValid((this.firstApplicant as FormGroup),["sale_agreement"]);
    }

    if(this.firstApplicant && this.secondApplicant)
    {
      applicationInvalid = this._validation_service.formControlsInValid((this.firstApplicant as FormGroup),["sale_agreement"]) && this._validation_service.formControlsInValid((this.secondApplicant as FormGroup),["sale_agreement"]);
    }

    //if the application without the saleAgreement is invalid
    if(!applicationInvalid && this.isSaleAgreementIncomplete())
    {
      this.mainNav.select('nav-agreement');
      //scroll to the sale agreement
      let activeElement = document.getElementById("sale-agreement-panel");
      activeElement.scrollIntoView({
        behavior: "smooth"
      });
      
    }else
    {
      this.mainNav.select('nav-application-form');
      if(this.firstApplicant)
      {
        this.errorSection.push(this._application_storage.sectionError(this.firstApplicant));
      }
      if(this.secondApplicant)
      {
       this.errorSection.push(this._application_storage.sectionError(this.secondApplicant,[
         'section_f',
         'section_g',
         'section_h'
         ]));  
      }
  
      if((this.secondApplicant && this.secondApplicant.invalid) && (this.firstApplicant && !this.firstApplicant.invalid))
      {
          this.active = 2;
      }else
      {
        this.active = 1;
      }
    }
 
    
    this._utility_service.focusOnError();
  }

  serverSideDataLoaded(data) 
  {
    this._application_storage.setData(this.bondApplication, data["data"]["bond_application"]);
    this.cd.detectChanges();
    this._application_storage.setData(this.bondApplication, data["data"]["bond_application"]);

    this._toast_service.show(data["message"], { classname: 'bg-success text-light' });
  }

  onLocalSave() {
    if (this._application_storage.allowLocalApplication()) {
      let applicationToSave = this._application_storage.removeUnkeptSections(this.bondApplication.value);
      this._application_storage.localSave(applicationToSave, `${this.activeUser.email}/${this.token}`);
    }
  }

  onLocalLoad() {
    let applicationForm = this._application_storage.localLoad(`${this.activeUser.email}/${this.token}`);
    this._application_storage.setData(this.bondApplication, applicationForm);
  }

  toggleSecondApplicant(add) {

    if (add && !this.secondApplicant) {
      this.applicantGroup.push(new FormGroup({}));
    }
    else if (add == false && this.secondApplicant) {
      this.active = 1;
      this.applicantGroup.removeAt(1);
    }
  }

  updateFormFields(changes): void {
    let applicationType = changes.new_value;

    this.toggleSecondApplicant(applicationType == "Joint Account");
    this.cd.detectChanges();

    this.addPreviouslyExcludedValues()
    this.formTypes.exclusion_list[applicationType];
    let applications = this.applicantGroup.controls;
    this._application_storage.setApplicantsCount$(applications.length);
    applications.forEach((application, index) => {
      let removeControlObject = {};
      let formGroups = [];

      if (applicationType) {
        formGroups = Object.keys(this.formTypes.exclusion_list[applicationType]);
      }

      formGroups.forEach(formGroup => {
        removeControlObject[formGroup] = {};

        this.formTypes.exclusion_list[applicationType][formGroup].forEach(field => {

          if (application != null) {
            let group = (application.get(formGroup) as FormGroup)
            if (group) {
              let control = group.get(field);
              removeControlObject[formGroup][field] = control;
              (application.get(formGroup) as FormGroup).removeControl(field);
            }
          }
        });

        this.removedControls[index] = removeControlObject;


      });


    });


  }

  addPreviouslyExcludedValues(): void {

    this.removedControls.forEach((removedControlGroup, index) => {

      let formGroups = Object.keys(removedControlGroup);

      formGroups.forEach(formGroup => {
        let fields = Object.keys(removedControlGroup[formGroup]);

        fields.forEach(field => {

          let group = (this.applicantGroup.at(index) as FormGroup);
          if(group)
          {
            (group.controls[formGroup] as FormGroup).addControl(field, removedControlGroup[formGroup][field]);
          }

        });
      });

    });
    //after the have been readded clear the list
    this.removedControls = [];
  }

  open(content) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
      keyboard: false

    }).result.then((result) => {
      if (result) {
        this._application_storage.allowLocalStore();
      }
    });
  }

  openPostModal(content) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
      keyboard: false

    }).result.then((result) => {
      if (result) {
        this.focusOnErrors();
      }
    });
  }

  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  onPartialSave(): void {
    let application_to_save = this._application_storage.removeUnkeptSections(this.bondApplication.value);
    const data = {
      "_method": "PUT",
      "bond_application": application_to_save,
    };



      this.formLoading = true;
      const fd = this._application_storage.objectToFormData(data);
      this._application_storage.serverSideSave$(this.token, fd).subscribe(data => {
        this.formLoading = false;
        this._toast_service.show(data["message"], { classname: 'bg-success text-light' });
      }, (res) => {
        this.formLoading = false;
      });
 
  }


  get displaySubmitButtons()
  {
    return ["nav-application-form"].includes(this.activeForm);
  }



}
