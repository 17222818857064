<div class="table-responsive">

<table class="table table-striped" [formGroup]="salary_deductions">
   <thead>
      <tr>
         <th scope="col-md">Salary Deductions</th>
         <th scope="col-md"></th>
      </tr>
   </thead>
    <tbody>
        <tr>
            <th scope="row">Income Tax</th>
            <td>
               <div class="form-group">
                  <input currencyMask formControlName="income_tax" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':salary_deductions.get('income_tax').invalid && (salary_deductions.get('income_tax').dirty || salary_deductions.get('income_tax').touched) }" id="income_tax-field">
                  <app-invalid-feedback  class="invalid-feedback" name="Income Tax" [control]="salary_deductions.get('income_tax')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
               </div>
            </td>
          </tr>
          <tr>
            <th scope="row">Unemployment Insurance Fund</th>
            <td>
               <div class="form-group">
                  <input currencyMask formControlName="unemployment_insurance_fund" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':salary_deductions.get('unemployment_insurance_fund').invalid && (salary_deductions.get('unemployment_insurance_fund').dirty || salary_deductions.get('unemployment_insurance_fund').touched) }" id="unemployment_insurance_fund-field">
                  <app-invalid-feedback  class="invalid-feedback" name="Unemployment Insurance Fund" [control]="salary_deductions.get('unemployment_insurance_fund')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
               </div>
            </td>
          </tr>
          <tr>
            <th scope="row">Pension</th>
            <td>
               <div class="form-group">
                  <input currencyMask formControlName="pension" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':salary_deductions.get('pension').invalid && (salary_deductions.get('pension').dirty || salary_deductions.get('pension').touched) }" id="pension-field">
                  <app-invalid-feedback  class="invalid-feedback" name="Pension" [control]="salary_deductions.get('pension')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
               </div>
            </td>
          </tr>
          <tr>
            <th scope="row">Medical Aid</th>
            <td>
               <div class="form-group">
                  <input currencyMask formControlName="medical_aid" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':salary_deductions.get('medical_aid').invalid && (salary_deductions.get('medical_aid').dirty || salary_deductions.get('medical_aid').touched) }" id="pension-field">
                  <app-invalid-feedback  class="invalid-feedback" name="Medical Aid" [control]="salary_deductions.get('medical_aid')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
               </div>
            </td>
          </tr>
          <tr>
            <th scope="row">Other (Please specify)</th>
            <td>
               <div class="form-group">
                  <input currencyMask formControlName="other_specify" type="input" (focus)="$event.target.select()" class="form-control" [ngClass]="{'is-invalid':salary_deductions.get('other_specify').invalid && (salary_deductions.get('other_specify').dirty || salary_deductions.get('other_specify').touched) }" id="other_specify-field">
                  <app-invalid-feedback  class="invalid-feedback" name="Other" [control]="salary_deductions.get('other_specify')" [messages]="{required:requiredMessage}"></app-invalid-feedback>
               </div>
               <div class="form-group">
                  <input formControlName="other_specification" type="input" class="form-control" [ngClass]="{'is-invalid':salary_deductions.get('other_specification').invalid && (salary_deductions.get('other_specification').dirty || salary_deductions.get('other_specification').touched) }" id="other_specification-field" placeholder="Please Specify Other">
                  <app-invalid-feedback  class="invalid-feedback" name="Please Specify" [control]="salary_deductions.get('other_specification')"></app-invalid-feedback>
               </div>
            </td>
          </tr>
          <tr>
            <th scope="row">Total of all payslip items</th>
            <td>
               <div class="form-group">
                  <span class="text-right">
                     {{ salary_deductions_total | currency:'R' }}
                  </span>
               </div>
            </td>
         </tr>
    </tbody>
</table>
</div>