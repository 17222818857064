<div *ngIf="development" class="img-header" [style.backgroundImage]="'url('+development.header_image_url+')'">

    <nav class="navbar navbar-expand-lg navbar-dark p-0">
        <div class="container pt-2">
            <a class="navbar-brand" href="#"> 

                <img *ngIf="development.logo_image_url" [src]="development.logo_image_url" alt="logo"class="image-nav">
                <h2 class="pt-4" *ngIf="!development.logo_image_url">{{development.name}}</h2>

            </a>
            <button class="navbar-toggler nav-btn" type="button" aria-label="Toggle navigation"
                (click)="navbarCollapsed = !navbarCollapsed" [attr.aria-expanded]="!navbarCollapsed"
                aria-expanded="false" aria-controls="navbarContent">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="w-30">

            </div>
            <div class="navbar-collapse p-2" [ngbCollapse]="navbarCollapsed" id="navbarContent">
                <ul class="navbar-nav mr-auto" *ngIf="development">
                    <li class="nav-item" *ngFor="let data of development.meta_pairs.menu_items">
                        <a [href]="data.url" class="w-100 text-white link-style nav-link" target="_blank">{{data.label}}</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

</div>