import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    })
  };
  constructor(
    private http: HttpClient,
  ) { }

  paymentStatus$(id,status,params)
  {
    let httpParams = new HttpParams();
    //Loop through the parameters
    Object.keys(params).forEach(function (key) {
        //only add the params if the have values
        if (params[key]) {
            //Add the parameter to the HTTP Parameters used in the request
            httpParams = httpParams.append(key, params[key]);
        }
    });
    let httpOptions = {};
    httpOptions = Object.assign(httpOptions, this.httpOptions)
    httpOptions['params'] = httpParams;

    return this.http.get(`${environment.api.base_url}/payment/${id}/status/${status}`,httpOptions)
  }
}
