import { Injectable, ApplicationRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { serialize } from 'object-to-formdata';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ProgressReportService } from './progress-report.service';
import { Paginate } from 'src/app/models/paginate';


@Injectable({
  providedIn: 'root'
})
export class ApplicationStorageService {
  
  httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    })
  };

  //keys used for local storage
  local_key = "application_form";
  use_local_storage = "store_locally";
  server_side_exist_key = "server_side_exist";
  bondApplicationForm:FormGroup;
  applicantsCount$ = new Subject<number>();



  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private progressReport: ProgressReportService
    ) {
    
  }
  
  getBondApplicationForm():FormGroup
  {
    return this.bondApplicationForm;
  }

  setBondApplicationForm(bondApplicationForm)
  {
    this.bondApplicationForm = bondApplicationForm;
  }

  setApplicationJointType(setAsJoint)
  {
    if(setAsJoint && this.bondApplicationForm.get("application_type.application_type"))
    {
      this.bondApplicationForm.get("application_type.application_type").setValue("Joint Account");
    }
  }

  setApplicantsCount$(count)
  {
    return this.applicantsCount$.next(count);
  }

  getApplicantsCount$()
  {
    return this.applicantsCount$;
  }

  localSave(applicationForm, key_suffix = null)
  {
    //handle extras in a different way
    let key = key_suffix ? `${this.local_key}_${key_suffix}` :this.local_key;
    localStorage.setItem(key,JSON.stringify(applicationForm));
  }

  getApplicationToken$(token): any
  {
    return this.http.get(`${environment.api.base_url}/applications/${token}/zip/token`,this.httpOptions)
  }


  getServerSideExist()
  {
    return JSON.parse(localStorage.getItem(this.server_side_exist_key));
  }

  serverSideSave$(token,applicationForm)
  {
  
    return this.http.post(`${environment.api.base_url}/applications/${token}/partial-save`, applicationForm)
  }

  serversideFinalSave$(token,applicationForm)
  {
    return this.http.post(`${environment.api.base_url}/bond-applications/${token}/final-save`, applicationForm)
  }

  bond_application_selection$(token,applicationForm)
  {
    return this.http.patch(`${environment.api.base_url}/applications/${token}/bond-application-selection`, applicationForm)
  }

  documents$(token)
  {
    return this.http.get(`${environment.api.base_url}/applications/${token}/documents`);
  }

  payDeposit$(token,unitId,data)
  {
    return this.http.post(`${environment.api.base_url}/applications/${token}/unit/${unitId}/purchase`,data); 
  }

  revisions$(token,params = {})
  {
    let httpParams = new HttpParams();
    
    Object.keys(params).forEach(function (key) {
        if (params[key]) {
            httpParams = httpParams.append(key, params[key]);
        }
    });

    let httpOptions = {};
    httpOptions 	= Object.assign(httpOptions, this.httpOptions)

    httpOptions['params'] = httpParams;

    return this.http.get(`${environment.api.base_url}/applications/${token}/revision-history`,httpOptions).pipe(map((res)=>{
        let paginator = new Paginate();
        return paginator.fromJson(res['data']); 
    }));  
  }

 /**
	 * Handles API request to get a list of Media Library files
	 * @return {Obserable} - An Observable that can be subscribed to, to send the HTTP request
	 */
	list$(params = {}) {
    let httpParams = new HttpParams();
    
    Object.keys(params).forEach(function (key) {
        if (params[key]) {
            httpParams = httpParams.append(key, params[key]);
        }
    });

    let httpOptions = {};
    httpOptions 	= Object.assign(httpOptions, this.httpOptions)

    httpOptions['params'] = httpParams;

    return this.http.get(`${environment.api.base_url}/applications`,httpOptions).pipe(map((res)=>{
        let paginator = new Paginate();
         if(res['data'] && res['data']['data'])
         {
            res['data']['data'] = res['data']['data'].map(app => { 
              app.action_url = app.status.code == "awt_dpst_slct" ? ['/application/pay-deposit/',app.token] : ['/application',app.token];
              return app;
            });
         }
        return paginator.fromJson(res['data']); 
    }));

}

  get$(token)
  {
    return this.http.get(`${environment.api.base_url}/applications/${token}`,this.httpOptions)
  }

  thankYou$(token)
  {
    return this.http.get(`${environment.api.base_url}/applications/${token}/thank-you`,this.httpOptions)
  }

  formdata$(token)
  {
    return this.http.get(`${environment.api.base_url}/applications/${token}/form-data`);
  }

  submitFicaDocuments$(token, ficaData) {
    return this.http.post(`${environment.api.base_url}/applications/${token}/fica-documents`, ficaData);
  }

  submitManualApplication$(token, formData) {
    return this.http.post(`${environment.api.base_url}/bond-applications/${token}/final-save:manual`, formData);
  }

  toggleManualApplication$(token, state) {
    return this.http.put(`${environment.api.base_url}/applications/${token}/toggle-manual-state?is_manual=${state ? 1 : 0}`, null);
  }

  clearLocalStorage()
  {
    localStorage.removeItem(this.local_key);
  }
  
  allowLocalApplication()
  {
    return JSON.parse(localStorage.getItem(this.use_local_storage));
  }

  allowLocalStore()
  {
    localStorage.setItem(this.use_local_storage,JSON.stringify(true));
  }

  localLoad(key_suffix = null)
  {
    let key = key_suffix ? `${this.local_key}_${key_suffix}` :this.local_key;
    return JSON.parse(localStorage.getItem(key));
  }

  initialServerSideLoad$(token)
  {
    return this.http.get(`${environment.api.base_url}/bond-applications/${token}/initial`, this.httpOptions)
  }

  removeUnkeptSections(bondApplicationForm)
  {
    let object = _.cloneDeep(bondApplicationForm);
    
    for(let x = 0; x < object['applicants'].length; x++) {
      delete object['applicants'][x]['document_uploads'];
      delete object['applicants'][x]['declaration'];
     }

    return object;
  }

  setData(bondApplicationForm:FormGroup,data)
  {
    if(bondApplicationForm.get('application_type.application_type') && data.application_type)
    {
      (bondApplicationForm.get('application_type.application_type') as FormControl).setValue(data.application_type.application_type);
    }

    if(bondApplicationForm.get('application_type.preferred_banks') && data.application_type)
    {
      let banks = [];
      if(data.application_type.preferred_banks)
      {
        banks = data.application_type.preferred_banks;
      }
      (bondApplicationForm.get('application_type') as FormGroup).setControl('preferred_banks',this.fb.array(banks, [Validators.minLength(2),Validators.required]));
    }

    if(bondApplicationForm.get('extras_group') && data.extras_group)
    {
      let extras = [];
      if(data.application_type.preferred_banks)
      {
        extras = data.extras_group.extras;
      }
      (bondApplicationForm.get('extras_group') as FormGroup).setControl('extras',this.fb.array(extras));
    }
    
    setTimeout(()=>{ 
     bondApplicationForm.patchValue(data);
    }, 1);
  }

  objectToFormData(object){

     
    const options = {
      /**
       * include array indices in FormData keys
       * defaults to false
       */
      indices: true,
     
      /**
       * treat null values like undefined values and ignore them
       * defaults to false
       */
      nullsAsUndefineds: false,
     
      /**
       * convert true or false to 1 or 0 respectively
       * defaults to false
       */
      booleansAsIntegers: true,
    };
     
    const formData = serialize(
      object,
      options, // optional
    );
     return formData;
  };

  /**
   * Asses Sections
   * Asses is the fom controll is valid and is ready to be 
   * submitted tot he server
   * 
   * @param {Array<string>} group
   * @param {Array<FormControl>} control
   * @param {Array<string>} exclude
   * @return {boolean}
   */
  asesSection(group:Array<string>,control:Array<FormControl>,exclude:Array<string>):boolean{

    let assesment = <boolean>false;

    /**
     * Iterate over sections
     */
    for (var i = group.length - 1; i >= 0; i--) {

      /**
       * Is section part of the validation exclusion list
       */
      if(exclude.includes(group[i])) {

        assesment = false;
        continue;

      }

      let positive = true;

      /**
       * Iterate over section assosiated controls
       */
      for (var i = control.length - 1; i >= 0; i--) {

        /**
         * Attempt to disprove the assumed positive validation of section
         */
        if(typeof control[i] == 'undefined'){
          positive = false;
          continue;
        }

        positive = control[i].invalid

      }

      assesment = positive;
    
    }

    return assesment;

  }

  sectionError(applicantGroup,exclude=[])
  {

    let progress = {
      "section_a":false,
      "section_b":false,
      "section_c":false,
      "section_d":false,
      "section_e":false,
      "section_f":false,
      "section_g":false,
      "section_h":false,
      "section_i":false,
      "section_j":false,
      "section_k":false
    }  

    /**
     * ## Dry up code block ## 
     *
     * @todo Contruct sections to named FormGroup(s) by section https://angular.io/api/forms/FormGroup#formgroup 
     * allowing iteration over progress / sections.
     *
     * Iteration not posobile on progress as form controls are ot group by form group section making the keys
     * inconsistant and oly accessable directly, i have left the old process code as incase we need to revert 
     * in a rush. 
     *
     */
    progress["section_a"] = this.asesSection(['section_a'],[applicantGroup.get('contact_details'),applicantGroup.get('personal_details')],exclude);
    progress["section_b"] = this.asesSection(['section_b'],[applicantGroup.get('juristic_application')],exclude)
    progress["section_c"] = this.asesSection(['section_c'],[applicantGroup.get('other_information')],exclude)
    progress["section_d"] = this.asesSection(['section_d'],[applicantGroup.get('employment_information')],exclude)
    progress["section_e"] = this.asesSection(['section_e'],[applicantGroup.get('salary_deductions'),applicantGroup.get('financial_details'),applicantGroup.get('fixed_debt_repayment'),applicantGroup.get('monthly_expenses'),applicantGroup.get('monthly_gross_income')],exclude)
    progress["section_f"] = this.asesSection(['section_f'],[applicantGroup.get('debit_order_details')],exclude)
    progress["section_g"] = this.asesSection(['section_g'],[applicantGroup.get('loan_details')],exclude)
    progress["section_h"] = this.asesSection(['section_h'],[applicantGroup.get('property_details')],exclude)
    progress["section_i"] = this.asesSection(['section_i'],[applicantGroup.get('prominent_influential_persons')],exclude)
    progress["section_j"] = this.asesSection(['section_j'],[applicantGroup.get('declaration')],exclude)
    progress["section_k"] = this.asesSection(['section_j'],[applicantGroup.get('declaration')],exclude)
    progress["section_k"] = this.progressReport.getFileProgress() < 100;

    //let contact_details   = applicantGroup.get('contact_details');
    //let personal_details  = applicantGroup.get('personal_details');
    //progress["section_a"] =  (contact_details.invalid && personal_details.invalid);

    //let juristic_application = applicantGroup.get('juristic_application');
    //progress["section_b"] = juristic_application.invalid;


    //let other_information = applicantGroup.get('other_information');
    //progress["section_c"] = other_information.invalid;

    //let employment_information = applicantGroup.get('employment_information');
    //progress["section_d"] = employment_information.invalid;

    //financial
    //let salary_deductions = applicantGroup.get('salary_deductions');
    //let financial_details = applicantGroup.get('financial_details');
    //let fixed_debt_repayment = applicantGroup.get('fixed_debt_repayment');
    //let monthly_expenses = applicantGroup.get('monthly_expenses');
    //let monthly_gross_income = applicantGroup.get('monthly_gross_income');
    //progress["section_e"] = (salary_deductions.invalid && financial_details.invalid && fixed_debt_repayment.invalid && monthly_expenses.invalid && monthly_gross_income.invalid) ;

    //let debit_order_details = applicantGroup.get('debit_order_details');
    //progress["section_f"] =  debit_order_details.invalid;

    //let loan_details = applicantGroup.get('loan_details');
    //progress["section_g"] =  loan_details.invalid;


    //let property_details = applicantGroup.get('property_details');
    //progress["section_h"] = property_details.invalid;


    //let prominentInfluentialPersons = applicantGroup.get('prominent_influential_persons');
    //progress["section_i"] = prominentInfluentialPersons.invalid;

    //let declaration = applicantGroup.get('declaration');
    //progress["section_j"] = declaration.invalid;
    
    //progress["section_k"] = this.progressReport.getFileProgress() < 100;

    return progress;

  }

}
