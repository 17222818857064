import { Component, Input, OnInit } from '@angular/core';
import { ApplicationStorageService } from 'src/app/application/services/application-storage.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-application-documents',
  templateUrl: './application-documents.component.html',
  styleUrls: ['./application-documents.component.scss']
})
export class ApplicationDocumentsComponent implements OnInit {

  @Input() application;
  applicationLoading: boolean;
  dataLoading:boolean;
  documents: any;
  constructor(
    private _bond_application_storage: ApplicationStorageService,
    private _toast_service: ToastService,
  ) { }

  ngOnInit(): void {
    this.initDocuments();
  }

  initDocuments()
  {
      this.documents = [];
      this.dataLoading = true;
      this._bond_application_storage.documents$(this.application.token)
      .subscribe((data) => {
        this.dataLoading = false;
        this.documents = data;
      }, (res) => {
        this.dataLoading = false;
        this._toast_service.show(res.error.message, { classname: 'bg-danger text-light' });
      });
  }

}
