<app-bond-application-header [development]="applicationData.development"></app-bond-application-header>
<div class="container">
  <div class="mt-5">

    <div *ngIf="showSaveWarning" class="alert alert-info">
      You have updated your application form, do not forget to save your progress to the server
    </div>

    <h6>{{ formTitle }}</h6>

    <hr />
    <!-- <p>Please upload your documents below and complete the online application form. If at any time you wish to rather
      pay a R5 000 refundable deposit
      Please click the button below.</p>
    <button class="btn btn-primary mb-2">Pay Deposit</button>
  </div> -->
    <ng-container *ngIf="applicationStatus.completed">
      <div class="container">
        <div class="card craft-card bg-light mt-5">
            <div class="card-body">
                <div class="row">
                    <fa-icon [icon]="checkCircle" class="fa-5x fa-icon d-block m-auto"></fa-icon>
                </div>
                <h3 class="text-center mb-4">Thank you</h3>
                <h5 class="text-center mb-4">Your application has been submitted ({{downloadApplication.updated_at | date }})</h5>
                <p class="text-center">
                    {{ applicationStatus.message }}
                </p>
                <P class="text-center mb-4">If you have any queries, please contact <a href="tel:0860027238">086 002 7238</a> or <a href="mailto:info@crafthomes.co.za">info@crafthomes.co.za</a></P>
                <div class="row mb-4 d-flex justify-content-center" >
                    <a class="btn btn-primary mr-3" [routerLink]="'/'">
                        Return to Dashboard
                    </a>
                    <ng-container *ngIf="downloadApplication">
                      <app-application-download [application]="downloadApplication" ></app-application-download>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>    
    </ng-container>
    <div *ngIf="!applicationStatus.completed" class="card" [appLoader]="applicationLoading">


      <form [formGroup]="bondApplication" #form="ngForm" (ngSubmit)="onSubmit()">
        <ul ngbNav #mainNav="ngbNav" [(activeId)]="activeForm" class="nav-tabs main-tabs" [destroyOnHide]="false">
          <li [ngbNavItem]="'nav-application-form'">
            <a ngbNavLink>APPLICATION FORM</a>
            <ng-template ngbNavContent>
              <app-progress-report [active]="active" [progress]="progress"></app-progress-report>
              <div class="card-body form-body">
                <div class="container-fluid">
                  <div class="d-flex flex-column align-items-md-center flex-md-row justify-content-end">
                    <button class="btn btn-primary mt-2 mr-1" type="submit">Submit</button>
                    <button class="btn btn-outline-primary mt-2 mr-1" (click)="onPartialSave()" type="button">Save</button>
                  </div>
                  <div class="my-4 text-right">
                    If you are struggling with the online bond application, <a href="#" (click)="toggleManualApplication($event)">click here</a> to switch to a manual PDF bond application.
                  </div>

                  <div class="row">
                    <div class="col">
                      <app-application-type [bondApplication]="bondApplication" [formData]="formData"
                        (changeApplicationType)="updateFormFields($event)"></app-application-type>

                      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs" [destroyOnHide]="false">
                        <li [ngbNavItem]="1">
                          <a ngbNavLink [ngClass]="{'d-none': !secondApplicant}">First Applicant</a>
                          <ng-template ngbNavContent>
                            <app-bond-application-form [bondApplication]="firstApplicant"
                              [errorSection]="errorSection[0]" [index]="0" [formData]="formData" [shouldEvaluate]="shouldEvaluate" (partialSave)="onPartialSave()">
                              <button class="btn btn-outline-primary mt-2 mr-1" (click)="onPartialSave()" type="button">TEST Save</button>
                            </app-bond-application-form>
                          </ng-template>
                        </li>
                        <li [ngbNavItem]="2" *ngIf="secondApplicant">
                          <a ngbNavLink>Second Applicant</a>
                          <ng-template ngbNavContent>
                            <app-bond-application-form [bondApplication]="secondApplicant"
                              [errorSection]="errorSection[1]" [index]="1" [formData]="formData" [shouldEvaluate]="shouldEvaluate" (partialSave)="onPartialSave()">
                            </app-bond-application-form>
                          </ng-template>
                        </li>
                      </ul>
                      <div [ngbNavOutlet]="nav"></div>
                    </div>
                  </div>

                  <ng-template #modal let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title" id="modal-basic-title">Please Note:</h4>
                    </div>
                    <div class="modal-body">
                      <p>
                        Welcome to the Craft Homes Buy Online Application process.
                      </p>
                      <p>
                        We value your privacy and would like to inform you that your <strong><u>sensitive information will be
                        stored on your browser</u></strong> therefore anyone who has access to your browser may access this
                        information without any need of your authentication.
                      </p>
                      <p>
                        We have taken all necessary precautions to ensure your information is safe with Craft Homes and
                        want to remind you to please save your application often to ensure the information will not be
                        lost.
                      </p>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-outline-success" (click)="modal.close(true)">Yes</button>
                      <button type="button" class="btn btn-outline-danger" (click)="modal.close(false)">No</button>
                    </div>
                  </ng-template>


                  <ng-template #postModal let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title" id="modal-basic-title">Cannot submit yet</h4>
                      <button type="button" class="close" aria-label="Close" (click)="modal.close(false)">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                
                      <div *ngIf="bondApplication.get('sale_agreement').invalid">
                        Please ensure that you have completed your Agreement of Sale.
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-outline-primary" (click)="modal.close(false)">Go
                        Back</button>
                      <button type="button" class="btn btn-outline-success" (click)="modal.close(true)">Continue
                        Submission</button>
                    </div>
                  </ng-template>

                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="'nav-agreement'">
            <a ngbNavLink>AGREEMENT OF SALE</a>
            <ng-template ngbNavContent>
              <div class="card-body form-body">
                <div class="d-flex flex-column align-items-md-center flex-md-row justify-content-end">
                  <button class="btn btn-primary mt-2 mr-1" type="submit">Submit</button>
                  <button class="btn btn-outline-primary mt-2 mr-1" (click)="onPartialSave()" type="button">Save</button>
                </div>
                <app-sale-agreement [formData]="formData" [bondApplication]="bondApplication"></app-sale-agreement>
                <div class="d-flex flex-column align-items-md-center flex-md-row justify-content-end">
                  <button class="btn btn-primary mt-2 mr-1" type="submit">Submit</button>
                  <button class="btn btn-outline-primary mt-2 mr-1" (click)="onPartialSave()" type="button">Save</button>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="'nav-finishes'">
            <a ngbNavLink>FINISHES</a>
            <ng-template ngbNavContent>
              <div class="card-body form-body">
                <app-finishes [bondApplication]="bondApplication" [formData]="formData"></app-finishes>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="'nav-extras'">
            <a ngbNavLink>EXTRAS</a>
            <ng-template ngbNavContent>
              <div class="card-body form-body">
                <app-extras [bondApplication]="bondApplication" [formData]="formData"></app-extras>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="mainNav"></div>


        <div class="card-body form-body">
          <div class="row mt-5">
            <div class="col">
              <hr>
            </div>
          </div>
         <ng-container *ngIf="displaySubmitButtons">
            <div 
            class="d-flex flex-column align-items-md-center flex-md-row justify-content-end">
            <button class="btn btn-primary mt-2 mr-1" type="submit">Submit</button>
            <button class="btn btn-outline-primary mt-2 mr-1" (click)="onPartialSave()" type="button">Save</button>
          </div>
            <div class="my-4 text-right">
              If you are struggling with the online bond application, <a href="#" (click)="toggleManualApplication($event)">click here</a> to switch to a manual PDF bond application.
            </div>
         </ng-container>

          <button class="btn btn-primary position-fixed" type="button" style="bottom: 1rem; right: 1rem;" *ngIf="isShow"
            (click)="gotoTop()">
            <fa-icon [icon]="iconUp"></fa-icon>
          </button>

          <div class="row">
            <div class="col">
              <h6>*PLEASE NOTE</h6>
              <p>
                Welcome to the Craft Homes Buy Online Application process.
              </p>
              <p>
                We value your privacy and would like to inform you that your <strong><u>sensitive information will be stored on
                your browser</u></strong> therefore anyone who has access to your browser may access this information without any
                need of your authentication.
              </p>
              <p>
                We have taken all necessary precautions to ensure your information is safe with Craft Homes and want to
                remind you to please save your application often to ensure the information will not be lost.
              </p>
            </div>

          </div>
          <div class="row">
            <div class="col">
              <img src="assets/image/craft_logo.png" width="200" alt="craft-logo" class="float-right">
            </div>
          </div>
        </div>


      </form>

    </div>
  </div>
