import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { RouterModule } from '@angular/router';
import { ImpersonationNoticeComponent } from './components/impersonation-notice/impersonation-notice.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImpersonationRequestComponent } from './components/impersonation-request/impersonation-request.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [TopNavComponent, ImpersonationNoticeComponent,ImpersonationRequestComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports:
  [
    TopNavComponent,
    ImpersonationNoticeComponent,
    ImpersonationRequestComponent
  ]
})
export class CoreModule { }
