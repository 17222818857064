import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ApplicationStorageService } from 'src/app/application/services/application-storage.service';
import { Paginate } from 'src/app/models/paginate';

@Component({
  selector: 'app-application-history',
  templateUrl: './application-history.component.html',
  styleUrls: ['./application-history.component.scss']
})
export class ApplicationHistoryComponent implements OnInit {

  @Input() application;
  public revisionPagination: Paginate;
	public revisionLoading: boolean;
	public queryParams = <any>{};
	public subscriptions = {
    	revisions:null,
  };
  numApplications: number;

	public searchForm = new FormGroup({
		search: new FormControl('', Validators.required),
	})

	get search(): any {
  	return this.searchForm.get('search');
	}

  constructor(
    private _activated_route: ActivatedRoute,
    private _router: Router,
    private _application_service: ApplicationStorageService
  ) { 
  
 
  }

  ngOnInit(): void {
    this.queryParams = {};
    this.loadRevisions();
  }

  
	loadRevisions() {
    this.revisionLoading = true;

    this.subscriptions.revisions = this._application_service.revisions$(this.application.token)
    .pipe( finalize(()=> this.revisionLoading = false ))
    .subscribe((res)=> {

      if(res.data.length == 0 && this.application.status.code == 'awt_cmpl') { 
        this._router.navigate([this.application.development.code,'bond-application',this.application.token]);
      } 

      this.revisionPagination = res;
      this.numApplications = this.revisionPagination.data.length;
    })
	}


	ngOnDestroy(): void {
    Object.keys(this.subscriptions).forEach(s=>{
      this.subscriptions[s].unsubscribe();
    })
  }

}
