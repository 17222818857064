import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SignaturePadModule } from 'angular2-signaturepad';
import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



import { SharedModule } from '../shared/shared.module';
import { UserModule } from '../user/user.module';
import { BondApplicationFormComponent } from './components/main-form/bond-application-form/bond-application-form.component';
import { ApplicationTypeComponent } from './components/main-form/bond-application-form/sections/application-type/application-type.component';
import { ContactDetailsComponent } from './components/main-form/bond-application-form/sections/contact-details/contact-details.component';
import { DebitOrderDetailsComponent } from './components/main-form/bond-application-form/sections/debit-order-details/debit-order-details.component';
import { DeclarationComponent } from './components/main-form/bond-application-form/sections/declaration/declaration.component';
import { DocumentUploadsComponent } from './components/main-form/bond-application-form/sections/document-uploads/document-uploads.component';
import { EmploymentInformationComponent } from './components/main-form/bond-application-form/sections/employment-information/employment-information.component';
import { ExtrasComponent } from './components/main-form/bond-application-form/sections/extras/extras.component';
import { FinancialDetailsComponent } from './components/main-form/bond-application-form/sections/financial-details/financial-details.component';
import { FinishesComponent } from './components/main-form/bond-application-form/sections/finishes/finishes.component';
import { FixedDebtRepaymentsComponent } from './components/main-form/bond-application-form/sections/fixed-debt-repayments/fixed-debt-repayments.component';
import { JuristicApplicationsComponent } from './components/main-form/bond-application-form/sections/juristic-applications/juristic-applications.component';
import { LoanDetailsComponent } from './components/main-form/bond-application-form/sections/loan-details/loan-details.component';
import { MonthlyExpensesComponent } from './components/main-form/bond-application-form/sections/monthly-expenses/monthly-expenses.component';
import { MonthlyGrossIncomeComponent } from './components/main-form/bond-application-form/sections/monthly-gross-income/monthly-gross-income.component';
import { OtherInformationComponent } from './components/main-form/bond-application-form/sections/other-information/other-information.component';
import { PersonalDetailComponent } from './components/main-form/bond-application-form/sections/personal-detail/personal-detail.component';
import { ProminentInfluentialPersonsComponent } from './components/main-form/bond-application-form/sections/prominent-influential-persons/prominent-influential-persons.component';
import { PropertyDetailsComponent } from './components/main-form/bond-application-form/sections/property-details/property-details.component';
import { SalaryDeductionsComponent } from './components/main-form/bond-application-form/sections/salary-deductions/salary-deductions.component';
import { SalleryDeductionCalculationComponent } from './components/main-form/bond-application-form/sections/sallery-deduction-calculation/sallery-deduction-calculation.component';
import { BondApplicationHeaderComponent } from './components/main-form/bond-application-header/bond-application-header.component';
import { MainFormComponent } from './components/main-form/main-form.component';
import { ProgressReportComponent } from './components/main-form/progress-report/progress-report.component';
import { SaleAgreementComponent } from './components/main-form/sale-agreement/sale-agreement/sale-agreement.component';
import { ApplicationListComponent } from './components/application-list/application-list.component';
import { ApplicationDashboardComponent } from './components/application-dashboard/application-dashboard.component';
import { UnitDetailsComponent } from './components/application-dashboard/unit-details/unit-details.component';
import { PayDepositComponent } from './components/pay-deposit/pay-deposit.component';
import { FinishSelectionComponent } from './components/application-dashboard/finish-selection/finish-selection.component';
import { ApplicationDocumentsComponent } from './components/application-dashboard/application-documents/application-documents.component';
import { ApplicationHistoryComponent } from './components/application-dashboard/application-history/application-history.component';
import { AdditionSelectionComponent } from './components/application-dashboard/addition-selection/addition-selection.component';
import { PaymentCompleteComponent } from './components/payment-complete/payment-complete.component';
import { ApplicationTypeSelectComponent } from './components/application-type-select/application-type-select.component';
import { CashFormComponent } from './components/cash-form/cash-form.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { ManualFormComponent } from './components/manual-form/manual-form.component';
import { ApplicationDownloadComponent } from './components/application-download/application-download.component';
import { ApplicationPayDepositComponent } from './components/application-dashboard/application-pay-deposit/application-pay-deposit.component';


export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R ",
  suffix: "",
  thousands: " ",
  nullable: true,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};
@NgModule({
  declarations: [
    PersonalDetailComponent,
    OtherInformationComponent,
    EmploymentInformationComponent,
    FinancialDetailsComponent,
    ContactDetailsComponent,
    BondApplicationFormComponent,
    LoanDetailsComponent,
    PropertyDetailsComponent,
    ApplicationTypeComponent,
    MonthlyGrossIncomeComponent,
    SalaryDeductionsComponent,
    MonthlyExpensesComponent,
    FixedDebtRepaymentsComponent,
    ProgressReportComponent,
    ProminentInfluentialPersonsComponent,
    DeclarationComponent,
    DocumentUploadsComponent,
    MainFormComponent,
    ExtrasComponent,
    FinishesComponent,
    BondApplicationHeaderComponent,
    SaleAgreementComponent,
    SalleryDeductionCalculationComponent,
    JuristicApplicationsComponent,
    DebitOrderDetailsComponent,
    ThankYouComponent,
    ApplicationListComponent,
    ApplicationDashboardComponent,
    UnitDetailsComponent,
    ApplicationPayDepositComponent,
    PayDepositComponent,
    FinishSelectionComponent,
    ApplicationDocumentsComponent,
    ApplicationHistoryComponent,
    AdditionSelectionComponent,
    PaymentCompleteComponent,
    ApplicationTypeSelectComponent,
    CashFormComponent,
    TermsAndConditionsComponent,
    ManualFormComponent,
    ApplicationDownloadComponent

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    UserModule,
    NgbModule,
    SignaturePadModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMaskModule.forRoot(),
    FontAwesomeModule,
    NgxDropzoneModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BondApplicationFormModule { }
