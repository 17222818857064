import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/user/services/user.service';

@Component({
  selector: 'app-impersonation-notice',
  templateUrl: './impersonation-notice.component.html',
  styleUrls: ['./impersonation-notice.component.scss']
})
export class ImpersonationNoticeComponent implements OnInit, OnDestroy{

  is_impersonation: boolean;
  user: User;
  subscriptions: any;

  constructor(
    private _user_service: UserService,
  ) { 
    this.subscriptions = {};

  }

  ngOnInit(): void {
    this.subscriptions.users  =  this._user_service.currentUser$.subscribe(user => {
        this.user = user;
        this.is_impersonation = this._user_service.isImpersonation();
   
    });
  }



  ngOnDestroy(): void {
    for (const subscription in this.subscriptions) {
      if (this.subscriptions.hasOwnProperty(subscription)) {
          this.subscriptions[subscription].unsubscribe();
      }
    }
  }

}
