import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ApplicationStorageService } from 'src/app/application/services/application-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faHandHoldingUsd, faFileSignature } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-pay-deposit',
  templateUrl: './pay-deposit.component.html',
  styleUrls: ['./pay-deposit.component.scss']
})
export class PayDepositComponent implements OnInit,OnDestroy {

  token:string;
  application:any;
  applicationDataLoading:boolean;
  subscriptions:any;
  cashHand = faHandHoldingUsd;
  contract = faFileSignature;

  constructor(
    private _route: ActivatedRoute,
    private _bond_application_storage: ApplicationStorageService,
    private _toast_service: ToastService,
    private _router: Router,
  ) { }


   ngOnInit(): void {

    this._route.paramMap.subscribe(params => {
      this.token = params.get('token');
      this.initApplication();
    });
  }

  initApplication()
  {
    this.subscriptions = {};
    this.applicationDataLoading = true;
    this.subscriptions.applicationHttp = this._bond_application_storage.get$(this.token)
      .subscribe((data) => {
        this.applicationDataLoading = false;
        this.application = data["data"];
      }, (res) => {
        this.applicationDataLoading = false;
        this._toast_service.show(res.error.message, { classname: 'bg-danger text-light' });
      });
  }

  bondApplicationSelection($event): void {
        $event.preventDefault()
        this.applicationDataLoading = true;
        this._bond_application_storage.bond_application_selection$(this.token,{
          pay_deposit: false,
          type: 'bond'
        }).subscribe(data => {
          if(this.application.development.is_manual) {
            this._router.navigate(['/','application', this.token]);
          } else {
            this._router.navigate(['/',this.application.development.code,'bond-application',this.token]);
          }

          this._toast_service.show(data["message"], { classname: 'bg-success text-light' });
        }, (res) => {
          this.applicationDataLoading = false;    
          this._toast_service.show(res.error.message, { classname: 'bg-danger text-light' });
        });
  }

  payDeposit($event): void {
      $event.preventDefault()
      this.applicationDataLoading = true;
      this._bond_application_storage.payDeposit$(this.token,this.application.unit_id,{}).subscribe(data => {
        this._toast_service.show(data["message"], { classname: 'bg-success text-light' });
        //this._router.navigateByUrl(data["submission_url"]);
         window.location.href = data["data"]["submission_url"];
      }, (res) => {
        this.applicationDataLoading = false;    
        this._toast_service.show(res.error.message, { classname: 'bg-danger text-light' });
      });
  }


  ngOnDestroy(): void {

    for (const subscription in this.subscriptions) {
      if (this.subscriptions.hasOwnProperty(subscription)) {
          this.subscriptions[subscription].unsubscribe();
      }
    }
  }
  

}
