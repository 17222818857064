import { Component, OnInit, HostListener, ElementRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NG_VALIDATORS, FormControl } from '@angular/forms';

@Component({
  selector: 'app-file-field',
  templateUrl: './file-field.component.html',
  styleUrls: ['./file-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileFieldComponent,
      multi: true
    }
  ]
})
export class FileFieldComponent implements  ControlValueAccessor {
  
  onTouched: Function;
  onChange: Function;

  file: any = null;//File | null | Array<File>
  @Input() fieldId:String;
  @Input() label:String;
  @Input() multiple:boolean = false;
  @Input() accept:string;
  @Input() invalid:boolean = false
  discloseValidatorChange = () => {}; // Called on a validator change or re-validation;

  @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
    let file = null;
    if(this.multiple)
    {
      file = [...event]
    }else
    {
      file = event && event.item(0);
    }

    this.onChange(file);
    this.file = file;
  }
  
  constructor( private host: ElementRef<HTMLInputElement> ) {
  }

  writeValue( value: null ) {
    // clear file input
    this.host.nativeElement.value = '';
    this.file = null;
  }

  get displayText()
  {
    if(this.multiple)
    {
      return this.file && this.file.length > 0  ? `${this.file.length} files selected` : "Choose files";
    }
    else
    {
      return this.file ? this.file.name : "Choose file";
    }
  }

  registerOnChange( fn: Function ) {
    this.onChange = fn;
  }

  registerOnTouched( fn: Function ) {
    this.onTouched = fn;
  }
}
