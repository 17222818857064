import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule, NgbDateParserFormatter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { BondApplicationFormModule } from './application/application.module';
import { SignaturePadModule } from 'angular2-signaturepad';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePickerAdapter } from './adapters/datepicker-adapter';
import { CustomDateParserFormatter } from './adapters/custom-date-parser-formatter';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CoreModule } from './core/core.module';
import { TokenInterceptor } from './HTTPInterceptor/token.interceptor';
import {EchoConfig, NgxLaravelEchoModule} from 'ngx-laravel-echo';
import { UserModule } from './user/user.module';
import { environment } from 'src/environments/environment';
import { VisitorCounterComponent } from './components/visitor-counter/visitor-counter.component';

export const echoConfig: EchoConfig = environment.echo;


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    VisitorCounterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule, 
    ReactiveFormsModule,
    SharedModule,
    BondApplicationFormModule,
    SignaturePadModule,
    HttpClientModule,
    CoreModule,
    NgxLaravelEchoModule.forRoot(echoConfig),
    UserModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS,useClass: TokenInterceptor,multi: true},
    {provide: NgbDateAdapter, useClass: DatePickerAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
