import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models/user';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { ImpersonationService } from 'src/app/user/services/impersonation.service';
import { UserService } from 'src/app/user/services/user.service';


@Component({
  selector: 'app-impersonation-request',
  templateUrl: './impersonation-request.component.html',
  styleUrls: ['./impersonation-request.component.scss']
})
export class ImpersonationRequestComponent implements OnInit {

  public closeResult = '';
  @ViewChild('impersonationRequestModal') impersonationRequestModal:any;
  impersonationRequestForm: FormGroup;
  modalReference:any;
  impersonationRequest:any;
  formLoading: boolean;
  message: string;
  subscriptions: any;
  user:User;


  constructor(
    private _modal_service: NgbModal,
    private _user_service: UserService,
    private _impersonation: ImpersonationService,
    private _validation_service: ValidationService,
    private _utility_service: UtilityService,
    private _toast_service: ToastService,
    private _router: Router,
    private fb: FormBuilder,
    ) {
      this.subscriptions = {};
    }
  
  ngOnInit(): void {
  }

  ngAfterViewInit():void{
    this.initImpersonationRequestForm();
  }

  initImpersonationSubscription()
  {
    this.subscriptions.current_user  =  this._user_service.currentUser$.subscribe(user=>{
      if(user)
      {
        this.subscriptions.impersination_request  = this._impersonation.impersonationRequest$(user.id).subscribe(request=>{
          this.message = request.message;
          this.impersonationRequest = request.impersonationRequest;
          this.closeModal();
          this.open(this.impersonationRequestModal);
        });
      }
    });
  }

  initImpersonationRequestForm()
  {
    this.impersonationRequestForm = this.fb.group({
      access_granted: ['', Validators.required],
      password: ['', [Validators.required]],
    });
    this.initImpersonationSubscription();
  }

  closeModal()
  {
    this.impersonationRequestForm.reset();
    if(this.modalReference)
    {
      this.modalReference.close();
    }
  }


  open(content) {
    this.modalReference = this._modal_service.open(content, {ariaLabelledBy: 'modal-basic-title',backdrop:'static'});
    this.modalReference.result.then((result) => {
      this.impersonationRequestForm.reset();
    }, (reason) => {
    });
  }

  onSubmit()
  {
    this._utility_service.markFormGroupTouched(this.impersonationRequestForm);    
    if(!this.impersonationRequestForm.invalid)
    {
      this.formLoading = true;
      this.subscriptions.update  =  this._impersonation.updateRequest$(this.impersonationRequest.id,this.impersonationRequestForm.value)
        .subscribe(data => {
          this.formLoading = false;
          this._toast_service.show(data["message"], { classname: 'bg-success text-light' });
          this.closeModal();
          if(data['data']['impersonation_request']["access_granted"])
          {
            this._user_service.logout$().subscribe(res=>{
              this._router.navigate(['/login']);
            });
          }

        }, (res) => {
          this.formLoading = false;       
          if (res.status == 422) {
            this._validation_service.setServerValidation(this.impersonationRequestForm,res.error.errors);
          }
  
        });
    }else
    {
      this._toast_service.show("Please ensure that your input is valid", { classname: 'bg-danger text-light' });
    }  
  }

  ngOnDestroy(): void {
    for (const subscription in this.subscriptions) {
      if (this.subscriptions.hasOwnProperty(subscription)) {
          this.subscriptions[subscription].unsubscribe();
      }
    }
  }

}
