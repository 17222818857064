<h3>
    Contact Details
</h3>
<fieldset [formGroup]="contact_details">

        <div class="row">
            <div class="col-md override-p-m">
                <!-- start of  Residential Address field -->
                <div class="form-group">
                    <label for="residential_address-field">Residential Address</label>
                    <input formControlName="residential_address" type="input" class="form-control" [ngClass]="{'is-invalid':contact_details.get('residential_address').invalid && (contact_details.get('residential_address').dirty || contact_details.get('residential_address').touched) }" id="residential_address-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Residential Address" [control]="contact_details.get('residential_address')"></app-invalid-feedback>
                </div>
                <!-- end of Residential Address Field -->
            </div>
            <div class="col-md override-p-m">
                    <!-- start of Postal Address field -->
                    <div class="form-group">
                        <label for="postal_address-field">Postal address (if different from residential address)</label>
                        <input formControlName="postal_address" type="input" class="form-control" [ngClass]="{'is-invalid':contact_details.get('postal_address').invalid && (contact_details.get('postal_address').dirty || contact_details.get('postal_address').touched) }" id="postal_address-field">
                        <app-invalid-feedback  class="invalid-feedback" name="Postal Address" [control]="contact_details.get('postal_address')"></app-invalid-feedback>
                    </div>
                    <!-- end of Postal Address Field -->
            </div>
        </div>  
        <div class="row">
            <div class="col-md-6 override-p-m">
                <div class="row">
                    <div class="col-md override-p-m">
                        <!-- start of Mobile Telephone field -->
                        <div class="form-group">
                            <label for="mobile_telephone-field">Mobile Telephone</label>
                            <input formControlName="mobile_telephone" type="tel" class="form-control" [ngClass]="{'is-invalid':contact_details.get('mobile_telephone').invalid && (contact_details.get('mobile_telephone').dirty || contact_details.get('mobile_telephone').touched) }" id="mobile_telephone-field">
                            <app-invalid-feedback  class="invalid-feedback" name="Mobile Telephone" [control]="contact_details.get('mobile_telephone')"></app-invalid-feedback>
                        </div>
                        <!-- end of Mobile Telephone Field -->
                    </div>
                    <div class="col-md-6 override-p-m">
                        <!-- start of Home Telephone field -->
                        <div class="form-group">
                            <label for="home_telephone-field">Home Telephone</label>
                            <input formControlName="home_telephone" type="tel" class="form-control" [ngClass]="{'is-invalid':contact_details.get('home_telephone').invalid && (contact_details.get('home_telephone').dirty || contact_details.get('home_telephone').touched) }" id="home_telephone-field">
                            <app-invalid-feedback  class="invalid-feedback" name="Home Telephone" [control]="contact_details.get('home_telephone')"></app-invalid-feedback>
                        </div>
                        <!-- end of Home Telephone Field -->
                    </div>
                </div>
            </div>

            <div class="col-md-6 override-p-m">
                <div class="row">
                    <div class="col-md  ">
                        <!-- start of  Work Telephone field -->
                        <div class="form-group">
                            <label for="work_telephone-field">Work Telephone</label>
                            <input formControlName="work_telephone" type="tel" class="form-control" [ngClass]="{'is-invalid':contact_details.get('work_telephone').invalid && (contact_details.get('work_telephone').dirty || contact_details.get('work_telephone').touched) }" id="work_telephone-field">
                            <app-invalid-feedback  class="invalid-feedback" name="Work Telephone" [control]="contact_details.get('work_telephone')"></app-invalid-feedback>
                        </div>
                        <!-- end of Work Telephone Field -->
                    </div>
                    <div class="col-md override-p-m">
                        <!-- start of  Email Address field -->
                        <div class="form-group">
                            <label for="email_address-field">Email Address</label>
                            <input formControlName="email_address" type="email" class="form-control" [ngClass]="{'is-invalid':contact_details.get('email_address').invalid && (contact_details.get('email_address').dirty || contact_details.get('email_address').touched) }" id="email_address-field">
                            <app-invalid-feedback  class="invalid-feedback" name="Email Address" [control]="contact_details.get('email_address')"></app-invalid-feedback>
                        </div>
                        <!-- end of Email Address Field -->
                    </div>
                </div>
            </div>
        </div>  
        <div class="row">

            <div class="col-md override-p-m">
                <!-- start of  Home Language field -->
                <div class="form-group">
                <label for="home_language-field">Home Language</label>
                <select formControlName="home_language" class="form-control" [ngClass]="{'is-invalid':contact_details.get('home_language').invalid && (contact_details.get('home_language').dirty || contact_details.get('home_language').touched) }" id="home_language-field">
                    <option class="d-none" [ngValue]="''">
                        Select preferred
                    </option>
                    <option *ngFor="let option of options['home_language']"  [ngValue]="option.value">
                        {{option.name }}
                    </option>
                </select>
                <app-invalid-feedback  class="invalid-feedback" name="Home Language" [control]="contact_details.get('home_language')"></app-invalid-feedback>
                </div>
                <!-- end of Home Language Field -->
            </div>
        </div>
        <div class="row">
            <div class="col-md override-p-m" *ngIf="contact_details.get('other_home_language_specification')">
                <!-- start of  Qualification Specification  field -->
                <div class="form-group">
                    <label for="title-field">Other Home Language Specification</label>
                    <input formControlName="other_home_language_specification" type="input" class="form-control" [ngClass]="{'is-invalid':contact_details.get('other_home_language_specification').invalid && (contact_details.get('other_home_language_specification').dirty || contact_details.get('other_home_language_specification').touched) }" id="title-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Qualification Specification " [control]="contact_details.get('other_home_language_specification')"></app-invalid-feedback>
                </div>
                <!-- end of  Qualification Specification  field -->
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="legal_notice_delivery_method-field">Legal Notice Delivery Method</label>
                <div class="row">
                  <div class="col-md pr-2 pl-2">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" formControlName="legal_notice_delivery_method"
                        id="legal_notice_delivery_method-main{{index}}" value="Hand Delivery">
                      <label class="form-check-label" for="legal_notice_delivery_method-main{{index}}">
                         Main Residence
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" formControlName="legal_notice_delivery_method"
                        id="legal_notice_delivery_method-registerd_mail{{index}}" value="Registerd Mail">
                      <label class="form-check-label" for="legal_notice_delivery_method-registerd_mail{{index}}">
                        Registered Mail
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md override-p-m" *ngIf="contact_details.get('legal_notice_delivery_address')">
                <!-- start of  Qualification Specification  field -->
                <div class="form-group">
                    <label for="title-field">Address (If different from Residential Address)</label>
                    <input formControlName="legal_notice_delivery_address" type="input" class="form-control" [ngClass]="{'is-invalid':contact_details.get('legal_notice_delivery_address').invalid && (contact_details.get('legal_notice_delivery_address').dirty || contact_details.get('legal_notice_delivery_address').touched) }" id="title-field">
                    <app-invalid-feedback  class="invalid-feedback" name="Legal Notice Delivery Address" [control]="contact_details.get('legal_notice_delivery_address')"></app-invalid-feedback>
                </div>
                <!-- end of  Qualification Specification  field -->
            </div>
        </div>
        <div class="row">
            <div class="col-md override-p-m">
                <!-- start of  Preferred Communication channel during application process field -->
                <div class="form-group">
                    <label for="preferred_communication_channel-field">Preferred Communication channel during application process</label>
                    <select formControlName="preferred_communication_channel"  class="form-control" [ngClass]="{'is-invalid':contact_details.get('preferred_communication_channel').invalid && (contact_details.get('preferred_communication_channel').dirty || contact_details.get('preferred_communication_channel').touched) }" id="preferred_communication_channel-field">
                        <option class="d-none" [ngValue]="''">
                            Select preferred communication channel
                        </option>
                        <option *ngFor="let option of options['prefferd_communication']"  [ngValue]="option.value">
                            {{option.name }}
                        </option>
                    </select>
                    <app-invalid-feedback  class="invalid-feedback" name="Preferred Communication channel during application process" [control]="contact_details.get('preferred_communication_channel')"></app-invalid-feedback>
                </div>
                <!-- end of Preferred Communication channel during application process Field -->
            </div>
        </div>

 
     
     
     
        
     
       
     
        
     
        

     

     

     

     
     
     

     
       
     
   
</fieldset>