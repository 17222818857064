import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormDataService {

  httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    })
  };

  constructor(
    private http: HttpClient,
  ) {}


  get$()
  {
    return this.http.get(`${environment.api.base_url}/form-data`);
  }

}
