import { Component, OnInit, Input, ChangeDetectorRef, Inject } from '@angular/core';
import { faCircle,faDotCircle } from '@fortawesome/free-regular-svg-icons';
import { DOCUMENT } from '@angular/common'; 

@Component({
  selector: 'app-progress-report',
  templateUrl: './progress-report.component.html',
  styleUrls: ['./progress-report.component.scss']
})
export class ProgressReportComponent implements OnInit {
  incomplete = faCircle;
  complete = faDotCircle;
  @Input() progress: any;
  @Input() active: number;

  constructor(
    private cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private document
    ) {
   }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  ngOnInit(): void {
  }

  get sections()
  {
    return Object.keys(this.progress);
  }

  gotoSection(section)
  {
    let index = this.active -1;
    let id = "panel-"+index+"-"+section.toLowerCase().replace(" ","-");
    let activeElement = document.getElementById(id);
    if(activeElement)
    {
      activeElement.scrollIntoView({
        behavior: "smooth"
      });
    }
  
   }
}
