import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import options from 'src/app/data/options.json'
import { DependecyFieldsService } from 'src/app/shared/services/dependecy-fields.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-employment-information',
  templateUrl: './employment-information.component.html',
  styleUrls: ['./employment-information.component.scss']
})
export class EmploymentInformationComponent implements OnInit, OnDestroy {

  employment_information: FormGroup;
  options = options;
  subscriptions: Array<Subscription>=[]
  @Input() bondApplication: FormGroup;
  @Input() index: number;
  constructor(private fb: FormBuilder,
    private _dependecy_fields_service: DependecyFieldsService
  ) {
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    })
  }

  ngOnInit(): void {
    this.AddToForm();
  }

  get registerFormControl() {
    return this.employment_information.controls;
  }

  AddToForm(): void {
    this.employment_information = this.fb.group({
      current_employment_type: ['', [Validators.required]],
      source_of_income_for_loan_repayment: ['', [Validators.required]],
      tax_number: ['', [Validators.required]],
    });
    this.bondApplication.addControl('employment_information', this.employment_information);

    const current_employment_type_prompters = this.options['current_employment_type'].filter(option => option.prompt);
    const current_employment_type_prompters_values = current_employment_type_prompters.map(option => option.value);
    
    let currentTypeDependecyFields = [
      {
        "field": "current_employment_type",
        "values": current_employment_type_prompters_values,
        "depedent_fields": [
          {
            "name": "other_current_employment_type_specification",
            "control": this.fb.control('', [Validators.required])
          }
        ]
      }
    ];

    this.subscriptions.push(...
      this._dependecy_fields_service.setConditionalFields(this.employment_information, currentTypeDependecyFields)
    )

    const present_employment_type_prompters = this.options['current_employment_type'].filter(option => option.present_prompt);
    const present_employment_type_prompters_values = present_employment_type_prompters.map(option => option.value);

    let presentFieldsDependecyFields = [
      {
        "field": "current_employment_type",
        "values": present_employment_type_prompters_values,
        "depedent_fields": [
          {
            "name": "occupation_industry",
            "control": this.fb.control('', [Validators.required])
          },
          {
            "name": "occupation_level",
            "control": this.fb.control('', [Validators.required])
          },
          {
            "name": "present_employer_name",
            "control": this.fb.control('', [Validators.required])
          },
          {
            "name": "employer_telephone_number",
            "control": this.fb.control('', [Validators.required])
          },
          {
            "name": "time_with_present_employer",
            "control": this.fb.control('', [Validators.required])
          },
        ]
      }
    ];

    this.subscriptions.push(...
      this._dependecy_fields_service.setConditionalFields(this.employment_information, presentFieldsDependecyFields)
    );

    const source_of_income_for_loan_repayment_prompters = this.options['loan_repayment'].filter(option => option.prompt);
    const source_of_income_for_loan_repayment_prompters_values = source_of_income_for_loan_repayment_prompters.map(option => option.value);

    let loanSourceDependecyFields = [
      {
        "field": "source_of_income_for_loan_repayment",
        "values": source_of_income_for_loan_repayment_prompters_values,
        "depedent_fields": [
          {
            "name": "other_source_of_income_for_loan_repayment_specification",
            "control": this.fb.control('', [Validators.required])
          }
        ]
      }
    ];
    this.subscriptions.push(...
      this._dependecy_fields_service.setConditionalFields(this.employment_information, loanSourceDependecyFields)
    )
  }
}
