import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-fixed-debt-repayments',
  templateUrl: './fixed-debt-repayments.component.html',
  styleUrls: ['./fixed-debt-repayments.component.scss']
})
export class FixedDebtRepaymentsComponent implements OnInit {
  
  fixed_debt_repayment:FormGroup;
  @Input() bondApplication: FormGroup;
  @Input() index: number;
  requiredMessage = "Value must be 0 or higher";
  constructor(private fb: FormBuilder,
    private utilityService: UtilityService 

  ) { }

  ngOnInit(): void {
    this.AddToForm();
  }

  AddToForm(): void{
      this.fixed_debt_repayment = this.fb.group({
        total_monthly_home_repayments: ['0',[Validators.required]],
        amount_loan_repayment_will_stop_after_ativation: ['0',[Validators.required]],
        asset_finance_vehicle_repayments: ['0',[Validators.required]],
        loan_overdraft_repayment: ['0',[Validators.required]],
        credit_card_repayment: ['0',[Validators.required]],
        retail_account_repayment: ['0',[Validators.required]],
        other_fixed_debt_repayments: ['0',[Validators.required]],
        other_specification: ['']

    });
    this.bondApplication.addControl('fixed_debt_repayment',this.fixed_debt_repayment);
  }

  get fixed_debt_repayments_total()
  {
    return  this.utilityService.valuesSum(this.fixed_debt_repayment.value,[
      "total_monthly_home_repayments",
      "amount_loan_repayment_will_stop_after_ativation",
      "asset_finance_vehicle_repayments",
      "loan_overdraft_repayment",
      "credit_card_repayment",
      "retail_account_repayment",
      "other_fixed_debt_repayments"
    ]); 
  }

}
