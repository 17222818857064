<div class="container">
    <h4 class="pt-5 pb-3">
        CRAFT HOMES EXTRAS PAYMENTS:<br>
        ONCE OFF CASH PAYMENT & PAYMENT PLAN OPTION
        TERMS AND CONDITIONS
    </h4>
    <hr>
    <ol class="pt-3 pb-3">
        <li>The extras selected will need to be paid in full according to the tax invoice sent to the client, before the due date stipulated per development.</li>
        <li>If a purchaser cancels their deal after the extras items have been installed in the unit, no refund will be given to the purchaser for the extras that have been paid for. </li>
        <li>If the deal is cancelled, before the due date of total payment for the extras, then a 10% admin fee will be charged on the total amount of the extras selected.</li>
        <li>If the client has selected a payment plan option and fails to pay the total cost due, before the due date stipulated, the total monies paid to Craft Homes, for such extras, is non-refundable and extras items not paid for will not be installed.</li>
        <li>If the client pays cash upfront for the extras, a 5% discount will be given on the total amount of the extras, including VAT.</li>
        <li>If the total amount of extras exceeds R100 000, a direct EFT payment needs to be made, if the client selects the cash option. A payment via Pay Fast in this instance, will not be allowed.</li>
        <li>If the total amount of extras exceeds R100 000 and the client selects a payment plan option, the payments via Pay Fast will be allowed, on condition that the monthly payment amount does not exceed R100 000 per payment. In this case, a direct EFT payment will need to be made per month.</li>
        <li>A client can select extras and pay cash upfront as many times as they wish, before the stipulated due date of payment of all the extras for the specific development.</li>
        <li>Only one payment plan is allowed per client, per development, at one time.</li>
    </ol>
</div>
