<nav class="navbar navbar-expand-lg navbar-light bg-nav">
    <div class="container">
      <a class="navbar-brand" [routerLink]="'/'">
        <img src="../../../../assets/image/craft_logo.png" alt="Craft Homes Logo" class="craft-logo">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" (click)="isMenuCollapsed = !isMenuCollapsed" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    
      <div class="collapse navbar-collapse" [ngbCollapse]="isMenuCollapsed" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto" *ngIf="user$ | async as user else noAuth">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="'/'" routerLinkActive="active">Applications</a>
          </li>
          <li ngbDropdown class="nav-item">
            <a class="nav-link" id="my-account" ngbDropdownToggle>My Account </a>
            <div ngbDropdownMenu aria-labelledby="my-account">
              <button ngbDropdownItem  [routerLink]="'my-profile'" routerLinkActive="active">My Profile</button>
              <button ngbDropdownItem  [routerLink]="'/change-password'" routerLinkActive="active">Change Password</button>
            </div>
          </li>
          <li>
              <a class="nav-link"  href="#" (click)="logout($event)" routerLinkActive="active">Log Out</a>
          </li>
        </ul>
        <ng-template  #noAuth>
              <ul  class="navbar-nav ml-auto text-right">
          
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'login'" routerLinkActive="active">Login</a>
                </li>
      
              </ul>
          </ng-template>
  
      </div>
    </div>
  </nav>