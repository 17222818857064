<ng-template #impersonationRequestModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Impersonation Request</h4>
    </div>
    <div class="modal-body" *ngIf="impersonationRequestForm">
        <form [formGroup]="impersonationRequestForm">
          <div class="alert alert-info">
            {{ message }}
            <br>
            <strong>Reason for Request:</strong>
            {{ impersonationRequest.reason }}
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="access_granted" id="access_granted_radio" [value]="true">
                <label class="form-check-label" for="access_granted_radio">Grant Access</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="access_granted" id="access_denied_radio" [value]="false">
                <label class="form-check-label" for="access_denied_radio">Deny Access</label>
              </div>
            </div>
          </div>
            <div class="row mb-2">
              <div class="col-12">
                <!-- start of  password field -->
              <div class="form-group ">
                <input placeholder="Password" formControlName="password" type="password" class="form-control" [ngClass]="{'is-invalid':impersonationRequestForm.get('password').invalid && (impersonationRequestForm.get('password').dirty || impersonationRequestForm.get('password').touched) }" id="email-field">
                <app-invalid-feedback  class="invalid-feedback" name="Password" [control]="impersonationRequestForm.get('password')"></app-invalid-feedback>
              </div>
            <!-- end of password Field -->
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <button [appLoader]="formLoading" type="submit" class="btn btn-primary btn-block" (click)="onSubmit()">Submit</button>
              </div>

            </div>
  
        </form>
    </div>
  </ng-template>
  