import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import options from 'src/app/data/options.json'
import { DependecyFieldsService } from 'src/app/shared/services/dependecy-fields.service';
import { Subscription } from 'rxjs';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { ApplicationStorageService } from 'src/app/application/services/application-storage.service';


@Component({
  selector: 'app-personal-detail',
  templateUrl: './personal-detail.component.html',
  styleUrls: ['./personal-detail.component.scss']
})
export class PersonalDetailComponent implements OnInit, OnDestroy {

  personal_details: FormGroup;
  options = options;
  @Input() bondApplication: FormGroup;
  @Input() index: number;
  formSubscriptions: Subscription = new Subscription()
  subscriptions: Array<Subscription> = []
  faCalendar = faCalendar;

  constructor(private fb: FormBuilder,
    private _dependecy_fields_service: DependecyFieldsService,
    private _bond_applicationStorage: ApplicationStorageService,
  ) { }
  ngOnDestroy(): void {
    this.formSubscriptions.unsubscribe()
    this.subscriptions.forEach(sub=>{sub.unsubscribe()})
  }

  ngOnInit(): void {
    this.AddToForm();
  }
  
  get registerFormControl() {
    return this.personal_details.controls;
  }

  onChange() {
    this.formSubscriptions.add(
      this.personal_details.get("marital_status").valueChanges.subscribe((nv) => {
        let selected = this.options.marital_status.find(element => element.value == nv);
        if (selected) {
          this._bond_applicationStorage.setApplicationJointType(selected.joint_application)
        }
      }))
  }

  AddToForm(): void {
    this.personal_details = this.fb.group({
      title: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      second_name: [''],
      date_of_birth: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      id_type: ['ID', [Validators.required]],
      nationality: ['', [Validators.required]],
      ethnic_group: ['', [Validators.required]],
      marital_status: ['', [Validators.required]],
      number_of_dependants: ['', [Validators.required, Validators.pattern("^[0-9]*$"),]],
      highest_qualification: ['', [Validators.required]],

    });
    this.bondApplication.addControl('personal_details', this.personal_details);

    const highest_qualification_prompters = this.options['highest_qualification'].filter(option => option.prompt_specification);
    const highest_qualification_prompters_values = highest_qualification_prompters.map(option => option.value);
    const qualificationDependecyFields = [
      {
        "field": "highest_qualification",
        "values": highest_qualification_prompters_values,
        "depedent_fields": [
          {
            "name": "other_highest_qualification_specification",
            "control": this.fb.control('', [Validators.required])
          }
        ]
      }
    ];
    this.subscriptions.push(...
      this._dependecy_fields_service.setConditionalFields(this.personal_details, qualificationDependecyFields)
    )
    const ethnic_group_prompters = this.options['ethnic_group'].filter(option => option.prompt_specification);
    const ethnic_group_prompters_values = ethnic_group_prompters.map(option => option.value);
    const ethnicDependecyFields = [
      {
        "field": "ethnic_group",
        "values": ethnic_group_prompters_values,
        "depedent_fields": [
          {
            "name": "other_ethnic_group_specification",
            "control": this.fb.control('', [Validators.required])
          }
        ]
      }
    ];
    this.subscriptions.push(...
      this._dependecy_fields_service.setConditionalFields(this.personal_details, ethnicDependecyFields)

    )
    const idTypeDependecyFields = [
      {
        "field": "id_type",
        "values": ["ID"],
        "depedent_fields": [
          {
            "name": "id_number",
            "control": this.fb.control('', [Validators.required])
          }
        ]
      }
    ];
    this.subscriptions.push(...
      this._dependecy_fields_service.setConditionalFields(this.personal_details, idTypeDependecyFields)
    )

    const passportTypeDependecyFields = [
      {
        "field": "id_type",
        "values": ["Passport"],
        "depedent_fields": [
          {
            "name": "passport_number",
            "control": this.fb.control('', [Validators.required])
          }
        ]
      }
    ];
    this.subscriptions.push(...
      this._dependecy_fields_service.setConditionalFields(this.personal_details, passportTypeDependecyFields)
    )

    if(!this.personal_details.get('id_number') && !this.personal_details.get('passport_number')) {
      this.personal_details.get('id_type').setValue('ID');
    }
    this.onChange()
  }

}
