import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import options from 'src/app/data/options.json'
import { DependecyFieldsService } from 'src/app/shared/services/dependecy-fields.service';
import { Subscription } from 'rxjs';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-other-information',
  templateUrl: './other-information.component.html',
  styleUrls: ['./other-information.component.scss']
})
export class OtherInformationComponent implements OnInit ,OnDestroy{

  other_information: FormGroup;
  options = options;
  @Input() bondApplication: FormGroup;
@Input() index: number;
  subscriptions: Array<Subscription> = []
  faCalendar = faCalendar;

  constructor(private fb: FormBuilder,
    private _dependecy_fields_service: DependecyFieldsService
  ) {
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>{sub.unsubscribe()})
  }

  ngOnInit(): void {
    this.AddToForm();
  }

  get registerFormControl() {
    return this.other_information.controls;
  }

  AddToForm(): void {
    this.other_information = this.fb.group({
      under_debt_counselling: ['', [Validators.required]],
      debt_re_arrangement_issued: ['', [Validators.required]],
      ever_insolvent_or_administration: ['', [Validators.required]],
      first_time_owner:['',[Validators.required]]
    });

    let under_debt_depedency = [
      {
        "field": "under_debt_counselling",
        "values": [true,"1",1],
        "depedent_fields": [
          {
            "name": "debt_counsellor_name",
            "control": this.fb.control('', [Validators.required])
          },
          {
            "name": "debt_counsellor_contact_number",
            "control": this.fb.control('', [Validators.required])
          },
        ]
      }
    ];
    this.subscriptions.push(...
      this._dependecy_fields_service.setConditionalFields(this.other_information, under_debt_depedency)
    )
    let date_of_rehabilitation_dependecy = [
      {
        "field": "ever_insolvent_or_administration",
        "values": [true,"1",1],
        "depedent_fields": [
          {
            "name": "date_of_rehabilitation",
            "control": this.fb.control('', [Validators.required])
          },
        ]
      }
    ];
    this.subscriptions.push(...
      this._dependecy_fields_service.setConditionalFields(this.other_information, date_of_rehabilitation_dependecy)
    )

    this.bondApplication.addControl('other_information', this.other_information)
  }

}
